/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useState, useEffect } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import Swal from "sweetalert2";
import Baseurl from "../../../../../Baseurl";
import Header from "../../../../Layout/Header";
import ProfileInfo from "../../Profile/ProfileInfo";
import { Link, useHistory, useLocation } from "react-router-dom";
import SidebarDashNew from "./SidebarDashNew";

function TanBackground() {
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const [allPostData, setAllPostData] = useState();
  const [bund, setBund] = useState("");

  const application_id = localStorage.getItem("assignId");
  const reviewData = new FormData();
  reviewData.append("application_id", application_id);

  const location = useLocation();
  const postData = () => {
    const token = localStorage.getItem("usertoken");

    var config = {
      method: "post",
      url: `${Baseurl.baseurl}real-estate/rent/tenant/progress`,
      data: reviewData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        setAllPostData(response?.data?.data);
        console.log(response?.data?.data, "sidebar response");
        if (response?.data?.status === true) {
          console.log(response?.data?.message, "response?.data?.message");
          // history.push('/new_mortage')
          setLoader(false);

          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
        // console.log(allGet, "all data");
        console.log(response, "my response");
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setBund(error?.response?.data?.errors);
        Swal.fire({
          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };
  useEffect(() => {
    postData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [isOpen, setIsOpen] = useState(true);

  const Assign_id = localStorage.getItem("assignId"); // also on condition for add and update
  const [id, setId] = useState(null);

  const [owning_money, setowning_money] = useState(false);
  const [bankruptcy, setbankruptcy] = useState(false);
  const [not_guilty, setnot_guilty] = useState(false);
  const [special_needs, setspecial_needs] = useState(false);
  const [additional_comments, setadditional_comments] = useState("");
  const [find_property, setfind_property] = useState("");
  const [formStateMoney, setFormStateMoney] = useState("");
  const [formStateBank, setFormStateBank] = useState("");
  const [formStateGuilty, setFormStateGuilty] = useState("");
  const [formStateSpecial, setFormStateSpecial] = useState("");

  const AddDeclaration = (e) => {
    e.preventDefault();
    const BorrowerData = new FormData();
    BorrowerData.append("application_id", Assign_id);
    BorrowerData.append("find_property", find_property);
    BorrowerData.append("additional_comments", additional_comments);

    BorrowerData.append("owning_money", owning_money ? 1 : 0);
    BorrowerData.append("criminal_charges", not_guilty ? 1 : 0);
    BorrowerData.append("bankruptcy", bankruptcy ? 1 : 0);
    BorrowerData.append("special_needs", special_needs ? 1 : 0);

    BorrowerData.append("owning_money_date_des", formStateMoney);
    BorrowerData.append("criminal_charges_date_des", formStateGuilty);
    BorrowerData.append("bankruptcy_date_des", formStateBank);
    BorrowerData.append("special_needs_des", formStateSpecial);

    id && BorrowerData.append("id", id);
    setLoader(true);
    let token = localStorage.getItem("usertoken");
    var config = {
      method: "post",
      url: `${Baseurl.baseurl}real-estate/rent/tenant/store/background`,
      data: BorrowerData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response);
        if (response?.data?.status === true) {
          console.log(response?.data?.message, "response?.data?.message");
          setLoader(false);
          Swal.fire({
            toast: true,
            icon: "success",
            title: response?.data?.message,
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          history.push("/rent/tanent/additionalinfo");
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setBund(error?.response?.data?.errors);
        Swal.fire({
          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };
  const Get_Borrower = () => {
    const BorrowerData = new FormData();
    BorrowerData.append("application_id", Assign_id);
    setLoader(true);
    let token = localStorage.getItem("usertoken");
    var config = {
      method: "post",
      url: `${Baseurl.baseurl}real-estate/rent/tenant/get/background`,
      data: BorrowerData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then(function (response) {
        if (response?.data?.status === true) {
          setId(response?.data?.data?.id);
          setLoader(false);
          setowning_money(
            response?.data?.data?.owning_money === 1 ? true : false
          );
          setnot_guilty(
            response?.data?.data?.criminal_charges === 1 ? true : false
          );
          setbankruptcy(response?.data?.data?.bankruptcy === 1 ? true : false);
          setspecial_needs(
            response?.data?.data?.special_needs === 1 ? true : false
          );
          setfind_property(response?.data?.data?.find_property);
          setadditional_comments(response?.data?.data?.additional_comments);
          setFormStateMoney(response?.data?.data?.owning_money_date_des);
          setFormStateBank(response?.data?.data?.bankruptcy_date_des);
          setFormStateGuilty(response?.data?.data?.criminal_charges_date_des);
          setFormStateSpecial(response?.data?.data?.special_needs_des);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setBund(error?.response?.data?.errors);
        Swal.fire({
          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };
  useEffect(() => {
    Get_Borrower();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <Header />

      {!loader ? (
        <form onSubmit={AddDeclaration}>
          <div className="container-fluid">
            <div className="row">
              <>
                <SidebarDashNew />
              </>

              <div
                className={
                  isOpen === true
                    ? "col-md-8 open he my-5 ps-lg-5"
                    : isOpen === false
                    ? "col-md-10 open nhi he my-5 ps-lg-5"
                    : ""
                }
              >
                <h3 className=" mb-3" id="bgcolor1">
                  Background
                </h3>
                <div>
                  <label
                    className="form-label col-10 mt-4"
                    style={{ textTransform: "none" }}
                  >
                    1) Have you or anyone listed on this application ever been
                    evicted or left owing money?
                  </label>
                  <div className="stateagent mt-3">
                    <div className="row">
                      <div className="col-3 col-md-2 col-lg-1">
                        <input
                          type="radio"
                          className="btn-check"
                          name="q1"
                          defaultChecked={owning_money}
                          onChange={() => {
                            setowning_money(true);
                          }}
                          id="YES1"
                          autocomplete="off"
                        />
                        <label
                          className="btn btn btn-link yes px-0 py-2"
                          for="YES1"
                        >
                          Yes {owning_money ? "✔" : null}
                        </label>
                      </div>
                      <div className="col-3 col-md-2 col-lg-1">
                        <input
                          type="radio"
                          className="btn-check"
                          name="q1"
                          id="No1"
                          autocomplete="off"
                          defaultChecked={owning_money}
                          onChange={() => {
                            setowning_money(false);
                          }}
                        />
                        <label
                          className="btn btn btn-link no px-0 py-2"
                          for="No1"
                        >
                          No {!owning_money ? "✔" : null}
                        </label>
                      </div>
                      {owning_money ? (
                        <>
                          <div className="col-md-4">
                            <div
                              className="input-group w-100 flex-column"
                              style={{ height: "84px" }}
                            >
                              <div>
                                <div className="form-group-new ms-4">
                                  <input
                                    className="w-100 form-control-new rounded-0 text-capitalize"
                                    id="description"
                                    name="description"
                                    type="text"
                                    onChange={(e) =>
                                      setFormStateMoney(e.target.value)
                                    }
                                    value={formStateMoney}
                                    required={owning_money ? true : false}
                                  />
                                  <label
                                    htmlFor="description"
                                    className="label-new"
                                  >
                                    Description & Date
                                  </label>
                                </div>
                                {formStateMoney?.description?.length > 4 ? (
                                  <p
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "bold",
                                      position: "absolute",
                                      color: "green",
                                      right: "18px",
                                      bottom: "32px",
                                      zIndex: "123",
                                    }}
                                  >
                                    ✔
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div>
                  <h5
                    className="form-label col-10 mt-4"
                    style={{ textTransform: "none" }}
                  >
                    2) Do you or anyone in your household have pending criminal
                    charges or a past conviction (excluding minor traffic
                    infractions)?
                  </h5>
                  <div className="stateagent mt-3">
                    <div className="d-flex">
                      <div className="col-3 col-md-2 col-lg-1">
                        <input
                          type="radio"
                          className="btn-check"
                          name="q2"
                          id="YES2"
                          defaultChecked={not_guilty}
                          onChange={() => {
                            setnot_guilty(true);
                          }}
                          autocomplete="off"
                        />
                        <label
                          className="btn btn btn-link yes px-0 py-2"
                          for="YES2"
                        >
                          Yes {not_guilty ? "✔" : null}
                        </label>
                      </div>
                      <div className="col-3 col-md-2 col-lg-1">
                        <input
                          type="radio"
                          className="btn-check"
                          name="q2"
                          id="No2"
                          autocomplete="off"
                          defaultChecked={not_guilty}
                          onChange={() => {
                            setnot_guilty(false);
                          }}
                        />
                        <label
                          className="btn btn btn-link no px-0 py-2"
                          for="No2"
                        >
                          No {!not_guilty ? "✔" : null}
                        </label>
                      </div>
                      {not_guilty ? (
                        <>
                          <div className="col-md-4">
                            <div
                              className="input-group w-100 flex-column"
                              style={{ height: "84px" }}
                            >
                              <div>
                                <div className="form-group-new ms-4">
                                  <input
                                    className="w-100 form-control-new rounded-0 text-capitalize"
                                    id="description"
                                    name="description"
                                    type="text"
                                    onChange={(e) =>
                                      setFormStateGuilty(e.target.value)
                                    }
                                    required={not_guilty ? true : false}
                                    value={formStateGuilty}
                                  />
                                  <label
                                    htmlFor="description"
                                    className="label-new"
                                  >
                                    Description & Date
                                  </label>
                                </div>
                                {formStateGuilty?.length > 4 ? (
                                  <p
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "bold",
                                      position: "absolute",
                                      color: "green",
                                      right: "18px",
                                      bottom: "32px",
                                      zIndex: "123",
                                    }}
                                  >
                                    ✔
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div>
                  <h5
                    className="form-label col-10 mt-4"
                    style={{ textTransform: "none" }}
                  >
                    3) Have you or anyone listed on this application ever filed
                    for bankruptcy, faced foreclosure, or been a defendant in a
                    civil suit?
                  </h5>
                  <div className="stateagent mt-3">
                    <div className="d-flex">
                      <div className="col-3 col-md-2 col-lg-1">
                        <input
                          type="radio"
                          className="btn-check"
                          name="q3"
                          id="YES3"
                          defaultChecked={bankruptcy}
                          onChange={() => {
                            setbankruptcy(true);
                          }}
                          autocomplete="off"
                        />
                        <label
                          className="btn btn btn-link yes px-0 py-2"
                          for="YES3"
                        >
                          Yes {bankruptcy ? "✔" : null}
                        </label>
                      </div>
                      <div className="col-3 col-md-2 col-lg-1">
                        <input
                          type="radio"
                          className="btn-check"
                          name="q3"
                          id="No3"
                          autocomplete="off"
                          defaultChecked={bankruptcy}
                          onChange={() => {
                            setbankruptcy(false);
                          }}
                        />
                        <label
                          className="btn btn btn-link no px-0 py-2"
                          for="No3"
                        >
                          No {!bankruptcy ? "✔" : null}
                        </label>
                      </div>
                      {bankruptcy ? (
                        <>
                          <div className="col-md-4">
                            <div
                              className="input-group w-100 flex-column"
                              style={{ height: "84px" }}
                            >
                              <div>
                                <div className="form-group-new ms-4">
                                  <input
                                    className="form-control-new w-100 rounded-0 text-capitalize"
                                    id="description"
                                    name="description"
                                    type="text"
                                    value={formStateBank}
                                    onChange={(e) =>
                                      setFormStateBank(e.target.value)
                                    }
                                    required={bankruptcy ? true : false}
                                  />
                                  <label
                                    htmlFor="description"
                                    className="label-new"
                                  >
                                    Description & Date
                                  </label>
                                </div>
                                {formStateBank?.length > 4 ? (
                                  <p
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "bold",
                                      position: "absolute",
                                      color: "green",
                                      right: "18px",
                                      bottom: "32px",
                                      zIndex: "123",
                                    }}
                                  >
                                    ✔
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="mt-4">
                  <h3 className="text-black ">Other Information</h3>
                  <h5 className="form-label" style={{ textTransform: "none" }}>
                    Do you have any special needs or requirements we should be
                    aware of?
                  </h5>
                  <div className="stateagent mt-3">
                    <div className="d-flex">
                      <div className="col-3 col-md-2 col-lg-1">
                        <input
                          type="radio"
                          className="btn-check"
                          name="junaid"
                          id="junaidyes"
                          defaultChecked={special_needs}
                          onChange={() => {
                            setspecial_needs(true);
                          }}
                          autocomplete="off"
                        />
                        <label
                          className="btn btn btn-link yes px-0 py-2"
                          for="junaidyes"
                        >
                          Yes {special_needs ? "✔" : null}
                        </label>
                      </div>
                      <div className="col-3 col-md-2 col-lg-1">
                        <input
                          type="radio"
                          className="btn-check"
                          name="junaid"
                          id="junaidno"
                          autocomplete="off"
                          defaultChecked={special_needs}
                          onChange={() => {
                            setspecial_needs(false);
                          }}
                        />
                        <label
                          className="btn btn btn-link no px-0 py-2"
                          for="junaidno"
                        >
                          No {!special_needs ? "✔" : null}
                        </label>
                      </div>
                      {special_needs ? (
                        <>
                          <div className="col-md-4">
                            <div
                              className="input-group w-100 flex-column"
                              style={{ height: "84px" }}
                            >
                              <div>
                                <div className="form-group-new ms-4">
                                  <input
                                    className="w-100 form-control-new rounded-0 text-capitalize"
                                    id="description"
                                    name="description"
                                    type="text"
                                    onChange={(e) =>
                                      setFormStateSpecial(e.target.value)
                                    }
                                    value={formStateSpecial}
                                    required={special_needs ? true : false}
                                  />
                                  <label
                                    htmlFor="description"
                                    className="label-new"
                                  >
                                    Description
                                  </label>
                                </div>
                                {formStateSpecial?.length > 4 ? (
                                  <p
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "bold",
                                      position: "absolute",
                                      color: "green",
                                      right: "18px",
                                      bottom: "32px",
                                      zIndex: "123",
                                    }}
                                  >
                                    ✔
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="col-6 mt-4 form-group">
                  <label className="land_fname">
                    How did you find this property?
                  </label>
                  <div className="select-container mt-2">
                    <select
                      required
                      name="estimated_credit_score"
                      formcontrolname="creditRange"
                      className={`form-control-select ${
                        find_property ? "text-success" : "text-dark"
                      }`}
                      value={find_property}
                      onChange={(e) => setfind_property(e.target.value)}
                      onFocus={() =>
                        document
                          ?.querySelector(".label-select")
                          ?.classList?.add("label-top")
                      }
                      onBlur={() =>
                        !find_property &&
                        document
                          ?.querySelector(".label-select")
                          ?.classList?.remove("label-top")
                      }
                    >
                      <option value="" disabled></option>
                      <option value={"Craigslist"}>
                        {" "}
                        Craigslist
                        {find_property === "Craigslist" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value={"Zillow"}>
                        {" "}
                        Zillow
                        {find_property === "Zillow" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value={"MLS Trulia"}>
                        {" "}
                        MLS Trulia
                        {find_property === "MLS Trulia" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value={"Hotpads.com"}>
                        {" "}
                        Hotpads.com
                        {find_property === "Hotpads.com" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value={"Realtor.com"}>
                        {" "}
                        Realtor.com
                        {find_property === "Realtor.com" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value={"Apartments.com"}>
                        {" "}
                        Apartments.com
                        {find_property === "Apartments.com" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value={"Rent.com"}>
                        {" "}
                        Rent.com
                        {find_property === "Rent.com" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value={"Homes.com"}>
                        {" "}
                        Homes.com
                        {find_property === "Homes.com" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value={"Agent"}>
                        {" "}
                        Agent
                        {find_property === "Agent" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value={"Sign"}>
                        {" "}
                        Sign
                        {find_property === "Sign" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value={"Owner"}>
                        {" "}
                        Owner
                        {find_property === "Owner" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                    </select>
                    <label
                      className={`label-new ${
                        find_property ? "label-top" : ""
                      }`}
                      htmlFor="estimated_credit_score"
                    >
                      Select
                    </label>
                  </div>
                  {bund?.find_property
                    ? bund?.find_property?.map((e) => (
                        <p className="text-danger">{e}</p>
                      ))
                    : null}
                </div>

                <div className="col-6 mt-4 form-group">
                  <p className="land_fname">Additional Comments (Optional)</p>
                  <textarea
                    required
                    className="comment-area"
                    defaultValue={additional_comments}
                    onChange={(e) => setadditional_comments(e.target.value)}
                    cols="88"
                    rows="4"
                  ></textarea>
                  {bund?.additional_comments
                    ? bund?.additional_comments?.map((e) => (
                        <p className="text-danger">{e}</p>
                      ))
                    : null}
                </div>

                <div>
                  <div className="row mt-4">
                    <label className="text-scolor fontsbtn">
                      Next is <span className="fontsbtncobor">Demographic</span>
                    </label>
                  </div>

                  <button
                    id="btn-save"
                    className="btn btn-primary rounded"
                    type="submit"
                  >
                    Save & Continue &nbsp;
                    <AiOutlineArrowRight />
                  </button>
                </div>
              </div>

              <ProfileInfo />
              <div className="footerimage3">
                <img
                  src="https://bankerbroker.developer-oa.xyz/assets/img/footercity.svg"
                  width="100%"
                  alt=""
                />
              </div>
            </div>
          </div>
        </form>
      ) : null}
    </>
  );
}

export default TanBackground;
