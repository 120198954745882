/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "./TanSideBar.css";
import { Link, useHistory, useLocation } from "react-router-dom";
import { FaCheckCircle, FaBars, FaCaretDown } from "react-icons/fa";
import axios from "axios";
import Baseurl from "../../../../../Baseurl";
import Swal from "sweetalert2";
import { Progress } from "antd";
// import usamajr from "../../purchase/Personalinfo";

const SidebarDashNew = ({submitDataId}) => {
  const [allPostData, setAllPostData] = useState();
  const application_id = localStorage.getItem("assignId");
  const reviewData = new FormData();
  reviewData.append("application_id", application_id);

  const location = useLocation();
  const pers =
    location.pathname === "/rent/tenant/personalinfo"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100 ";
  const addit =
    location.pathname === "/rent/tanent/additionalinfo"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const cosi =
    location.pathname === "/rent/tanent/cosigner"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const inc =
    location.pathname === "/rent/tanent/Income"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const back =
    location.pathname === "/rent/tenant/background"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const demo =
    location.pathname === "/rent/tanent/demographic"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const docu =
    location.pathname === "/rent/tanent/document"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";

  const review =
    location.pathname === "/rent/tanent/review"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const postData = () => {
    const token = localStorage.getItem("usertoken");

    var config = {
      method: "post",
      url: `${Baseurl.baseurl}real-estate/rent/tenant/progress`,
      data: reviewData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        setAllPostData(response?.data?.data);
        console.log(response?.data?.data, "sidebar response");
        if (response?.data?.status === true) {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
      })
      .catch(function (error) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      });
  };
  useEffect(() => {
    postData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [isOpen, setIsOpen] = useState(true);

  const handleToggle = () => {
    if (isOpen === false) {
      setIsOpen(true);
    }
    if (isOpen === true) {
      setIsOpen(false);
    } else {
      console.log(isOpen, "hui");
    }
    console.log(isOpen, "huihui");
  };

  const isDisabled = submitDataId;

  return (
    <>
      <FaBars
        class=" none"
        onClick={() => {
          handleToggle();
        }}
      />

      <FaBars
        class=" block"
        id="topnav-hamburger-icon"
        onClick={() => {
          handleToggle();
        }}
      />

      <div
        className={
          isOpen === true
            ? "col-md-2 ps-0 sidebarmain fixed_side sidebar-nav open"
            : "d-none"
        }
      >
        <div className="px-4 my-3">
          <Link to="#">Dashboard</Link>
          <br />
          <span>Tenant</span>
          <Progress percent={allPostData} status="active" />
        </div>
        <div className="tangreyline"></div>

        {isDisabled ? (
          <div className={`${pers} disabled_side`}>
            <div className="sidecircle">
              {location.pathname === "/rent/tenant/personalinfo" && (
                <FaCheckCircle className="checkicon" />
              )}
            </div>
            <div className="mort grey_color fw-500">Personal Info</div>
            <div></div>
          </div>
        ) : (
          <Link to="/rent/tenant/personalinfo">
            <div className={pers}>
              <div className="sidecircle">
                {location.pathname === "/rent/tenant/personalinfo" && (
                  <FaCheckCircle className="checkicon" />
                )}
              </div>
              <div className="mort grey_color fw-500">Personal Info</div>
              <div></div>
            </div>
          </Link>
        )}

        {isDisabled ? (
          <div className={`${cosi} disabled_side`}>
            <div className="sidecircle">
              {location.pathname === "/rent/tanent/cosigner" && (
                <FaCheckCircle className="checkicon" />
              )}
            </div>
            <div className="mort grey_color fw-500">Co Signers & Occupants</div>
            <div></div>
          </div>
        ) : (
          <Link to="/rent/tanent/cosigner">
            <div className={cosi}>
              <div className="sidecircle">
                {location.pathname === "/rent/tanent/cosigner" && (
                  <FaCheckCircle className="checkicon" />
                )}
              </div>
              <div className="mort grey_color fw-500">
                Co Signers & Occupants
              </div>
              <div></div>
            </div>
          </Link>
        )}

        {isDisabled ? (
          <div className={`${inc} disabled_side`}>
            <div className="sidecircle">
              {location.pathname === "/rent/tanent/Income" && (
                <FaCheckCircle className="checkicon" />
              )}
            </div>
            <div className="mort grey_color fw-500">Income</div>
            <div></div>
          </div>
        ) : (
          <Link to="/rent/tanent/Income">
            <div className={inc}>
              <div className="sidecircle">
                {location.pathname === "/rent/tanent/Income" && (
                  <FaCheckCircle className="checkicon" />
                )}
              </div>
              <div className="mort grey_color fw-500">Income</div>
              <div></div>
            </div>
          </Link>
        )}

        {isDisabled ? (
          <div className={`${back} disabled_side`}>
            <div className="sidecircle">
              {location.pathname === "/rent/tenant/background" && (
                <FaCheckCircle className="checkicon" />
              )}
            </div>
            <div className="mort grey_color fw-500">Background</div>
            <div></div>
          </div>
        ) : (
          <Link to="/rent/tenant/background">
            <div className={back}>
              <div className="sidecircle">
                {location.pathname === "/rent/tenant/background" && (
                  <FaCheckCircle className="checkicon" />
                )}
              </div>
              <div className="mort grey_color fw-500">Background</div>
              <div></div>
            </div>
          </Link>
        )}

        {isDisabled ? (
          <div className={`${addit} disabled_side`}>
            <div className="sidecircle">
              {location.pathname === "/rent/tanent/additionalinfo" && (
                <FaCheckCircle className="checkicon" />
              )}
            </div>
            <div className="mort grey_color fw-500">Additional Info</div>
            <div></div>
          </div>
        ) : (
          <Link to="/rent/tanent/additionalinfo">
            <div className={addit}>
              <div className="sidecircle">
                {location.pathname === "/rent/tanent/additionalinfo" && (
                  <FaCheckCircle className="checkicon" />
                )}
              </div>
              <div className="mort grey_color fw-500">Additional Info</div>
              <div></div>
            </div>
          </Link>
        )}

        {/* Document link without the disable condition */}
        <Link to="/rent/tanent/document">
          <div className={docu}>
            <div className="sidecircle">
              {location.pathname === "/rent/tanent/document" && (
                <FaCheckCircle className="checkicon" />
              )}
            </div>
            <div className="mort grey_color fw-500">Document</div>
            <div></div>
          </div>
        </Link>

        {isDisabled ? (
          <div className={`${review} disabled_side`}>
            <div className="sidecircle">
              {location.pathname === "/rent/tanent/review" && (
                <FaCheckCircle className="checkicon" />
              )}
            </div>
            <div className="mort grey_color fw-500">Review And Submit</div>
            <div></div>
          </div>
        ) : (
          <Link to="/rent/tanent/review">
            <div className={review}>
              <div className="sidecircle">
                {location.pathname === "/rent/tanent/review" && (
                  <FaCheckCircle className="checkicon" />
                )}
              </div>
              <div className="mort grey_color fw-500">Review And Submit</div>
              <div></div>
            </div>
          </Link>
        )}
      </div>
    </>
  );
};

export default SidebarDashNew;
