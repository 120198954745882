import React from "react";

const OptionalIncomeForm = ({
  setFormData,
  formData,
  total_monthly_income,
  optionIncome,
}) => {
  const formatIncome = (input) => {
    const numericValue = input.replace(/[^0-9]/g, "");
    return Number(numericValue).toLocaleString();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const formattedValue = name.includes("monthly")
      ? formatIncome(value)
      : value;
    setFormData((prevState) => ({
      ...prevState,
      [name]: formattedValue,
    }));
  };

  const EmployeIncomeNumberOne = Number(
    total_monthly_income?.replace(/\D/g, "")
  );
  const EmployeIncomeNumberTwo = Number(
    formData?.gross_monthly_amount?.replace(/\D/g, "")
  );
  const EmployeIncomeNumberThree = Number(
    formData?.military_monthly_amount?.replace(/\D/g, "")
  );
  const EmployeIncomeNumberFour = Number(
    formData?.other_monthly_amount?.replace(/\D/g, "")
  );

  const totalIncomeOnes = EmployeIncomeNumberOne + EmployeIncomeNumberTwo;
  const totalIncomeTwos = totalIncomeOnes + EmployeIncomeNumberThree;
  const totalIncomeThrees = totalIncomeTwos + EmployeIncomeNumberFour;

  return (
    <>
      <div className="mt-3 p-0">
        <h5 className="income-hc1">Gross Monthly Income</h5>
      </div>
      <div className="col-md-3">
        <div class="input-group my-3">
          <div className="form-group-new">
            <div className="select-container">
              <select
                required={optionIncome ? true : false}
                name="gross_soi"
                formcontrolname="creditRange"
                className={`form-control-select w-100 ${
                  formData?.gross_soi ? "text-success" : "text-dark"
                }`}
                value={formData?.gross_soi}
                onChange={handleChange}
                onFocus={() =>
                  document
                    ?.querySelector(".label-select")
                    ?.classList?.add("label-top")
                }
                onBlur={() =>
                  !formData?.gross_soi &&
                  document
                    ?.querySelector(".label-select")
                    ?.classList?.remove("label-top")
                }
              >
                <option value="" disabled></option>
                <option value={"Base Amount"}>
                  {" "}
                  Base Amount{" "}
                  {formData?.gross_soi === "Base Amount" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value={"Bonus Amount"}>
                  {" "}
                  Bonus Amount{" "}
                  {formData?.gross_soi === "Bonus Amount" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value={"Commission Amount"}>
                  {" "}
                  Commission Amount{" "}
                  {formData?.gross_soi === "Commission Amount" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value={"Overtime Amount"}>
                  {" "}
                  Overtime Amount{" "}
                  {formData?.gross_soi === "Overtime Amount" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value={"Military Entitlement"}>
                  {" "}
                  Military Entitlement{" "}
                  {formData?.gross_soi === "Military Entitlement" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value={"Other Amount"}>
                  {" "}
                  Other Amount{" "}
                  {formData?.gross_soi === "Other Amount" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
              </select>
              <label
                className={`label-new ${
                  formData?.gross_soi ? "label-top" : ""
                }`}
                htmlFor="estimated_credit_score"
              >
                Source Of Income
              </label>
            </div>
          </div>

          {formData?.gross_soi?.length > 4 ? (
            <p
              style={{
                fontSize: "16px",
                color: "green",
                fontWeight: "bold",
                position: "absolute",
                right: "15px",
                top: "10px",
                zIndex: "123",
              }}
            >
              ✔
            </p>
          ) : null}
        </div>
      </div>
      <div className="col-md-3">
        <div class="input-group my-3">
          <div class="input-group ">
            <div className="form-group-new">
              <input
                className="form-control-new rounded-0 text-capitalize"
                id="inputheight1"
                placeholder=" "
                type="text"
                name="gross_note"
                onChange={handleChange}
                value={formData?.gross_note}
                required={optionIncome ? true : false}
              />
              <label
                htmlFor="inputheight1"
                className="label-new"
                style={{ fontSize: "12px" }}
              >
                Optional Note
              </label>
            </div>
            {formData?.gross_note?.length > 4 ? (
              <p
                style={{
                  fontSize: "16px",
                  color: "green",
                  fontWeight: "bold",
                  position: "absolute",
                  right: "15px",
                  top: "10px",
                  zIndex: "123",
                }}
              >
                ✔
              </p>
            ) : null}
          </div>
        </div>
      </div>
      <div className="col-md-2">
        <div class="input-group my-3">
          <div className="form-group-new">
            <input
              className="form-control-new rounded-0 text-capitalize"
              id="inputheight1"
              placeholder=" "
              type="date"
              name="gross_ret_date"
              onChange={handleChange}
              value={formData?.gross_ret_date}
              required={optionIncome ? true : false}
            />
            <label
              htmlFor="inputheight1"
              className="label-new"
              style={{ fontSize: "12px" }}
            >
              Retirement Date
            </label>
          </div>
          {formData?.gross_ret_date?.length > 10 ? (
            <p
              style={{
                fontSize: "16px",
                color: "green",
                fontWeight: "bold",
                position: "absolute",
                right: "15px",
                top: "10px",
                zIndex: "123",
              }}
            >
              ✔
            </p>
          ) : null}
        </div>
      </div>
      <div className="col-md-2">
        <div class="input-group my-3">
          <div className="form-group-new">
            <input
              className="form-control-new rounded-0 text-capitalize"
              id="inputheight1"
              placeholder=" "
              type="text"
              name="gross_monthly_amount"
              onChange={handleChange}
              required={optionIncome ? true : false}
              value={
                formData?.gross_monthly_amount
                  ? "$" + formData?.gross_monthly_amount
                  : ""
              }
            />
            <label
              htmlFor="inputheight1"
              className="label-new"
              style={{ fontSize: "11px" }}
            >
              Monthly Amount
            </label>
          </div>
          {formData?.gross_monthly_amount?.length > 4 ? (
            <p
              style={{
                fontSize: "16px",
                color: "green",
                fontWeight: "bold",
                position: "absolute",
                right: "15px",
                top: "10px",
                zIndex: "123",
              }}
            >
              ✔
            </p>
          ) : null}
        </div>
      </div>
      <div className="col-md-2">
        <p
          className="fw-semibold total-fnt1 mb-2"
          style={{ marginTop: "-24px" }}
        >
          Income Two
        </p>
        <div class="input-group my-3">
          <div className="form-group-new">
            <input
              className="form-control-new rounded-0 text-capitalize"
              placeholder=" "
              type="text"
              name="totalOtherIncomeOne"
              value={
                totalIncomeOnes ? "$" + totalIncomeOnes?.toLocaleString() : ""
              }
              disabled
            />
            <label
              htmlFor="inputheight1"
              className="label-new"
              style={{ fontSize: "10px" }}
            >
              Total Other Income
            </label>
          </div>
          {/* {formData?.totalOtherIncomeOne?.length > 4 ? (
              <p
                style={{
                  fontSize: "16px",
                  color: "green",
                  fontWeight: "bold",
                  position: "absolute",
                  right: "15px",
                  top: "10px",
                  zIndex: "123",
                }}
              >
                ✔
              </p>
            ) : null} */}
        </div>
      </div>
      <div className="mt-3 p-0 ">
        <h5 className="income-hc1">Other Income</h5>
      </div>
      <div className="col-md-3">
        <div class="input-group my-3">
          <div className="form-group-new">
            <div className="select-container">
              <select
              required={optionIncome ? true : false}
                name="military_soi"
                formcontrolname="creditRange"
                className={`form-control-select w-100 ${
                  formData?.military_soi ? "text-success" : "text-dark"
                }`}
                onChange={handleChange}
                value={formData?.military_soi}
                onFocus={() =>
                  document
                    ?.querySelector(".label-select")
                    ?.classList?.add("label-top")
                }
                onBlur={() =>
                  !formData?.military_soi &&
                  document
                    ?.querySelector(".label-select")
                    ?.classList?.remove("label-top")
                }
              >
                <option value="" disabled></option>
                <option value="Alimony">
                  {" "}
                  Alimony
                  {formData?.military_soi === "Alimony" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>

                <option value="AutomobileAllowance">
                  Automobile Allowance
                  {formData?.military_soi === "AutomobileAllowance" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value="BoarderIncome">
                  {" "}
                  Boarder Income
                  {formData?.military_soi === "BoarderIncome" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value="CapitalGains">
                  {" "}
                  Capital Gains
                  {formData?.military_soi === "CapitalGains" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value="ChildSupport">
                  {" "}
                  Child Support
                  {formData?.military_soi === "ChildSupport" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value="Disability">
                  {" "}
                  Disability
                  {formData?.military_soi === "Disability" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value="FosterCare">
                  {" "}
                  Foster Care
                  {formData?.military_soi === "FosterCare" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value="HousingAllowance">
                  {" "}
                  Housing or Parsonage
                  {formData?.military_soi === "HousingAllowance" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value="DividendsInterest">
                  {" "}
                  Interest and Dividends
                  {formData?.military_soi === "DividendsInterest" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value="MortgageCreditCertificate">
                  Mortgage Credit Certificate{" "}
                  {formData?.military_soi === "MortgageCreditCertificate" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value="MortgageDifferential">
                  Mortgage Differential Payments{" "}
                  {formData?.military_soi === "MortgageDifferential" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value="NotesReceivableInstallment">
                  Notes Receivable
                  {formData?.military_soi === "NotesReceivableInstallment" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value="PublicAssistance">
                  {" "}
                  Public Assistance
                  {formData?.military_soi === "PublicAssistance" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value="Pension">
                  {" "}
                  Retirement
                  {formData?.military_soi === "Pension" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value="Royalties">
                  {" "}
                  Royalty Payments
                  {formData?.military_soi === "Royalties" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value="SeparateMaintenance">
                  {" "}
                  Separate Maintenance
                  {formData?.military_soi === "SeparateMaintenance" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value="SocialSecurity">
                  {" "}
                  Social Security
                  {formData?.military_soi === "SocialSecurity" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value="TipIncome">
                  {" "}
                  Tip Income
                  {formData?.military_soi === "TipIncome" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value="Trust">
                  Trust Income
                  {formData?.military_soi === "Trust" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value="Unemployment">
                  {" "}
                  Unemployment Benefits
                  {formData?.military_soi === "Unemployment" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value="VABenefitsNonEducational">
                  {" "}
                  VA Compensation
                  {formData?.military_soi === "VABenefitsNonEducational" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value="TemporaryLeave">
                  {" "}
                  Temporary Leave
                  {formData?.military_soi === "TemporaryLeave" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value="MiscellaneousIncome">
                  Miscellaneous Income
                  {formData?.military_soi === "MiscellaneousIncome" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value="AccessoryUnitIincome">
                  Accessory Unit Income
                  {formData?.military_soi === "AccessoryUnitIincome" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value="DefinedContributionPlan">
                  Defined Contribution Plan{" "}
                  {formData?.military_soi === "DefinedContributionPlan" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value="EmploymentRelatedAccount">
                  Employment Related Account{" "}
                  {formData?.military_soi === "EmploymentRelatedAccount" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value="HousingChoiceVoucherProgram">
                  Housing Choice Voucher Program{" "}
                  {formData?.military_soi === "HousingChoiceVoucherProgram" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value="NonBorrowerHouseholdIncome">
                  Non Borrower Household Income{" "}
                  {formData?.military_soi === "NonBorrowerHouseholdIncome" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value="ContractBasis">
                  {" "}
                  Contract Basis
                  {formData?.military_soi === "ContractBasis" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value="Other" selected="">
                  Other
                  {formData?.military_soi === "Other" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
              </select>
              <label
                className={`label-new ${
                  formData?.military_soi ? "label-top" : ""
                }`}
                htmlFor="estimated_credit_score"
              >
                Alimony
              </label>
            </div>
          </div>

          {formData?.gross_soi?.length > 4 ? (
            <p
              style={{
                fontSize: "16px",
                color: "green",
                fontWeight: "bold",
                position: "absolute",
                right: "15px",
                top: "10px",
                zIndex: "123",
              }}
            >
              ✔
            </p>
          ) : null}
        </div>
      </div>
      <div className="col-md-3">
        <div class="input-group my-3">
          <div class="input-group ">
            <div className="form-group-new">
              <input
                className="form-control-new rounded-0 text-capitalize"
                id="inputheight1"
                placeholder=" "
                type="text"
                name="military_note"
                onChange={handleChange}
                value={formData?.military_note}
                required={optionIncome ? true : false}
              />
              <label
                htmlFor="inputheight1"
                className="label-new"
                style={{ fontSize: "12px" }}
              >
                Optional Note
              </label>
            </div>
            {formData?.military_note?.length > 4 ? (
              <p
                style={{
                  fontSize: "16px",
                  color: "green",
                  fontWeight: "bold",
                  position: "absolute",
                  right: "15px",
                  top: "10px",
                  zIndex: "123",
                }}
              >
                ✔
              </p>
            ) : null}
          </div>
        </div>
      </div>
      <div className="col-md-2">
        <div class="input-group my-3">
          <div className="form-group-new">
            <input
              className="form-control-new rounded-0 text-capitalize"
              id="inputheight1"
              placeholder=" "
              type="date"
              name="military_ret_date"
              onChange={handleChange}
              value={formData?.military_ret_date}
              required={optionIncome ? true : false}
            />
            <label
              htmlFor="inputheight1"
              className="label-new"
              style={{ fontSize: "12px" }}
            >
              Retirement Date
            </label>
          </div>
          {formData?.military_ret_date?.length > 10 ? (
            <p
              style={{
                fontSize: "16px",
                color: "green",
                fontWeight: "bold",
                position: "absolute",
                right: "15px",
                top: "10px",
                zIndex: "123",
              }}
            >
              ✔
            </p>
          ) : null}
        </div>
      </div>
      <div className="col-md-2">
        <div class="input-group my-3">
          <div className="form-group-new">
            <input
              className="form-control-new rounded-0 text-capitalize"
              id="inputheight1"
              placeholder=" "
              type="text"
              name="military_monthly_amount"
              onChange={handleChange}
              value={
                formData?.military_monthly_amount
                  ? "$" + formData?.military_monthly_amount
                  : ""
              }
              required={optionIncome ? true : false}
            />
            <label
              htmlFor="inputheight1"
              className="label-new"
              style={{ fontSize: "11px" }}
            >
              Monthly Amount
            </label>
          </div>
          {formData?.military_monthly_amount?.length > 4 ? (
            <p
              style={{
                fontSize: "16px",
                color: "green",
                fontWeight: "bold",
                position: "absolute",
                right: "15px",
                top: "10px",
                zIndex: "123",
              }}
            >
              ✔
            </p>
          ) : null}
        </div>
      </div>
      <div className="col-md-2">
        <p
          className="fw-semibold total-fnt1 mb-2"
          style={{ marginTop: "-24px" }}
        >
          Income Three
        </p>
        <div class="input-group my-3">
          <div className="form-group-new">
            <input
              className="form-control-new rounded-0 text-capitalize"
              placeholder=" "
              type="text"
              value={
                totalIncomeTwos ? "$" + totalIncomeTwos?.toLocaleString() : ""
              }
              disabled
            />
            <label
              htmlFor="inputheight1"
              className="label-new"
              style={{ fontSize: "10px" }}
            >
              Total Other Income
            </label>
          </div>
          {/* {formData?.length > 4 ? (
              <p
                style={{
                  fontSize: "16px",
                  color: "green",
                  fontWeight: "bold",
                  position: "absolute",
                  right: "15px",
                  top: "10px",
                  zIndex: "123",
                }}
              >
                ✔
              </p>
            ) : null} */}
        </div>
      </div>
      <div className="mt-3 p-0 ">
        <h5 className="income-hc1">Military Income</h5>
      </div>
      <div className="col-md-3">
        <div class="input-group my-3">
          <div className="form-group-new">
            <div className="select-container">
              <select
                name="other_soi"
                required={optionIncome ? true : false}
                formcontrolname="creditRange"
                className={`form-control-select w-100 ${
                  formData?.other_soi ? "text-success" : "text-dark"
                }`}
                value={formData?.other_soi}
                onChange={handleChange}
                onFocus={() =>
                  document
                    ?.querySelector(".label-select")
                    ?.classList?.add("label-top")
                }
                onBlur={() =>
                  !formData?.other_soi &&
                  document
                    ?.querySelector(".label-select")
                    ?.classList?.remove("label-top")
                }
              >
                <option value="" disabled></option>
                <option value={"Militry Base"}>
                  {" "}
                  Militry Base{" "}
                  {formData?.other_soi === "Militry Base" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value={"Military Clothes Allowance"}>
                  {" "}
                  Military Clothes Allowance{" "}
                  {formData?.other_soi === "Military Clothes Allowance" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value={"Military Combat Pay"}>
                  {" "}
                  Military Combat Pay{" "}
                  {formData?.other_soi === "Military Combat Pay" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value={"Military Flight Pay"}>
                  {" "}
                  Military Flight Pay{" "}
                  {formData?.other_soi === "Military Flight Pay" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value={"Military Hazard Pay"}>
                  {" "}
                  Military Hazard Pay{" "}
                  {formData?.other_soi === "Military Hazard Pay" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value={"Military Overseas Pay"}>
                  {" "}
                  Military Overseas Pay{" "}
                  {formData?.other_soi === "Military Overseas Pay" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value={"Military Prop Pay"}>
                  {" "}
                  Military Prop Pay{" "}
                  {formData?.other_soi === "Military Prop Pay" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value={"Military Quarters Allowance"}>
                  {" "}
                  Military Quarters Allowance{" "}
                  {formData?.other_soi === "Military Quarters Allowance" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value={"Military Rations Allowance"}>
                  {" "}
                  Military Rations Allowance{" "}
                  {formData?.other_soi === "Military Rations Allowance" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value={"Military Variable Housing Allowance"}>
                  {" "}
                  Military Variable Housing Allowance{" "}
                  {formData?.other_soi ===
                  "Military Variable Housing Allowance" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
              </select>
              <label
                className={`label-new ${
                  formData?.other_soi ? "label-top" : ""
                }`}
                htmlFor="estimated_credit_score"
              >
                Source Of Income
              </label>
            </div>
          </div>

          {formData?.other_soi?.length > 4 ? (
            <p
              style={{
                fontSize: "16px",
                color: "green",
                fontWeight: "bold",
                position: "absolute",
                right: "15px",
                top: "10px",
                zIndex: "123",
              }}
            >
              ✔
            </p>
          ) : null}
        </div>
      </div>
      <div className="col-md-3">
        <div class="input-group my-3">
          <div class="input-group ">
            <div className="form-group-new">
              <input
                className="form-control-new rounded-0 text-capitalize"
                id="inputheight1"
                placeholder=" "
                type="text"
                name="other_note"
                onChange={handleChange}
                value={formData?.other_note}
                required={optionIncome ? true : false}
              />
              <label
                htmlFor="inputheight1"
                className="label-new"
                style={{ fontSize: "12px" }}
              >
                Optional Note
              </label>
            </div>
            {formData?.other_note?.length > 4 ? (
              <p
                style={{
                  fontSize: "16px",
                  color: "green",
                  fontWeight: "bold",
                  position: "absolute",
                  right: "15px",
                  top: "10px",
                  zIndex: "123",
                }}
              >
                ✔
              </p>
            ) : null}
          </div>
        </div>
      </div>
      <div className="col-md-2">
        <div class="input-group my-3">
          <div className="form-group-new">
            <input
              className="form-control-new rounded-0 text-capitalize"
              id="inputheight1"
              placeholder=" "
              type="date"
              name="other_ret_date"
              onChange={handleChange}
              value={formData?.other_ret_date}
              required={optionIncome ? true : false}
            />
            <label
              htmlFor="inputheight1"
              className="label-new"
              style={{ fontSize: "12px" }}
            >
              Retirement Date
            </label>
          </div>
          {formData?.other_ret_date?.length > 10 ? (
            <p
              style={{
                fontSize: "16px",
                color: "green",
                fontWeight: "bold",
                position: "absolute",
                right: "15px",
                top: "10px",
                zIndex: "123",
              }}
            >
              ✔
            </p>
          ) : null}
        </div>
      </div>
      <div className="col-md-2">
        <div class="input-group my-3">
          <div className="form-group-new">
            <input
              className="form-control-new rounded-0 text-capitalize"
              id="inputheight1"
              placeholder=" "
              type="text"
              name="other_monthly_amount"
              onChange={handleChange}
              value={
                formData?.other_monthly_amount
                  ? "$" + formData?.other_monthly_amount
                  : ""
              }
              required={optionIncome ? true : false}
            />
            <label
              htmlFor="inputheight1"
              className="label-new"
              style={{ fontSize: "11px" }}
            >
              Monthly Amount
            </label>
          </div>
          {formData?.other_monthly_amount?.length > 4 ? (
            <p
              style={{
                fontSize: "16px",
                color: "green",
                fontWeight: "bold",
                position: "absolute",
                right: "15px",
                top: "10px",
                zIndex: "123",
              }}
            >
              ✔
            </p>
          ) : null}
        </div>
      </div>
      <div className="col-md-2">
        <p
          className="fw-semibold total-fnt1 mb-2"
          style={{ marginTop: "-24px" }}
        >
          Income Four
        </p>
        <div class="input-group my-3">
          <div className="form-group-new">
            <input
              className="form-control-new rounded-0 text-capitalize"
              placeholder=" "
              type="text"
              value={
                totalIncomeThrees
                  ? "$" + totalIncomeThrees?.toLocaleString()
                  : ""
              }
              disabled
            />
            <label
              htmlFor="inputheight1"
              className="label-new"
              style={{ fontSize: "10px" }}
            >
              Total Other Income
            </label>
          </div>
          {/* {formData?.length > 4 ? (
              <p
                style={{
                  fontSize: "16px",
                  color: "green",
                  fontWeight: "bold",
                  position: "absolute",
                  right: "15px",
                  top: "10px",
                  zIndex: "123",
                }}
              >
                ✔
              </p>
            ) : null} */}
        </div>
      </div>
    </>
  );
};

export default OptionalIncomeForm;
