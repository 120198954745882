/* eslint-disable react/style-prop-object */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Baseurl from "../../../../../Baseurl";

const DocumentReviewSubmit = ({
  personalInfo,
  income,
  itsForm,
  coSinger,
  additionalInfo,
  submitData,
}) => {
  return (
    <>
      <div className="container">
        <p
          className="col-lg-12 text-center"
          style={{
            padding: "0px",
            fontSize: 22,
            fontFamily: "Arial",
            color: "black",
          }}
        >
          APPLICATION TO RENT
        </p>
        <p
          className="col-lg-12"
          style={{
            padding: "0px",
            fontSize: 18,
            fontFamily: "Arial",
            color: "black",
          }}
        >
          (All sections must be completed) Individual applications are required
          for each individual 18 years of age or older. If a section does not
          apply to you, please enter N/A - not applicable.)
        </p>
      </div>
      <div
        className="container"
        style={{
          backgroundColor: "#fff",
          webkitBorderRadius: "3px",
          mozBorderRadius: "3px",
          borderRadius: "3px",
          webkitBoxShadow:
            "0 1px 3px rgba(0, 0, 0, 0.12),0 1px 2px rgba(0, 0, 0, 0.24)",
          mozBoxShadow:
            "0 1px 3px rgba(0, 0, 0, 0.12),0 1px 2px rgba(0, 0, 0, 0.24)",
          boxShadow:
            "0 1px 3px rgba(0, 0, 0, 0.12),0 1px 2px rgba(0, 0, 0, 0.24)",
          color: "black",
        }}
      >
        <div
          className="row py-3 fw-bold"
          style={{
            fontSize: 16,
            fontFamily: "Arial",
            marginTop: 20,
            lineHeight: "normal;",
            border: "1px solid black",
          }}
        >
          {/* One Line Code Start */}
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            <div className="ps-2">Last Name</div>
            <div
              className="col-lg-12 ps-2"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              {personalInfo?.last_name ? personalInfo?.last_name : "- -"}
            </div>
          </p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            First Name
            <div
              className="col-lg-12"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              {personalInfo?.first_name ? personalInfo?.first_name : "- -"}
            </div>
          </p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            Middle Name
            <div
              className="col-lg-12"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              {personalInfo?.middle_name ? personalInfo?.middle_name : "- -"}
            </div>
          </p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            Social Secruity #
            <div
              className="col-lg-12"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              {personalInfo?.ssn ? personalInfo?.ssn : "- -"}
            </div>
          </p>
          {/* One Line Code End */}

          {/* Two Line Code Start */}
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            <div className="ps-2">Other names used in the past</div>
            <div
              className="col-lg-12 ps-2"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              {personalInfo?.middle_name ? personalInfo?.middle_name : "- -"}
            </div>
          </p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            Date of Birth
            <div
              className="col-lg-12"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              {personalInfo?.dob ? personalInfo?.dob : "- -"}
            </div>
          </p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            Home #
            <div
              className="col-lg-12"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              {personalInfo?.home_phone ? personalInfo?.home_phone : "- -"}
            </div>
          </p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            Work #
            <div
              className="col-lg-12"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              {personalInfo?.work_phone ? personalInfo?.work_phone : "- -"}
            </div>
          </p>
          {/* Two Line Code End */}

          {/* Three Line Code Start */}
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            <div>Goverment Issued Photo ID Type</div>
            <div
              className="col-lg-12 ps-2"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              - -{" "}
            </div>
          </p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            ID #
            <div
              className="col-lg-12"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              - -{" "}
            </div>
          </p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            Issued by
            <div
              className="col-lg-12"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              - -{" "}
            </div>
          </p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            Expiration Date
            <div
              className="col-lg-12"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              - -
            </div>
          </p>
          {/* Three Line Code End */}

          {/* Four Line Code Start */}
          <p
            className="col-lg-2"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            <div className="ps-2">Present Address</div>
            <div
              className="col-lg-12 ps-2"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              {personalInfo?.have_mailing_city
                ? personalInfo?.have_mailing_city
                : "- -"}
            </div>
          </p>
          <p
            className="col-lg-2"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            City
            <div
              className="col-lg-12"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              {personalInfo?.have_mailing_city
                ? personalInfo?.have_mailing_city
                : "- -"}
            </div>
          </p>
          <p
            className="col-lg-2"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            State
            <div
              className="col-lg-12"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              {personalInfo?.have_mailing_state
                ? personalInfo?.have_mailing_state
                : "- -"}
            </div>
          </p>
          <p
            className="col-lg-2"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            Zip
            <div
              className="col-lg-12"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              {personalInfo?.have_mailing_zipcode
                ? personalInfo?.have_mailing_zipcode
                : "- -"}
            </div>
          </p>
          <p
            className="col-lg-2"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            Move in date
            <div
              className="col-lg-12"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              {personalInfo?.start_date ? personalInfo?.start_date : "- -"}
            </div>
          </p>
          <p
            className="col-lg-2"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            Move out date
            <div
              className="col-lg-12"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              {personalInfo?.end_date ? personalInfo?.end_date : "- -"}
            </div>
          </p>
          {/* Four Line Code End */}

          {/* Five Line Code Start */}
          <p
            className="col-lg-4"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            <div className="ps-2">Reason for moving</div>
            <div
              className="col-lg-12 ps-2"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              {itsForm?.moving_reason ? itsForm?.moving_reason : "- -"}
            </div>
          </p>
          <p
            className="col-lg-4"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            Landlord/Owner Name and phone number
            <div
              className="col-lg-12"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              {personalInfo?.landlord_first_name &&
              personalInfo?.landlord_phone_no
                ? personalInfo?.landlord_first_name +
                  personalInfo?.landlord_phone_no
                : "- -"}
            </div>
          </p>
          <p
            className="col-lg-4"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            {/*  */}
            <div
              className="col-lg-12"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
                height: "26px",
                marginTop: "24px",
              }}
            >
              {/*  */}
            </div>
          </p>
          {/* Five Line Code End */}

          {/* Six Line Code Start */}
          <p
            className="col-lg-12"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            <div className="ps-2">
              Previous Address (if less than three years at present:)
            </div>
          </p>
          {/* Six Line Code End */}

          {/* Seven Line Code Start */}
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            <div className="ps-2">Address</div>
            <div
              className="col-lg-12 ps-2"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              {itsForm?.previous_address
                ? itsForm?.previous_address.split(" ").slice(0, 2).join(" ")
                : "- -"}
            </div>
          </p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            City
            <div
              className="col-lg-12"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              {personalInfo?.have_mailing_city
                ? personalInfo?.have_mailing_city
                : "- -"}
            </div>
          </p>
          <p
            className="col-lg-2"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            State
            <div
              className="col-lg-12"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              {personalInfo?.have_mailing_state
                ? personalInfo?.have_mailing_state
                : "- -"}
            </div>
          </p>
          <p
            className="col-lg-2"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            Zip
            <div
              className="col-lg-12"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              {personalInfo?.have_mailing_zipcode
                ? personalInfo?.have_mailing_zipcode
                : "- -"}
            </div>
          </p>
          <p
            className="col-lg-2"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            From/to
            <div
              className="col-lg-12"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              {itsForm?.start_date && itsForm?.end_date
                ? itsForm?.start_date + " " + itsForm?.end_date
                : "- -"}
            </div>
          </p>
          {/* Seven Line Code End */}

          {/* Eight Line Code Start */}
          <p
            className="col-lg-4"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            <div className="ps-2">Lanlord name</div>
            <div
              className="col-lg-12 ps-2"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              {personalInfo?.landlord_first_name
                ? personalInfo?.landlord_first_name
                : "- -"}
            </div>
          </p>
          <p
            className="col-lg-4"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            Landlord phone number
            <div
              className="col-lg-12"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              {personalInfo?.landlord_phone_no
                ? personalInfo?.landlord_phone_no
                : "- -"}
            </div>
          </p>
          <p
            className="col-lg-4"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            {/*  */}
            <div
              className="col-lg-12"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
                height: "26px",
                marginTop: "24px",
              }}
            >
              {/*  */}
            </div>
          </p>
          {/* Eight Line Code End */}

          {/* Nine Line Code Start */}
          <p
            className="col-lg-12"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            <div className="ps-2">List all proposed occupants:</div>
          </p>
          {/* Nine Line Code End */}

          {/* Ten Line Code Start */}
          {coSinger?.map((occupant) => (
            <>
              <p
                className="col-lg-3"
                style={{
                  padding: "0px",
                  fontSize: 16,
                  fontFamily: "Arial",
                }}
              >
                <div className="ps-2">Last Name</div>
                <div
                  className="col-lg-12 ps-2"
                  style={{
                    padding: "0px",
                    color: "black",
                    fontStyle: "italic",
                    fontSize: 16,
                    fontFamily: "Arial",
                    borderTop: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                >
                  {" "}
                  {occupant?.last_name ? occupant?.last_name : "- -"}
                </div>
              </p>
              <p
                className="col-lg-3"
                style={{
                  padding: "0px",
                  fontSize: 16,
                  fontFamily: "Arial",
                }}
              >
                First
                <div
                  className="col-lg-12"
                  style={{
                    padding: "0px",
                    color: "black",
                    fontStyle: "italic",
                    fontSize: 16,
                    fontFamily: "Arial",
                    borderTop: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                >
                  {" "}
                  {occupant?.first_name ? occupant?.first_name : "- -"}
                </div>
              </p>
              <p
                className="col-lg-3"
                style={{
                  padding: "0px",
                  fontSize: 16,
                  fontFamily: "Arial",
                }}
              >
                Middle
                <div
                  className="col-lg-12"
                  style={{
                    padding: "0px",
                    color: "black",
                    fontStyle: "italic",
                    fontSize: 16,
                    fontFamily: "Arial",
                    borderTop: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                >
                  {" "}
                  {occupant?.first_name ? occupant?.first_name : "- -"}
                </div>
              </p>
              <p
                className="col-lg-3"
                style={{
                  padding: "0px",
                  fontSize: 16,
                  fontFamily: "Arial",
                }}
              >
                Date of Birth
                <div
                  className="col-lg-12"
                  style={{
                    padding: "0px",
                    color: "black",
                    fontStyle: "italic",
                    fontSize: 16,
                    fontFamily: "Arial",
                    borderTop: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                >
                  {" "}
                  {occupant?.dob ? occupant?.dob : "- -"}
                </div>
              </p>
            </>
          ))}
          {/* Ten Line Code End */}

          {/* Eleven Line Code Start */}
          <p
            className="col-lg-5"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            <div className="ps-2">Employment and/or source of income</div>
          </p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            Do you own property ?
          </p>
          <p
            className="col-lg-2"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            Yes
          </p>
          <p
            className="col-lg-2"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            No
          </p>

          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            <div className="ps-2">Present Employer</div>
            <div
              className="col-lg-12 ps-2"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              {income?.employer_name ? income?.employer_name : "- -"}
            </div>
          </p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            Address/City
            <div
              className="col-lg-12"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              {income?.city ? income?.city : "- -"}
            </div>
          </p>
          <p
            className="col-lg-1"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            State
            <div
              className="col-lg-12"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              {income?.state ? income?.state : "- -"}
            </div>
          </p>
          <p
            className="col-lg-1"
            style={{
              fontSize: 16,
              fontFamily: "Arial",
              padding: "0px",
            }}
          >
            Zip
            <div
              className="col-lg-12"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              {income?.zip_code ? income?.zip_code : "- -"}
            </div>
          </p>
          <p
            className="col-lg-4"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            Employments dates from/to
            <div
              className="col-lg-12"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              {income?.start_date && income?.end_date
                ? income?.start_date + " " + income?.end_date
                : "- -"}
            </div>
          </p>
          {/* Eleven Line Code End */}

          {/* Tweleve Line Code Start */}
          <p
            className="col-lg-12"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            <div className="ps-2">Other income/source</div>
            <div
              className="col-lg-12 ps-2"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              {income?.total_monthly_income
                ? income?.total_monthly_income
                : "- -"}
            </div>
          </p>

          <p
            className="col-lg-4"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            <div className="ps-2"> Name of supervisor and phone #</div>
            <div
              className="col-lg-12 ps-2"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              {income?.supervisor_name && income?.employer_phone
                ? income?.supervisor_name + " " + income?.employer_phone
                : "- -"}
            </div>
          </p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            Full or part time
            <div
              className="col-lg-12"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              - -{" "}
            </div>
          </p>
          <p
            className="col-lg-2"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            hourly rate
            <div
              className="col-lg-12"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              ${" "}
            </div>
          </p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            gross monthly income
            <div
              className="col-lg-12"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              ${" "}
              {income?.gross_monthly_amount
                ? income?.gross_monthly_amount
                : "- -"}
            </div>
          </p>
          <p
            className="col-lg-4"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            <div className="ps-2">Name of your bank/Address</div>
            <div
              className="col-lg-12 ps-2"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              {income?.street_address
                ? income?.street_address.split(" ").slice(0, 2).join(" ")
                : "- -"}
            </div>
          </p>
          <p
            className="col-lg-2"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            City
            <div
              className="col-lg-12"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              {income?.city ? income?.city : "- -"}
            </div>
          </p>
          <p
            className="col-lg-2"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            State
            <div
              className="col-lg-12"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              {income?.state ? income?.state : "- -"}
            </div>
          </p>
          <p
            className="col-lg-2"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            Account #
            <div
              className="col-lg-12"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              - -
            </div>
          </p>
          <p
            className="col-lg-2"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            {/* Account # */}
            <div
              className="col-lg-12"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
                height: "26px",
                marginTop: "24px",
              }}
            >
              {" "}
              {/* - - */}
            </div>
          </p>

          <p
            className="col-lg-4"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            {" "}
          </p>
          <p
            className="col-lg-2"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            {" "}
          </p>
          <p
            className="col-lg-2"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            {" "}
          </p>
          <p
            className="col-lg-2"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            Cheking{" "}
          </p>
          <p
            className="col-lg-2"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            {" "}
          </p>

          <p
            className="col-lg-4"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            {" "}
          </p>
          <p
            className="col-lg-2"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            {" "}
          </p>
          <p
            className="col-lg-2"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            {" "}
          </p>
          <p
            className="col-lg-2"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            Savings{" "}
          </p>
          <p
            className="col-lg-2"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            {" "}
          </p>

          {/* Tweleve Line Code End */}

          {/* Page Two Code Start */}
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            <div className="ps-2">Name of creditors</div>
            <div
              className="col-lg-12 ps-2"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              - -{" "}
            </div>
          </p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            Acct #
            <div
              className="col-lg-12"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              - -{" "}
            </div>
          </p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            Monthly Payment
            <div
              className="col-lg-12"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              - -{" "}
            </div>
          </p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            Balance
            <div
              className="col-lg-12"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              - -
            </div>
          </p>

          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            {" "}
          </p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            {" "}
          </p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            ${" "}
          </p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            ${" "}
          </p>

          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            {" "}
          </p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            {" "}
          </p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            ${" "}
          </p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            ${" "}
          </p>

          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            {" "}
          </p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            {" "}
          </p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            ${" "}
          </p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            ${" "}
          </p>

          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            {" "}
          </p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            {" "}
          </p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            ${" "}
          </p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            ${" "}
          </p>

          <p
            className="col-lg-4"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            <div className="ps-2">Do you have ant pets?Yes No</div>
          </p>
          <p
            className="col-lg-2"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            What Kind ?
          </p>
          <p
            className="col-lg-4"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            Liquid Filled Bed?Yes or No
          </p>
          <p
            className="col-lg-2"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            {" "}
          </p>

          <p
            className="col-lg-5"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            <div className="ps-2">Have you ever been evicted ? Yes No</div>
          </p>
          <p
            className="col-lg-6"
            style={{
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            Have you ever filed for bankruptcy ? Yes No
          </p>
          <p
            className="col-lg-1"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            {" "}
          </p>
          <p
            className="col-lg-12"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            <div className="ps-2">
              Have you ever been convicted of a misdmeanor and/or felony ? Yes
              No
            </div>
          </p>

          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
              height: "26px",
            }}
          ></p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          ></p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          ></p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          ></p>

          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
              height: "26px",
            }}
          ></p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          ></p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          ></p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          ></p>

          {additionalInfo?.vehicle_details?.vehicles?.map((vehicle) => (
            <>
              <p
                className="col-lg-3"
                style={{
                  padding: "0px",
                  fontSize: 16,
                  fontFamily: "Arial",
                }}
              >
                <div className="ps-2">Automobile: Make:</div>
                <div
                  className="col-lg-12 ps-2"
                  style={{
                    padding: "0px",
                    color: "black",
                    fontStyle: "italic",
                    fontSize: 16,
                    fontFamily: "Arial",
                    borderTop: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                >
                  {" "}
                  {vehicle?.make ? vehicle?.make : "- -"}
                </div>
              </p>
              <p
                className="col-lg-3"
                style={{
                  padding: "0px",
                  fontSize: 16,
                  fontFamily: "Arial",
                }}
              >
                Model:
                <div
                  className="col-lg-12"
                  style={{
                    padding: "0px",
                    color: "black",
                    fontStyle: "italic",
                    fontSize: 16,
                    fontFamily: "Arial",
                    borderTop: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                >
                  {" "}
                  {vehicle?.model ? vehicle?.model : "- -"}
                </div>
              </p>
              <p
                className="col-lg-3"
                style={{
                  padding: "0px",
                  fontSize: 16,
                  fontFamily: "Arial",
                }}
              >
                Year:
                <div
                  className="col-lg-12"
                  style={{
                    padding: "0px",
                    color: "black",
                    fontStyle: "italic",
                    fontSize: 16,
                    fontFamily: "Arial",
                    borderTop: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                >
                  {" "}
                  {vehicle?.year ? vehicle?.year : "- -"}
                </div>
              </p>
              <p
                className="col-lg-3"
                style={{
                  padding: "0px",
                  fontSize: 16,
                  fontFamily: "Arial",
                }}
              >
                License:
                <div
                  className="col-lg-12"
                  style={{
                    padding: "0px",
                    color: "black",
                    fontStyle: "italic",
                    fontSize: 16,
                    fontFamily: "Arial",
                    borderTop: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                >
                  {" "}
                  {vehicle?.license ? vehicle?.license : "- -"}
                </div>
              </p>
            </>
          ))}

          <p
            className="col-lg-12"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            <div className="ps-2">Emergency notification:</div>
          </p>

          <>
            <p
              className="col-lg-3"
              style={{
                padding: "0px",
                fontSize: 16,
                fontFamily: "Arial",
              }}
            >
              <div className="ps-2">Name</div>
              <div
                className="col-lg-12"
                style={{
                  padding: "0px",
                  color: "black",
                  fontStyle: "italic",
                  fontSize: 16,
                  fontFamily: "Arial",
                  borderTop: "1px solid black",
                  borderBottom: "1px solid black",
                }}
              >
                <div className="ps-2">
                  {personalInfo?.em_name ? personalInfo?.em_name : "- -"}{" "}
                </div>
              </div>
            </p>
            <p
              className="col-lg-5"
              style={{
                padding: "0px",
                fontSize: 16,
                fontFamily: "Arial",
              }}
            >
              Address,Street,City,State,Zip Code
              <div
                className="col-lg-12"
                style={{
                  padding: "0px",
                  color: "black",
                  fontStyle: "italic",
                  fontSize: 16,
                  fontFamily: "Arial",
                  borderTop: "1px solid black",
                  borderBottom: "1px solid black",
                }}
              >
                {" "}
                {personalInfo?.em_address
                  ? personalInfo?.em_address.split(" ").slice(0, 2).join(" ")
                  : "- -"}
              </div>
            </p>
            <p
              className="col-lg-2"
              style={{
                padding: "0px",
                fontSize: 16,
                fontFamily: "Arial",
              }}
            >
              Phone #
              <div
                className="col-lg-12"
                style={{
                  padding: "0px",
                  color: "black",
                  fontStyle: "italic",
                  fontSize: 16,
                  fontFamily: "Arial",
                  borderTop: "1px solid black",
                  borderBottom: "1px solid black",
                }}
              >
                {" "}
                {personalInfo?.em_phone_no ? personalInfo?.em_phone_no : "- -"}
              </div>
            </p>
            <p
              className="col-lg-2"
              style={{
                padding: "0px",
                fontSize: 16,
                fontFamily: "Arial",
              }}
            >
              Relationship
              <div
                className="col-lg-12"
                style={{
                  padding: "0px",
                  color: "black",
                  fontStyle: "italic",
                  fontSize: 16,
                  fontFamily: "Arial",
                  borderTop: "1px solid black",
                  borderBottom: "1px solid black",
                }}
              >
                {" "}
                {personalInfo?.em_relation ? personalInfo?.em_relation : "- -"}
              </div>
            </p>
          </>

          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            <div
              className="col-lg-12"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                marginTop: "24px",
              }}
            >
              {" "}
            </div>
          </p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            <div
              className="col-lg-12"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                marginTop: "24px",
              }}
            >
              {" "}
            </div>
          </p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            <div
              className="col-lg-12"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                marginTop: "24px",
              }}
            >
              {" "}
            </div>
          </p>

          <p
            className="col-lg-12"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            <div className="ps-2">
              Applicant represents that all the above statements are true and
              correct and hereby authorizes verification of the above
              information including, but not limited to, obtaining a credit
              report, processing criminal background, and verifying any and all
              employment and personal references. Applicant consents to allow
              owner/agent to disclose tenancy information to previous or
              subsequent owners/agents.
            </div>
          </p>

          <p
            className="col-lg-12"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            <div className="ps-2">
              Owner/agent will require a payment of $55.00 per applicant to be
              used for screening applicant with reference to credit, criminal,
              employment, and personal background information. Said fee is non-
              refundable and is not applied to rent and/or security of rental
              unit.
            </div>
          </p>

          <p
            className="col-lg-12"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            <div className="ps-2">
              The rent for unit is $ _______________ per month. A security of $
              _______________ will be due upon approval of application and at
              time of signing/executing the monthly rental agreement and
              accepting rules and regulations.
            </div>
          </p>

          <div className="col-lg-7 pt-2">
            <div className="row">
              <p
                className="col-lg-4 mb-0"
                style={{
                  padding: "0px",
                  fontSize: 16,
                  fontFamily: "Arial",
                  borderBottom: "1px solid black",
                  height: "8vh",
                }}
              >
                <div className="ps-2">Applicant Signature</div>
              </p>
              <div
                className="col-lg-8 respnsecss"
                style={{ borderBottom: "1px solid black", height: "8vh" }}
              >
                <img
                  src={`${Baseurl.imgurl + submitData?.signature}`}
                  alt="no Signature"
                  style={{
                    width: "120px",
                    objectFit: "contain",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-5 pt-2">
            <div className="row">
              <p
                className="col-lg-6 mb-0"
                style={{
                  fontSize: 16,
                  fontFamily: "Arial",
                  borderBottom: "1px solid black",
                  height: "8vh",
                }}
              >
                Date
              </p>
              <div
                className="col-lg-6 respnsecss"
                style={{ borderBottom: "1px solid black" }}
              >
                {submitData?.date ? submitData?.date : "- -"}
              </div>
            </div>
          </div>

          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderBottom: "1px solid black",
              height: "30px",
            }}
          ></p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderBottom: "1px solid black",
              height: "30px",
            }}
          ></p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderBottom: "1px solid black",
              height: "30px",
            }}
          ></p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderBottom: "1px solid black",
              height: "30px",
            }}
          ></p>

          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderBottom: "1px solid black",
              height: "14px",
            }}
          ></p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderBottom: "1px solid black",
              height: "14px",
            }}
          ></p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderBottom: "1px solid black",
              height: "14px",
            }}
          ></p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderBottom: "1px solid black",
              height: "14px",
            }}
          ></p>

          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderBottom: "1px solid black",
              height: "14px",
            }}
          ></p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderBottom: "1px solid black",
              height: "14px",
            }}
          ></p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderBottom: "1px solid black",
              height: "14px",
            }}
          ></p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderBottom: "1px solid black",
              height: "14px",
            }}
          ></p>

          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderBottom: "1px solid black",
              height: "14px",
            }}
          ></p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderBottom: "1px solid black",
              height: "14px",
            }}
          ></p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderBottom: "1px solid black",
              height: "14px",
            }}
          ></p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderBottom: "1px solid black",
              height: "14px",
            }}
          ></p>

          <p
            className="col-lg-4"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderBottom: "1px solid black",
            }}
          >
            <div className="ps-2">Office Use</div>
          </p>
          <p
            className="col-lg-4"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderBottom: "1px solid black",
            }}
          >
            Date application picked up
          </p>
          <p
            className="col-lg-4"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderBottom: "1px solid black",
            }}
          >
            Date submitted
          </p>

          <p
            className="col-lg-2"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            <div className="ps-2">Family size</div>
            <div
              className="col-lg-12 ps-2"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              - -{" "}
            </div>
          </p>
          <p
            className="col-lg-4"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            Monthly Income Annual Income
            <div
              className="col-lg-12"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              - -{" "}
            </div>
          </p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            Very Low/Low/Moderate
            <div
              className="col-lg-12"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              - -{" "}
            </div>
          </p>
          <p
            className="col-lg-1"
            style={{
              fontSize: 16,
              fontFamily: "Arial",
              padding: "0px",
            }}
          >
            Rent
            <div
              className="col-lg-12"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              {" "}
              - -
            </div>
          </p>
          <p
            className="col-lg-2"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
            }}
          >
            Unit/address
            <div
              className="col-lg-12"
              style={{
                padding: "0px",
                color: "black",
                fontStyle: "italic",
                fontSize: 16,
                fontFamily: "Arial",
                borderBottom: "1px solid black",
                borderTop: "1px solid black",
              }}
            >
              {" "}
              - -
            </div>
          </p>

          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderBottom: "1px solid black",
              height: "14px",
            }}
          ></p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderBottom: "1px solid black",
              height: "14px",
            }}
          ></p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderBottom: "1px solid black",
              height: "14px",
            }}
          ></p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderBottom: "1px solid black",
              height: "14px",
            }}
          ></p>

          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderBottom: "1px solid black",
              height: "14px",
            }}
          ></p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderBottom: "1px solid black",
              height: "14px",
            }}
          ></p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderBottom: "1px solid black",
              height: "14px",
            }}
          ></p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderBottom: "1px solid black",
              height: "14px",
            }}
          ></p>

          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderBottom: "1px solid black",
              height: "14px",
            }}
          ></p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderBottom: "1px solid black",
              height: "14px",
            }}
          ></p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderBottom: "1px solid black",
              height: "14px",
            }}
          ></p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderBottom: "1px solid black",
              height: "14px",
            }}
          ></p>

          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderBottom: "1px solid black",
              height: "14px",
            }}
          ></p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderBottom: "1px solid black",
              height: "14px",
            }}
          ></p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderBottom: "1px solid black",
              height: "14px",
            }}
          ></p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderBottom: "1px solid black",
              height: "14px",
            }}
          ></p>

          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderBottom: "1px solid black",
              height: "14px",
            }}
          ></p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderBottom: "1px solid black",
              height: "14px",
            }}
          ></p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderBottom: "1px solid black",
              height: "14px",
            }}
          ></p>
          <p
            className="col-lg-3"
            style={{
              padding: "0px",
              fontSize: 16,
              fontFamily: "Arial",
              borderBottom: "1px solid black",
              height: "14px",
            }}
          ></p>
          {/* Page Two Code End */}
        </div>
      </div>
    </>
  );
};

export default DocumentReviewSubmit;
