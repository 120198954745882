import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Header from "../../../Layout/Header";
import Logout from "../../../Layout/Logout";
import Footer from "../../../Layout/Footer";
import Swal from "sweetalert2";
import Baseurl from "../../../../Baseurl";

const RealstateType = () => {
  const [loader, setLoader] = useState(false);

  const GettingidTanent = () => {
    setLoader(true);
    let token = localStorage?.getItem("usertoken");
    var config = {
      method: "post",
      url: `${Baseurl.baseurl}real-estate/rent/assign/application-id`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setLoader(true);
        console.log(response);
        localStorage.setItem("assignId", response?.data?.data?.id);
        localStorage.removeItem("newid");
        if (response.data.status === true) {
          setLoader(false);
          Swal.fire({
            toast: true,
            icon: "success",
            title: response?.data?.message,
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
        } else {
          setLoader(false);
          Swal.fire({
            toast: true,
            icon: "error",
            title: response?.data?.message,
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
        }
      })
      .catch(function (error) {
        console.log(error,'real-estate/rent/assign/application/id')
        setLoader(false);
        Swal.fire({
          toast: true,
          icon: "error",
          title: error,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };

  return (
    <>
     {loader ? <div className="loader"></div> : null}
      <Header />
      <section className="bor_types d-flex justify-content-center align-items-center">
        <div className="container">
          <div className="row mt-3 py-4">
            <div className="card custom_card mt-3 mb-4 mt-lg-5 w-90 mx-auto align-items-center px-5">
              <h3
                style={{ fontSize: "24px", fontWeight: "500" }}
                className="text-center my-4 text-dark"
              >
                Please Select your Option
              </h3>
              <div className="row  justify-content-center">
                <div className="col-md-4 my-3 mt-md-0">
                  <Link to={"#"}>
                    <div className="card cardes py-4">
                      <div className="d-flex flex-column align-items-center justify-content-center h-100">
                        <img
                          src={'/img/type/buyer.png'}
                          alt=""
                          width={"35%"}
                          // height={"100%"}
                          className="text-center "
                        />
                        <h6 className="text-black">Buyer</h6>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-md-4 my-3 mt-md-0">
                  <Link to={"#"}>
                    <div
                      style={{ width: "100%", height: "100%" }}
                      className="card cardes py-4"
                    >
                      <div className="d-flex flex-column align-items-center justify-content-center h-100">
                        <img
                          src={'/img/type/seller.png'}
                          alt=""
                          width={"35%"}
                          // height={"100%"}
                          className="text-center "
                        />
                        <h6 className="text-black">Seller</h6>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-md-4 my-3 mt-md-0" onClick={GettingidTanent}>
                  <Link to={"/heloc"}
                  >
                    <div
                      style={{ width: "100%", height: "100%" }}
                      className="card cardes py-4"
                    >
                      <div className="d-flex flex-column align-items-center justify-content-center h-100">
                        <img
                          src={'/img/type/rent.png'}
                          alt=""
                          width={"35%"}
                          // height={"100%"}
                          className="text-center "
                        />
                        <h6 className="text-black">Rent</h6>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mb-5">
                  <div className="text-center">
                    <Logout classes="btn btn-outline-primary fw-bolder" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </section>
      <Footer />
    </>
  );
};

export default RealstateType;
