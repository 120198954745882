/* eslint-disable no-mixed-operators */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-const-assign */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import Header from "../../../../Layout/Header";
import ProfileInfo from "../../Profile/ProfileInfo";
import Swal from "sweetalert2";
import axios from "axios";
import Baseurl from "../../../../../Baseurl";
import { Link, useHistory, useLocation } from "react-router-dom";
import EmployeeNewForm from "./Income/EmployeeNewForm";
import OptionalIncomeForm from "./Income/OptionalIncomeForm";
import SidebarDashNew from "./SidebarDashNew";
import moment from "moment";

function TanIncome() {
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const [allPostData, setAllPostData] = useState();
  // const [bund, setBund] = useState();

  const application_id = localStorage.getItem("assignId");
  const reviewData = new FormData();
  reviewData.append("application_id", application_id);

  const location = useLocation();
  const postData = () => {
    const token = localStorage.getItem("usertoken");

    var config = {
      method: "post",
      url: `${Baseurl.baseurl}real-estate/rent/tenant/progress`,
      data: reviewData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        setAllPostData(response?.data?.data);
        console.log(response?.data?.data, "sidebar response");
        if (response?.data?.status === true) {
          console.log(response?.data?.message, "response?.data?.message");
          setLoader(false);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
        // console.log(allGet, "all data");
        console.log(response, "my response");
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      });
  };
  useEffect(() => {
    postData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Assign_id = localStorage.getItem("assignId");
  const [co_Borrower, setCo_Borrower] = useState(false);
  const BorrowerDataGet = new FormData();
  const [showfirstform, setshowfirstform] = useState(false);
  const [error, setError] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  const [optionIncome, setOptionIncome] = useState(false);
  const [employerInformation, setEmployerInformation] = useState([
    {
      source_of_income: true,
      military: false,
      branch: "",
      status: "",
      pcs_status: "",
      coe: "",
      employer_name: "",
      employer_phone: "",
      supervisor_name: "",
      supervisor_email: "",
      street_address: "",
      multipleAddressData: {
        city: "",
        state: "",
        postal_code: "",
        apt_unit: "",
      },
      job_title: "",
      start_date: null,
      end_date: new Date(),
      startDateDisabled: false,
      endDateDisabled: false,
      total_year: "",
      total_month: "",
      ownership_interest: false,
      family_employed: false,
      dd214: "",
      total_monthly_income: "",
      ownership_share: "",
      month_in_profession: "",
      year_in_profession: "",
    },
  ]);
  const [formData, setFormData] = useState({
    gross_soi: "",
    gross_note: "",
    gross_ret_date: "",
    gross_monthly_amount: "",
    military_soi: "",
    military_note: "",
    military_ret_date: "",
    military_monthly_amount: "",
    other_soi: "",
    other_note: "",
    other_ret_date: "",
    other_monthly_amount: "",
  });
  const EmployeIncomeNumberOne = Number(
    employerInformation[0]?.total_monthly_income?.replace(/\D/g, "")
  );
  const EmployeIncomeNumberTwo = Number(
    formData?.gross_monthly_amount?.replace(/\D/g, "")
  );
  const EmployeIncomeNumberThree = Number(
    formData?.military_monthly_amount?.replace(/\D/g, "")
  );
  const EmployeIncomeNumberFour = Number(
    formData?.other_monthly_amount?.replace(/\D/g, "")
  );
  const totalIncomeOnes = EmployeIncomeNumberOne + EmployeIncomeNumberTwo;
  const totalIncomeTwos = totalIncomeOnes + EmployeIncomeNumberThree;
  const totalIncomeThrees = totalIncomeTwos + EmployeIncomeNumberFour;
  BorrowerDataGet.append("application_id", Assign_id);

  const calculateDuration = (startDate, endDate) => {
    const years = endDate.diff(startDate, "years");
    startDate.add(years, "years");
    const months = endDate.diff(startDate, "months");
    return { total_year: years, total_month: months };
  };

  const updateDatesInArray = (array) => {
    return array?.map((item) => {
      const dateStart = moment(item?.start_date, "MM/YYYY");
      const dateEnd = moment(item?.end_date, "MM/YYYY");

      if (dateStart.isValid() && dateEnd.isValid()) {
        const { total_year, total_month } = calculateDuration(
          dateStart.clone(),
          dateEnd.clone()
        );

        let updatedItem = {
          ...item,
          start_date: dateStart.toDate(),
          end_date: dateEnd.toDate(),
          total_year,
          total_month,
        };

        // Update cloning array if it exists
        if (Array.isArray(item?.cloning)) {
          updatedItem.cloning = updateDatesInArray(item.cloning);
        }

        return updatedItem;
      } else {
        console.error("Invalid date for item:", item);
        return item;
      }
    });
  };

  const Get_Borrower = () => {
    setLoader(true);
    let token = localStorage.getItem("usertoken");
    var config = {
      method: "post",
      url: `${Baseurl.baseurl}real-estate/rent/tenant/get/all/incomes`,
      data: BorrowerDataGet,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then(function (response) {
        const res = response?.data?.data;
        if (res?.length > 0) {
          const updatedItsFormArray = updateDatesInArray(res);
          setEmployerInformation(updatedItsFormArray);
          setOptionIncome(res[0]?.optional_income == 1 ? true : false);
          setFormData(res[0]);
        }
        if (response?.data?.status === true) {
          setLoader(false);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      });
  };
  useEffect(() => {
    Get_Borrower();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Submit_Borrower = (e) => {
    e.preventDefault();
    setLoader(true);
    const SubmitData = new FormData();
    SubmitData.append("application_id", Assign_id);
    SubmitData.append(
      "source_of_income",
      employerInformation[0]?.source_of_income
    );
    SubmitData.append("military", employerInformation[0]?.military ? 1 : 0);
    SubmitData.append("branch", employerInformation[0]?.branch);
    SubmitData.append("status", employerInformation[0]?.status);
    SubmitData.append("pcs_status", employerInformation[0]?.pcs_status);
    SubmitData.append("coe", employerInformation[0]?.coe ? 1 : 0);
    SubmitData.append("dd214", employerInformation[0]?.dd214 ? 1 : 0);
    SubmitData.append("employer_name", employerInformation[0]?.employer_name);
    SubmitData.append("employer_phone", employerInformation[0]?.employer_phone);
    SubmitData.append(
      "supervisor_name",
      employerInformation[0]?.supervisor_name
    );
    SubmitData.append(
      "supervisor_email",
      employerInformation[0]?.supervisor_email
    );
    SubmitData.append("street_address", employerInformation[0]?.street_address);
    SubmitData.append(
      "apt_unit",
      employerInformation[0]?.multipleAddressData?.apt_unit
    );
    SubmitData.append(
      "city",
      employerInformation[0]?.multipleAddressData?.city
    );
    SubmitData.append(
      "state",
      employerInformation[0]?.multipleAddressData?.state
    );
    SubmitData.append(
      "zip_code",
      employerInformation[0]?.multipleAddressData?.postal_code
    );
    SubmitData.append("job_title", employerInformation[0]?.job_title);
    SubmitData.append(
      "start_date",
      employerInformation[0]?.start_date?.toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
      })
    );
    SubmitData.append(
      "end_date",
      employerInformation[0]?.end_date?.toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
      })
    );
    SubmitData.append(
      "total_monthly_income",
      employerInformation[0]?.total_monthly_income
    );
    SubmitData.append(
      "year_in_profession",
      employerInformation[0]?.year_in_profession
    );
    SubmitData.append(
      "month_in_profession",
      employerInformation[0]?.month_in_profession
    );
    SubmitData.append(
      "ownership_interest",
      employerInformation[0]?.ownership_interest ? 1 : 0
    );
    SubmitData.append(
      "ownership_share",
      employerInformation[0]?.ownership_share
        ? employerInformation[0]?.ownership_share
        : "MONTHLY"
    );
    SubmitData.append(
      "family_employed",
      employerInformation[0]?.family_employed ? 1 : 0
    );
    SubmitData.append("optional_income", optionIncome ? 1 : 0);
    if (optionIncome) {
      SubmitData.append("gross_soi", formData?.gross_soi);
      SubmitData.append("gross_ret_date", formData?.gross_ret_date);
      SubmitData.append("gross_monthly_amount", formData?.gross_monthly_amount);
      SubmitData.append("income_two", totalIncomeOnes);
      SubmitData.append("gross_note", formData?.gross_note);
      SubmitData.append("military_soi", formData?.military_soi);
      SubmitData.append("military_ret_date", formData?.military_ret_date);
      SubmitData.append(
        "military_monthly_amount",
        formData?.military_monthly_amount
      );
      SubmitData.append("income_three", totalIncomeTwos);
      SubmitData.append("military_note", formData?.military_note);
      SubmitData.append("other_soi", formData?.other_soi);
      SubmitData.append("other_ret_date", formData?.other_ret_date);
      SubmitData.append("other_monthly_amount", formData?.other_monthly_amount);
      SubmitData.append("income_four", totalIncomeThrees);
      SubmitData.append("other_note", formData?.other_note);
    }

    if (employerInformation?.length >= 1) {
      for (var index = 0; index < employerInformation?.length - 1; index++) {
        SubmitData.append(
          `submissions[${index}][start_date]`,
          employerInformation[index + 1]?.start_date?.toLocaleDateString(
            "en-US",
            {
              year: "numeric",
              month: "2-digit",
            }
          )
        );
        SubmitData.append(
          `submissions[${index}][end_date]`,
          employerInformation[index + 1]?.end_date?.toLocaleDateString(
            "en-US",
            {
              year: "numeric",
              month: "2-digit",
            }
          )
        );
        SubmitData.append(
          `submissions[${index}][employer_name]`,
          employerInformation[index + 1]?.employer_name
        );
        SubmitData.append(
          `submissions[${index}][employer_phone]`,
          employerInformation[index + 1]?.employer_phone
        );
        SubmitData.append(
          `submissions[${index}][supervisor_name]`,
          employerInformation[index + 1]?.supervisor_name
        );
        SubmitData.append(
          `submissions[${index}][supervisor_email]`,
          employerInformation[index + 1]?.supervisor_email
        );
        SubmitData.append(
          `submissions[${index}][street_address]`,
          employerInformation[index + 1]?.street_address
        );
        SubmitData.append(
          `submissions[${index}][apt_unit]`,
          employerInformation[index + 1]?.multipleAddressData?.apt_unit
        );
        SubmitData.append(
          `submissions[${index}][city]`,
          employerInformation[index + 1]?.multipleAddressData?.city
        );
        SubmitData.append(
          `submissions[${index}][state]`,
          employerInformation[index + 1]?.multipleAddressData?.state
        );
        SubmitData.append(
          `submissions[${index}][zip_code]`,
          employerInformation[index + 1]?.multipleAddressData?.postal_code
        );
        SubmitData.append(
          `submissions[${index}][job_title]`,
          employerInformation[index + 1]?.job_title
        );
        SubmitData.append(
          `submissions[${index}][total_monthly_income]`,
          employerInformation[index + 1]?.total_monthly_income
        );
        SubmitData.append(
          `submissions[${index}][year_in_profession]`,
          employerInformation[index + 1]?.year_in_profession
        );
        SubmitData.append(
          `submissions[${index}][month_in_profession]`,
          employerInformation[index + 1]?.month_in_profession
        );
        SubmitData.append(
          `submissions[${index}][ownership_interest]`,
          employerInformation[index + 1]?.ownership_interest ? 1 : 0
        );
        SubmitData.append(
          `submissions[${index}][ownership_share]`,
          employerInformation[index + 1]?.ownership_share
            ? employerInformation[index + 1]?.ownership_share
            : "MONTHLY"
        );
        SubmitData.append(
          `submissions[${index}][family_employed]`,
          employerInformation[0]?.family_employed ? 1 : 0
        );
      }
    }

    let token = localStorage.getItem("usertoken");
    var config = {
      method: "post",
      url: `${Baseurl.baseurl}real-estate/rent/tenant/store/income`,
      data: SubmitData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response);
        setCo_Borrower(response?.data?.message);
        if (response?.data?.status === true) {
          setLoader(false);
          Get_Borrower();
          history.push("/rent/tenant/background");
          Swal.fire({
            toast: true,
            icon: "success",
            title: response?.data?.message,
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setError(error?.response?.data?.errors);
        Swal.fire({
          toast: true,
          icon: "error",
          title: error?.response?.data?.errors?.error,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };

  return (
    <>
      {loader ? <div className="loader"></div> : null}

      <Header />
      <form onSubmit={Submit_Borrower}>
        <div className="container-fluid">
          <div className="row">
            <>
              <SidebarDashNew />
            </>
            <>
              <div
                className={
                  isOpen === true
                    ? "col-md-8 open he my-5 ps-lg-4"
                    : isOpen === false
                    ? "col-md-10 open nhi he my-5 ps-lg-4"
                    : ""
                }
              >
                <div className="container">
                  <div className="row">
                    {showfirstform === true ? null : (
                      <>
                        <EmployeeNewForm
                          optionIncome={optionIncome}
                          setOptionIncome={setOptionIncome}
                          setEmployerInformation={setEmployerInformation}
                          employerInformation={employerInformation}
                          formData={formData}
                          setFormData={setFormData}
                        />
                        {optionIncome ? (
                          <OptionalIncomeForm
                            setFormData={setFormData}
                            formData={formData}
                            total_monthly_income={
                              employerInformation[0]?.total_monthly_income
                            }
                            optionIncome={optionIncome}
                          />
                        ) : null}
                        <div className="col-md-6 my-4">
                          <p className="fw-semibold total-fnt1">
                            Total of all income
                          </p>
                          <div class="input-group">
                            <span class="input-group-label contact-info-label ">
                              $ Total Monthly Income
                            </span>
                            <input
                              type="text"
                              name="fname"
                              placeholder="Total Monthly Income"
                              class="form-control rounded-0 text-capitalize input26clr"
                              disabled={true}
                              value={`${
                                totalIncomeThrees
                                  ? "$" + totalIncomeThrees?.toLocaleString()
                                  : ""
                              }`}
                            />{" "}
                          </div>
                        </div>
                        <label className="text-scolor fontsbtn">
                          Next is{" "}
                          <span className="fontsbtncobor">Background</span>
                        </label>{" "}
                        <br />
                        <button
                          className="ms-2 btn btn-primary rounded-0 mt-2 col-md-3 fw-bolder continue-fnt12"
                          type="submit"
                        >
                          Save And Continue &nbsp;
                          <AiOutlineArrowRight />
                        </button>{" "}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>

            <ProfileInfo />
            <div className="footerimage3">
              <img
                src="https://bankerbroker.developer-oa.xyz/assets/img/footercity.svg"
                width="100%"
                alt=""
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default TanIncome;
