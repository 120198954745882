/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from "react";
import { useLoadScript, StandaloneSearchBox } from "@react-google-maps/api";

const libraries = ["places"];

const GoogleSearch = ({ setaddressMailing, addressMailing , mailing }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDXJS_VZMhnp0szh92aZGg8RHszz6RMQN8",
    libraries,
  });

  const searchBoxRef = useRef(null);

  const onPlacesChanged = () => {
    const places = searchBoxRef.current.getPlaces();
    if (places.length === 0) {
      return;
    }
    const place = places[0];
    const userInputAddress = place.formatted_address;
    setaddressMailing(userInputAddress);
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading maps...</div>;

  return (
    <>
      <StandaloneSearchBox
        onLoad={(ref) => (searchBoxRef.current = ref)}
        onPlacesChanged={onPlacesChanged}
      >
        <>
          <div className="form-group-new">
            <input
              className="form-control-new rounded-0 text-capitalize"
              type="text"
             placeholder=" "
              value={addressMailing}
              required={mailing ? true : false}
              onChange={(e) => setaddressMailing(e.target.value)}
            />
            <label htmlFor="inputheight1" className="label-new">
              Enter Mailing Address
            </label>
            {addressMailing?.length > 10 ? (
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  position: "absolute",
                  color: "green",
                  right: "30px",
                  bottom: "-8px",
                  zIndex: "123",
                }}
              >
                ✔
              </p>
            ) : null}
          </div>
        </>
      </StandaloneSearchBox>
    </>
  );
};

export default GoogleSearch;
