/* eslint-disable eqeqeq */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import Header from "../../../../Layout/Header";
import TanSideBar from "./TanSideBar";
import { AiOutlineArrowRight } from "react-icons/ai";
import ProfileInfo from "../../Profile/ProfileInfo";
import Baseurl from "../../../../../Baseurl";
import axios from "axios";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { Modal, NavLink } from "react-bootstrap";
import ReviewSubmitGoogleAddress from "./ReviewSubmitGoogleAddress";
import { Fab } from "@mui/material";
import { LuClipboardSignature } from "react-icons/lu";
import SignaturePad from "react-signature-canvas";
import SidebarDashNew from "./SidebarDashNew";

function TanReview() {
  const history = useHistory();
  const [about_us, set_about_us] = useState("");
  const [disclosures_consent, set_disclosures_consent] = useState(0);
  const [authorization, set_authorization] = useState(0);
  const [loader, setLoader] = useState(false);
  // New Input Code Start
  const [describe, setDescribe] = useState("");

  const [addressCurrent, setAddressCurrent] = useState("");
  const [googleData, setGoogleData] = useState({
    street_number: "",
    street_name: "",
    city: "",
    state: "",
    postal_code: "",
    apt_unit: "",
  });
  const [MonthlyRental, setMonthlyRental] = useState("");
  const [Deposit, setDeposit] = useState("");
  const [ApplicationFee, setApplicationFee] = useState("");
  const [signatureDate, setSignatureDate] = useState(new Date());
  const [modalShow, setModalShow] = useState(false);
  const [thankYoumodalShow, setThankYouModalShow] = useState(false);

  useEffect(() => {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split("T")[0];
    setSignatureDate(formattedDate);
  }, []);

  const formatNumber = (value) => {
    if (!value) return value;
    const numberValue = Number(value.replace(/[^0-9]/g, ""));
    return new Intl.NumberFormat().format(numberValue);
  };

  const handleInputChange = (setter) => (event) => {
    const { value } = event.target;
    const formattedValue = formatNumber(value);
    setter(formattedValue);
  };

  const [trimmedDataURL, setTrimmedDataURL] = useState(null);
  const sigPads = useRef(null);
  const clear = () => {
    sigPads.current.clear();
  };

  const save = () => {
    if (sigPads.current) {
      const dataURL = sigPads.current.getTrimmedCanvas().toDataURL("image/png");
      setTrimmedDataURL(dataURL);
      setModalShow(false);
    }
  };
  // New Input Code End

  const application_id = localStorage.getItem("assignId");

  const getData = () => {
    const token = localStorage.getItem("usertoken");
    const reviewData = new FormData();
    reviewData.append("application_id", application_id);
    var config = {
      method: "post",
      url: `${Baseurl.baseurl}real-estate/rent/tenant/review`,
      data: reviewData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        const res = response?.data?.data;
        console.log(res, "my response");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const postData = (e) => {
    e.preventDefault();
    setLoader(true);
    const token = localStorage.getItem("usertoken");
    const reviewData = new FormData();
    reviewData.append("application_id", application_id);
    reviewData.append("about_us", about_us);
    reviewData.append("disclosures_consent", disclosures_consent);
    reviewData.append("authorization", authorization);
    reviewData.append("address", addressCurrent);
    reviewData.append("street_no", googleData?.street_number);
    reviewData.append("street_name", googleData?.street_name);
    reviewData.append("state", googleData?.state);
    reviewData.append("city", googleData?.city);
    reviewData.append("zip_code", googleData?.postal_code);
    reviewData.append("unit", googleData?.apt_unit);

    reviewData.append("monthly_rent", MonthlyRental);
    reviewData.append("deposit", Deposit);
    reviewData.append("application_fee", ApplicationFee);

    reviewData.append("description", describe);
    reviewData.append("date", signatureDate);
    reviewData.append("signature", trimmedDataURL);

    var config = {
      method: "post",
      url: `${Baseurl.baseurl}real-estate/rent/tenant/submit/application`,
      data: reviewData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        if (response?.data?.status === true) {
          history.push("/rent/tanent/document");
          Swal.fire({
            toast: true,
            icon: "success",
            title: response?.data?.message,
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        Swal.fire({
          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };

  const handleChangeCheckBox = (e, func) => {
    const { value, checked } = e.target;
    {
      checked ? func(1) : func(0);
    }
  };

  return (
    <>
      {loader ? <div className="loader"></div> : null}

      <Header />
      <form onSubmit={postData}>
        <div className="container-fluid">
          <div className="row">
            <SidebarDashNew />

            <div className="col-md-8 my-5 ps-lg-5 ">
              <div className="row">
                <div className="form-group mb-gutter mb-0">
                  <label
                    className="form-label font-bold"
                    style={{ textTransform: "none" }}
                  >
                    Quickly review all the details and you’re ready to submit
                  </label>
                </div>
                <h4
                  className="form-label font-bold mb-0"
                  style={{ textTransform: "none" }}
                >
                  You are applying for this desired rental
                </h4>
                <div className="col-md-12 my-2 position-relative  ">
                  <ReviewSubmitGoogleAddress
                    setAddressCurrent={setAddressCurrent}
                    addressCurrent={addressCurrent}
                    googleData={googleData}
                    setGoogleData={setGoogleData}
                  />
                </div>
                <div className="col-md-6 my-2 position-relative ">
                  <div className="form-group-new">
                    <input
                      className="form-control-new rounded-0 text-capitalize"
                      type="text"
                      placeholder=" "
                      required
                      value={
                        googleData?.street_number
                          ? googleData?.street_number
                          : "Street Number"
                      }
                    />
                    <label htmlFor="inputheight1" className="label-new">
                      Street Number
                    </label>
                  </div>
                  {googleData?.street_number?.length >= 2 ? (
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        position: "absolute",
                        color: "green",
                        right: "24px",
                        bottom: "10px",
                        zIndex: "123",
                      }}
                    >
                      ✔
                    </p>
                  ) : null}
                </div>
                <div className="col-md-6 my-2 position-relative">
                  <div className="form-group-new">
                    <input
                      className="form-control-new rounded-0 text-capitalize"
                      type="text"
                      placeholder=" "
                      value={
                        googleData?.street_name
                          ? googleData?.street_name
                          : "Street"
                      }
                      required
                    />
                    <label htmlFor="inputheight1" className="label-new">
                      Street Name
                    </label>
                  </div>
                  {googleData?.street_name?.length >= 2 ? (
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        position: "absolute",
                        color: "green",
                        right: "24px",
                        bottom: "10px",
                        zIndex: "123",
                      }}
                    >
                      ✔
                    </p>
                  ) : null}
                </div>
                <div className="col-md-6 my-2 position-relative ">
                  <div className="form-group-new">
                    <input
                      className="form-control-new rounded-0 text-capitalize"
                      type="text"
                      placeholder=" "
                      value={googleData?.state ? googleData?.state : "State"}
                      required
                    />
                    <label htmlFor="inputheight1" className="label-new">
                      State
                    </label>
                  </div>
                  {googleData?.state?.length >= 2 ? (
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        position: "absolute",
                        color: "green",
                        right: "24px",
                        bottom: "10px",
                        zIndex: "123",
                      }}
                    >
                      ✔
                    </p>
                  ) : null}
                </div>
                <div className="col-md-6 my-2 position-relative">
                  <div className="form-group-new">
                    <input
                      className="form-control-new rounded-0 text-capitalize"
                      type="text"
                      placeholder=" "
                      value={googleData?.city ? googleData?.city : "City"}
                      required
                    />
                    <label htmlFor="inputheight1" className="label-new">
                      City
                    </label>
                  </div>
                  {googleData?.city?.length >= 2 ? (
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        position: "absolute",
                        color: "green",
                        right: "24px",
                        bottom: "10px",
                        zIndex: "123",
                      }}
                    >
                      ✔
                    </p>
                  ) : null}
                </div>
                <div className="col-md-6 my-2 position-relative ">
                  <div className="form-group-new">
                    <input
                      className="form-control-new rounded-0 text-capitalize"
                      type="text"
                      placeholder=" "
                      value={
                        googleData?.apt_unit ? googleData?.apt_unit : "Apt/unit"
                      }
                    />
                    <label htmlFor="inputheight1" className="label-new">
                      Apt/unit
                    </label>
                  </div>
                  {googleData?.apt_unit?.length >= 2 ? (
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        position: "absolute",
                        color: "green",
                        right: "24px",
                        bottom: "10px",
                        zIndex: "123",
                      }}
                    >
                      ✔
                    </p>
                  ) : null}
                </div>
                <div className="col-md-6 my-2 position-relative">
                  <div className="form-group-new">
                    <input
                      className="form-control-new rounded-0 text-capitalize"
                      type="text"
                      placeholder=" "
                      value={
                        googleData?.postal_code
                          ? googleData?.postal_code
                          : "Zip Code"
                      }
                      required
                    />
                    <label htmlFor="inputheight1" className="label-new">
                      Zip Code
                    </label>
                  </div>
                  {googleData?.postal_code?.length >= 2 ? (
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        position: "absolute",
                        color: "green",
                        right: "24px",
                        bottom: "10px",
                        zIndex: "123",
                      }}
                    >
                      ✔
                    </p>
                  ) : null}
                </div>

                <div className="col-md-6 my-2 position-relative ">
                  <div className="form-group-new">
                    <input
                      className="form-control-new rounded-0 text-capitalize"
                      type="text"
                      placeholder=" "
                      value={MonthlyRental}
                      required
                      onChange={handleInputChange(setMonthlyRental)}
                    />
                    <label htmlFor="inputheight1" className="label-new">
                      Monthly Rental
                    </label>
                  </div>
                  {MonthlyRental.length > 4 ? (
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        position: "absolute",
                        color: "green",
                        right: "24px",
                        bottom: "10px",
                        zIndex: "123",
                      }}
                    >
                      ✔
                    </p>
                  ) : null}
                </div>
                <div className="col-md-6 my-2 position-relative px-3">
                  <div className="form-group-new">
                    <input
                      className="form-control-new rounded-0 text-capitalize"
                      type="text"
                      placeholder=" "
                      value={Deposit}
                      required
                      onChange={handleInputChange(setDeposit)}
                    />
                    <label htmlFor="inputheight2" className="label-new">
                      Deposit
                    </label>
                  </div>
                  {Deposit.length > 4 ? (
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        position: "absolute",
                        color: "green",
                        right: "24px",
                        bottom: "10px",
                        zIndex: "123",
                      }}
                    >
                      ✔
                    </p>
                  ) : null}
                </div>
                <div className="col-md-6 my-2 position-relative ">
                  <div className="form-group-new">
                    <input
                      className="form-control-new rounded-0 text-capitalize"
                      type="text"
                      placeholder=" "
                      value={ApplicationFee}
                      required
                      onChange={handleInputChange(setApplicationFee)}
                    />
                    <label htmlFor="inputheight3" className="label-new">
                      Application Fee
                    </label>
                  </div>
                  {ApplicationFee.length > 4 ? (
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        position: "absolute",
                        color: "green",
                        right: "24px",
                        bottom: "10px",
                        zIndex: "123",
                      }}
                    >
                      ✔
                    </p>
                  ) : null}
                </div>

                <p>How did you hear about us?</p>
                <div className="col-md-6 position-relative ">
                  <div className="select-container mt-2">
                    <select
                      name="estimated_credit_score"
                      required
                      formcontrolname="creditRange"
                      className={`form-control-select w-100 ${
                        about_us ? "text-success" : "text-dark"
                      }`}
                      value={about_us}
                      onChange={(e) => set_about_us(e.target.value)}
                      onFocus={() =>
                        document
                          ?.querySelector(".label-select")
                          ?.classList?.add("label-top")
                      }
                      onBlur={() =>
                        !about_us &&
                        document
                          ?.querySelector(".label-select")
                          ?.classList?.remove("label-top")
                      }
                    >
                      <option value="" disabled></option>
                      <option value={"Select Source"}>
                        Select Source
                        {about_us === "Select Source" ? (
                          <span className="tick-mark2"> ✔</span>
                        ) : null}
                      </option>

                      <option value="Televison">
                        Televison
                        {about_us === "Televison" ? (
                          <span className="tick-mark2"> ✔</span>
                        ) : null}
                      </option>
                      <option value="Radio">
                        Radio
                        {about_us === "Radio" ? (
                          <span className="tick-mark2"> ✔</span>
                        ) : null}
                      </option>
                      <option value="Newspaper">
                        Newspaper
                        {about_us === "Newspaper" ? (
                          <span className="tick-mark2"> ✔</span>
                        ) : null}
                      </option>
                      <option value="SocialMedia">
                        Social Media
                        {about_us === "SocialMedia" ? (
                          <span className="tick-mark2"> ✔</span>
                        ) : null}
                      </option>
                      <option value="SearchEngine">
                        Search Engine
                        {about_us === "SearchEngine" ? (
                          <span className="tick-mark2"> ✔</span>
                        ) : null}
                      </option>
                      <option value="FriendFamily">
                        Referral - Friend/Family
                        {about_us === "FriendFamily" ? (
                          <span className="tick-mark2"> ✔</span>
                        ) : null}
                      </option>
                      <option value="RealEstateAgent">
                        Referral - Business Contact
                        {about_us === "RealEstateAgent" ? (
                          <span className="tick-mark2"> ✔</span>
                        ) : null}
                      </option>
                      <option value="FinancialAdvisor">
                        Referral - Financial Advisor
                        {about_us === "FinancialAdvisor" ? (
                          <span className="tick-mark2"> ✔</span>
                        ) : null}
                      </option>
                      <option value="ReturnClient">
                        Return Client
                        {about_us === "ReturnClient" ? (
                          <span className="tick-mark2"> ✔</span>
                        ) : null}
                      </option>
                      <option value="1Other">
                        Other
                        {about_us === "1Other" ? (
                          <span className="tick-mark2"> ✔</span>
                        ) : null}
                      </option>
                    </select>
                    <label
                      className={`label-new ${about_us ? "label-top" : ""}`}
                      htmlFor="estimated_credit_score"
                    >
                      Select
                    </label>
                  </div>
                </div>
                <div className="col-md-6 my-2 position-relative px-3">
                  <div className="form-group-new">
                    <input
                      className="form-control-new rounded-0 text-capitalize"
                      type="text"
                      placeholder=" "
                      value={describe}
                      required
                      onChange={(e) => setDescribe(e.target.value)}
                    />
                    <label htmlFor="inputheight1" className="label-new">
                      Describe
                    </label>
                  </div>
                  {describe?.length > 4 ? (
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        position: "absolute",
                        color: "green",
                        right: "24px",
                        bottom: "10px",
                        zIndex: "123",
                      }}
                    >
                      ✔
                    </p>
                  ) : null}
                </div>

                <div className="d-flex ">
                  <div className="mt-4">
                    <div class="alternate-name-wrapper mt-gutter">
                      <label
                        for="eConsent"
                        className="custom-checkbox checkbox-top-aligned d-flex align-items-baseline"
                      >
                        <input
                          type="checkbox"
                          id="eConsent"
                          className=" ng-untouched ng-pristine ng-valid"
                          required
                          onChange={(e) =>
                            handleChangeCheckBox(e, set_disclosures_consent)
                          }
                        />{" "}
                        &nbsp;
                        <span class="label font-sm ">
                          <span>
                            {/* I, Hnh Tech, agree to and acknowledge receipt of
                            &nbsp; */}
                            <NavLink to={""} className="text-primary">
                              Disclosures and Consent to Do Business
                              Electronically
                            </NavLink>
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="mt-4 ps-5">
                    <div class="alternate-name-wrapper mt-gutter ">
                      <label
                        for="consent"
                        className="custom-checkbox checkbox-top-aligned d-flex align-items-baseline"
                      >
                        <input
                          type="checkbox"
                          id="consent"
                          className=" ng-untouched ng-pristine ng-valid"
                          onChange={(e) =>
                            handleChangeCheckBox(e, set_authorization)
                          }
                          required
                        />{" "}
                        &nbsp;
                        <span class="label font-sm ">
                          <span>
                            {/* Authorization to Order Credit and Verify Financial
                            History &nbsp; */}
                            <NavLink to={""} className="text-primary">
                              Authorization to Order Credit and Verify Financial
                              History
                            </NavLink>
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 mt-4">
                  <Fab
                    variant="extended"
                    style={{ float: "right" }}
                    onClick={() => setModalShow(true)}
                  >
                    <LuClipboardSignature
                      sx={{ mr: 1 }}
                      style={{ fontSize: "30px" }}
                    />
                    Signature
                  </Fab>
                </div>
                <div className="col-md-10 mt-4">
                  <p className="text-primary">
                    By signing this document, you complete the application,
                    authorize us to check your credit, employment & financial
                    history, and consent to do business electronically. Your
                    application including all submitted documents & info will be
                    available as a single PDF on your document page.
                  </p>
                </div>
                <div className="col-md-4">
                  <button
                    className="btn btn-primary rounded font-bold w-60"
                    id="btn-save-con1"
                    disabled={trimmedDataURL ? false : true}
                  >
                    Submit &nbsp;
                    <AiOutlineArrowRight />
                  </button>
                </div>
              </div>
            </div>
            <ProfileInfo />

            <div className="footerimage3">
              <img
                src="https://bankerbroker.developer-oa.xyz/assets/img/footercity.svg"
                width="100%"
                alt=""
              />
            </div>
          </div>
        </div>
      </form>

      {/* Signature modal  */}
      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          closeButton
          onClick={() => {
            clear();
            setModalShow(false);
          }}
        >
          <Modal.Title id="contained-modal-title-vcenter">
            Borrower Signature
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5
            className="font266"
            style={{ fontSize: "20px", fontWeight: "500", color: "black" }}
          >
            Borrower Signature{" "}
          </h5>
          <div className="col-lg-12 mb-3">
            <input
              className="form-control text-capitalize rounded-0 shadow-sm"
              type="date"
              name="borrower_signature_date"
              onChange={(e) => setSignatureDate(e.target.value)}
              value={signatureDate}
              placeholder="Signature Date"
            />
          </div>
          <div className="col-lg-12">
            <div className="shadow-sm" id="sigContainer">
              <SignaturePad
                canvasProps={{
                  width: 600,
                  height: 200,
                  className: "sigCanvas",
                }}
                ref={sigPads}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="col-lg-12 mb-3">
            <div className="d-flex justify-content-between">
              <div>
                <button
                  type="button"
                  className="btn btn-primary rounded-0"
                  onClick={clear}
                >
                  Clear
                </button>
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-primary rounded-0"
                  onClick={save}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      {/* Signature modal  */}

      {/* Thank you Modal Start */}
      <Modal
        show={thankYoumodalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          closeButton
          onClick={() => {
            setThankYouModalShow(false);
          }}
        >
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-black"
          >
            Thank You !
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center">
            <div className="col-md-12">
              <div className="card border border-3 border-success bg-white shadow p-5">
                <div className="mb-4 text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="text-success"
                    width={75}
                    height={75}
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                  </svg>
                </div>
                <div className="text-center">
                  <h1 className="text-black">Thank You !</h1>
                  <p className="text-black">
                    We've send the link to your inbox. Lorem ipsum dolor
                    sit,lorem lorem{" "}
                  </p>
                  <button type="button" className="btn btn-success rounded-0">
                    Back To Home
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Thank you Modal End */}
    </>
  );
}

export default TanReview;
