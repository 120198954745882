/* eslint-disable no-mixed-operators */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-const-assign */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import Header from "../../../../Layout/Header";
import Swal from "sweetalert2";
import axios from "axios";
import Baseurl from "../../../../../Baseurl";
import ProfileInfo from "../../Profile/ProfileInfo";
import { useHistory, useLocation } from "react-router-dom";
import SidebarDashNew from "./SidebarDashNew";

function TanCoSigner() {
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const [allPostData, setAllPostData] = useState();
  const [bund, setBund] = useState("");

  const application_id = localStorage.getItem("assignId");
  const reviewData = new FormData();
  reviewData.append("application_id", application_id);

  const location = useLocation();
  const postData = () => {
    const token = localStorage.getItem("usertoken");

    var config = {
      method: "post",
      url: `${Baseurl.baseurl}real-estate/rent/tenant/progress`,
      data: reviewData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        setAllPostData(response?.data?.data);
        console.log(response?.data?.data, "sidebarresponse");
        if (response?.data?.status === true) {
          console.log(response?.data?.message, "response?.data?.message");
          setLoader(false);

          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
        console.log(response, "my response");
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setBund(error?.response?.data?.errors);
        Swal.fire({
          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };
  useEffect(() => {
    postData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isOpen, setIsOpen] = useState(true);

  const Assign_id = localStorage.getItem("assignId");
  const [showfirstform, setshowfirstform] = useState(false);

  const [ConSinger, setCoSinger] = useState([
    {
      first_name: "",
      last_name: "",
      email: "",
      co_phone: "",
      relationship: "",
      occupy_property: 0,
    },
  ]);

  const [ConSingerOccupants, setCoSingerOccupants] = useState([
    {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      sn: "",
      dob: "",
      age: "",
      cosign: "",
      relationship: "",
    },
  ]);

  const [viewCoSinger, setViewConSinger] = useState(
    "I am applying individually"
  );

  // Getting All Co-Borrowers
  const BorrowerDataGet = new FormData();
  BorrowerDataGet.append("application_id", Assign_id);
  const Get_Borrower = () => {
    setLoader(true);
    let token = localStorage.getItem("usertoken");
    var config = {
      method: "post",
      url: `${Baseurl.baseurl}real-estate/rent/tenant/get/all/signers`,
      data: BorrowerDataGet,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        const typesCoSinger = response?.data?.data?.filter((type) => {
          if (type.type === "cosigner") {
            setViewConSinger("I am applying jointy");
          }
          return type.type === "cosigner";
        });
        const typesOccupants = response?.data?.data?.filter((type) => {
          return type.type === "occupants";
        });
        if (typesCoSinger?.length === 0) {
          setCoSinger([
            {
              first_name: "",
              last_name: "",
              email: "",
              co_phone: "",
              relationship: "",
              occupy_property: 0,
            },
          ]);
        } else {
          setCoSinger(typesCoSinger);
        }
        if (typesOccupants?.length === 0) {
          setCoSingerOccupants([
            {
              first_name: "",
              last_name: "",
              email: "",
              phone: "",
              sn: "",
              dob: "",
              age: "",
              cosign: "",
              relationship: "",
            },
          ]);
        } else {
          setCoSingerOccupants(typesOccupants);
          setshowfirstform(true);
        }

        if (response?.data?.status === true) {
          setLoader(false);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setBund(error?.response?.data?.errors);
      });
  };
  useEffect(() => {
    Get_Borrower();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [applyingsolo, setApplyingsolo] = useState(0);
  const Check_ApplyingSolo = (e) => {
    const value = e.target.value;
    setViewConSinger(value);
    setshowfirstform(false);
    if (value === "I am applying individually") {
      setApplyingsolo(1);
    } else {
      setApplyingsolo(0);
    }
  };

  const Submit_Borrower = (e) => {
    e.preventDefault();
    setLoader(true);
    const SubmitData = new FormData();
    SubmitData.append("application_id", Assign_id);
    ConSingerOccupants?.map((occupant, index) => {
      SubmitData.append(
        `occupants[${index}][first_name]`,
        occupant?.first_name
      );
      SubmitData.append(`occupants[${index}][last_name]`, occupant?.last_name);
      SubmitData.append(`occupants[${index}][sn]`, occupant?.sn);
      SubmitData.append(`occupants[${index}][dob]`, occupant?.dob);
      SubmitData.append(`occupants[${index}][age]`, occupant?.age);
      SubmitData.append(`occupants[${index}][cosign]`, occupant?.cosign);
      SubmitData.append(`occupants[${index}][email]`, occupant?.email);
      SubmitData.append(`occupants[${index}][phone]`, occupant?.phone);
      SubmitData.append(
        `occupants[${index}][relationship]`,
        occupant?.relationship
      );
    });
    SubmitData.append("credit_score", viewCoSinger);
    // if (viewCoSinger === "I am applying jointy") {
    //   ConSinger?.map((cosigner, index) => {
    //     SubmitData.append(
    //       `cosigner[${index}][first_name]`,
    //       cosigner?.first_name
    //     );
    //     SubmitData.append(`cosigner[${index}][last_name]`, cosigner?.last_name);
    //     SubmitData.append(`cosigner[${index}][email]`, cosigner?.email);
    //     SubmitData.append(`cosigner[${index}][co_phone]`, cosigner?.co_phone);
    //     SubmitData.append(
    //       `cosigner[${index}][occupy_property]`,
    //       cosigner?.occupy_property ? 1 : 0
    //     );
    //     SubmitData.append(
    //       `cosigner[${index}][relationship]`,
    //       cosigner?.relationship
    //     );
    //   });
    // }
    let token = localStorage.getItem("usertoken");
    var config = {
      method: "post",
      url: `${Baseurl.baseurl}real-estate/rent/tenant/invite/co-signers/info`,
      data: SubmitData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response);
        if (response?.data?.status === true) {
          setLoader(false);
          history.push("/rent/tanent/Income");
          Swal.fire({
            toast: true,
            icon: "success",
            title: response?.data?.message,
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setBund(error?.response?.data?.errors);
        Swal.fire({
          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };

  // Multiple Input Code Start Edit

  const handlePlusClickBeds = () => {
    setCoSinger([
      ...ConSinger,
      {
        first_name: "",
        last_name: "",
        email: "",
        co_phone: "",
        relationship: "",
        occupy_property: 0,
      },
    ]);
  };

  const handleMinusClickBeds = (index, bedroom) => {
    if (ConSinger?.length > 1) {
      const newBedrooms = [...ConSinger];
      newBedrooms.splice(index, 1);
      setCoSinger(newBedrooms);
    }
  };

  const handleInputChangeBeds = (value, index, field) => {
    const newBedrooms = [...ConSinger];
    if (field === "co_phone") {
      value = formatPhoneNumber(value);
    }
    newBedrooms[index][field] = value;
    setCoSinger(newBedrooms);
  };

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return cleaned;
  };

  // Multiple Input Code End Edit

  // Multiple Two Code Start

  const handlePlusClickOccupants = () => {
    setCoSingerOccupants([
      ...ConSingerOccupants,
      {
        first_name: "",
        last_name: "",
        sn: "",
        dob: "",
        age: "",
        cosign: "",
        email: "",
        phone: "",
        relationship: "",
      },
    ]);
  };

  const handleMinusClickOccupants = (index, bedroom) => {
    if (ConSingerOccupants?.length > 1) {
      const newBedrooms = [...ConSingerOccupants];
      newBedrooms.splice(index, 1);
      setCoSingerOccupants(newBedrooms);
    }
  };

  const formatSecurityNumber = (value) => {
    let inputSecurityNumber = value.replace(/\D/g, "");
    if (inputSecurityNumber?.length > 9) {
      inputSecurityNumber = inputSecurityNumber.slice(0, 9);
    }
    let formattedSecurityNumber = "";
    if (inputSecurityNumber?.length > 5) {
      formattedSecurityNumber = `${inputSecurityNumber.substring(
        0,
        3
      )}-${inputSecurityNumber.substring(3, 5)}-${inputSecurityNumber.substring(
        5
      )}`;
    } else {
      formattedSecurityNumber = inputSecurityNumber;
    }

    return formattedSecurityNumber;
  };

  const handleInputChangeOccupants = (value, index, field) => {
    const newBedrooms = [...ConSingerOccupants];
    if (field === "phone") {
      value = formatPhoneNumber(value);
    } else if (field === "sn") {
      value = formatSecurityNumber(value);
    } else if (field === "dob") {
      const calculatedAge = calculateAgeOne(value);
      newBedrooms[index]["age"] = calculatedAge;
    }
    newBedrooms[index][field] = value;
    setCoSingerOccupants(newBedrooms);
  };

  // Auto Age Code Start
  const calculateAgeOne = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  // Auto Age Code End
  // Multiple Two Code End

  const coSingerInput = viewCoSinger === "I am applying jointy";

  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <Header />
      <div className="container-fluid">
        <div className="row">
          <>
            <SidebarDashNew />
          </>
          <div
            className={
              isOpen === true
                ? "col-md-8 open he mt-5 mb-2 ps-lg-5"
                : isOpen === false
                ? "col-md-10 open nhi he mt-5 mb-2 ps-lg-5"
                : ""
            }
          >
            <form onSubmit={Submit_Borrower}>
              <div className="row">
                <>
                  <h3 className="mb-3 p-md-0 fw-bold" id="co-singers-h">
                    Co-Signers & Occupants
                  </h3>
                  {viewCoSinger === "I am applying jointy" ? (
                    <>
                      {/* <div className="personalinfo_maxwidth">
                      <>
                        <div className="mt-3">
                          <>
                            <h5
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "black",
                              }}
                            >
                              Fill Co-Signers information
                            </h5>
                            <div>
                              {ConSinger?.map((bedroom, index) => (
                                <div className="row" key={index}>
                                  <div className="col-md-6">
                                    <div className="input-group my-3">
                                      <div className="form-group-new">
                                        <input
                                          className="form-control-new rounded-0 text-capitalize"
                                          placeholder=" "
                                          type="text"
                                          value={bedroom?.first_name}
                                          onChange={(e) =>
                                            handleInputChangeBeds(
                                              e.target.value,
                                              index,
                                              "first_name"
                                            )
                                          }
                                          required={coSingerInput ? true : false}
                                        />
                                        <label
                                          htmlFor="inputheight1"
                                          className="label-new"
                                        >
                                          FIRST NAME
                                        </label>
                                      </div>
                                      {bedroom?.first_name?.length > 4 ? (
                                        <p
                                          style={{
                                            fontSize: "16px",
                                            color: "green",
                                            fontWeight: "bold",
                                            position: "absolute",
                                            right: "15px",
                                            top: "10px",
                                            zIndex: "123",
                                          }}
                                        >
                                          ✔
                                        </p>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="input-group my-3">
                                      <div className="form-group-new">
                                        <input
                                          className="form-control-new rounded-0 text-capitalize"
                                          placeholder=" "
                                          type="text"
                                          value={bedroom?.last_name}
                                          onChange={(e) =>
                                            handleInputChangeBeds(
                                              e.target.value,
                                              index,
                                              "last_name"
                                            )
                                          }
                                          required={coSingerInput ? true : false}
                                        />
                                        <label
                                          htmlFor="inputheight1"
                                          className="label-new"
                                        >
                                          LAST NAME
                                        </label>
                                      </div>
                                      {bedroom?.last_name?.length > 4 ? (
                                        <p
                                          style={{
                                            fontSize: "16px",
                                            color: "green",
                                            fontWeight: "bold",
                                            position: "absolute",
                                            right: "15px",
                                            top: "10px",
                                            zIndex: "123",
                                          }}
                                        >
                                          ✔
                                        </p>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="input-group my-3">
                                      <div className="form-group-new">
                                        <input
                                          className="form-control-new rounded-0 text-capitalize"
                                          placeholder=" "
                                          type="email"
                                          value={bedroom?.email}
                                          onChange={(e) =>
                                            handleInputChangeBeds(
                                              e.target.value,
                                              index,
                                              "email"
                                            )
                                          }
                                          required={coSingerInput ? true : false}
                                        />
                                        <label
                                          htmlFor="inputheight1"
                                          className="label-new"
                                        >
                                          EMAIL ID
                                        </label>
                                      </div>
                                      {bedroom?.email?.length > 4 ? (
                                        <p
                                          style={{
                                            fontSize: "16px",
                                            color: "green",
                                            fontWeight: "bold",
                                            position: "absolute",
                                            right: "15px",
                                            top: "10px",
                                            zIndex: "123",
                                          }}
                                        >
                                          ✔
                                        </p>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="input-group my-3">
                                      <div className="form-group-new">
                                        <input
                                          className="form-control-new rounded-0 text-capitalize"
                                          placeholder=" "
                                          type="text"
                                          maxLength="14"
                                          value={bedroom?.co_phone}
                                          onChange={(e) =>
                                            handleInputChangeBeds(
                                              e.target.value,
                                              index,
                                              "co_phone"
                                            )
                                          }
                                          required={coSingerInput ? true : false}
                                        />
                                        <label
                                          htmlFor="inputheight1"
                                          className="label-new"
                                        >
                                          PHONE
                                        </label>
                                      </div>
                                      {bedroom?.co_phone?.length > 4 ? (
                                        <p
                                          style={{
                                            fontSize: "16px",
                                            color: "green",
                                            fontWeight: "bold",
                                            position: "absolute",
                                            right: "15px",
                                            top: "10px",
                                            zIndex: "123",
                                          }}
                                        >
                                          ✔
                                        </p>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="input-group my-3">
                                      <div className="form-group-new">
                                        <input
                                          className="form-control-new rounded-0 text-capitalize"
                                          placeholder=" "
                                          type="text"
                                          value={bedroom?.relationship}
                                          onChange={(e) =>
                                            handleInputChangeBeds(
                                              e.target.value,
                                              index,
                                              "relationship"
                                            )
                                          }
                                          required={coSingerInput ? true : false}
                                        />
                                        <label
                                          htmlFor="inputheight1"
                                          className="label-new"
                                        >
                                          RELATIONSHIP
                                        </label>
                                      </div>
                                      {bedroom?.relationship?.length > 4 ? (
                                        <p
                                          style={{
                                            fontSize: "16px",
                                            color: "green",
                                            fontWeight: "bold",
                                            position: "absolute",
                                            right: "15px",
                                            top: "10px",
                                            zIndex: "123",
                                          }}
                                        >
                                          ✔
                                        </p>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div class="form-check my-3">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        onChange={(e) =>
                                          handleInputChangeBeds(
                                            bedroom.occupy_property === 1
                                              ? 0
                                              : 1,
                                            index,
                                            "occupy_property"
                                          )
                                        }
                                        checked={
                                          bedroom.occupy_property === 1
                                            ? true
                                            : false
                                        }
                                        required={coSingerInput ? true : false}
                                      />
                                      <span
                                        className="label font-sm1"
                                        id="complete-fs1"
                                        for="defaultCheck1"
                                      >
                                        This co-signers will not occupy the
                                        property but is financially responsible
                                        for the lease.
                                      </span>{" "}
                                    </div>
                                  </div>
                                  {ConSinger?.length > 1 && (
                                    <div
                                      className="mt-4 btn btn-danger cursor-pointer"
                                      style={{
                                        display: "flex",
                                        justifyContent: "start",
                                        marginBottom: "20px",
                                        marginLeft: "20px",
                                        width: "100px",
                                      }}
                                      onClick={() =>
                                        handleMinusClickBeds(index, bedroom)
                                      }
                                    >
                                      Remove
                                    </div>
                                  )}
                                </div>
                              ))}
                              <div
                                className="btn btn-primary cursor-pointer"
                                style={{ marginTop: "20px" }}
                                onClick={handlePlusClickBeds}
                              >
                                Add Co-Signers
                              </div>
                            </div>
                          </>
                        </div>
                        <div className="d-flex mt-4">
                          <div
                            className="font-medium ms-0"
                            id="cancel-btn1"
                            onClick={() => {
                              setshowfirstform(false);
                              setViewConSinger("I am applying individually");
                            }}
                          >
                            CANCEL
                          </div>
                        </div>
                      </>
                    </div> */}
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="select-container my-2">
                    <select
                      required
                      onClick={(e) => Check_ApplyingSolo(e)}
                      name="estimated_credit_score"
                      formcontrolast_name="creditRange"
                      className={`form-control-select w-25 ${
                        viewCoSinger ? "text-success" : "text-dark"
                      }`}
                      value={viewCoSinger}
                      onChange={(e) => setViewConSinger(e.target.value)}
                      onFocus={() =>
                        document
                          ?.querySelector(".label-select")
                          ?.classList?.add("label-top")
                      }
                      onBlur={() =>
                        !viewCoSinger &&
                        document
                          ?.querySelector(".label-select")
                          ?.classList?.remove("label-top")
                      }
                    >
                      <option value="" disabled></option>
                      <option
                        value="I am applying individually"
                        className="ng-star-inserted"
                      >
                        I am applying individually{" "}
                        {viewCoSinger === "I am applying individually" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option
                        value="I am applying jointy"
                        className="ng-star-inserted"
                      >
                        I am applying jointy{" "}
                        {viewCoSinger === "I am applying jointy" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                    </select>
                    <label
                      className={`label-new ${viewCoSinger ? "label-top" : ""}`}
                      htmlFor="estimated_credit_score"
                    >
                      Co-Signers
                    </label>
                  </div>
                  {viewCoSinger ? (
                    <>
                      <h5
                        style={{
                          fontSize: "20px",
                          fontWeight: "500",
                          color: "black",
                        }}
                        className="mt-2"
                      >
                        List all proposed occupants & Cosigners
                      </h5>
                      {ConSingerOccupants?.map((occupants, index) => (
                        <div className="row">
                          <div className="col-md-3">
                            <div class="input-group my-3">
                              <div className="form-group-new">
                                <input
                                  className="form-control-new rounded-0 text-capitalize"
                                  placeholder=" "
                                  type="text"
                                  value={occupants.first_name}
                                  onChange={(e) =>
                                    handleInputChangeOccupants(
                                      e.target.value,
                                      index,
                                      "first_name"
                                    )
                                  }
                                  required
                                />
                                <label
                                  htmlFor="inputheight1"
                                  className="label-new"
                                  style={{ fontSize: "12px" }}
                                >
                                  FIRST NAME
                                </label>
                              </div>

                              {occupants.first_name?.length > 4 ? (
                                <p
                                  style={{
                                    fontSize: "16px",
                                    color: "green",
                                    fontWeight: "bold",
                                    position: "absolute",
                                    right: "15px",
                                    top: "10px",
                                    zIndex: "123",
                                  }}
                                >
                                  ✔
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div class="input-group my-3">
                              <div className="form-group-new">
                                <input
                                  className="form-control-new rounded-0 text-capitalize"
                                  placeholder=" "
                                  type="text"
                                  value={occupants.last_name}
                                  onChange={(e) =>
                                    handleInputChangeOccupants(
                                      e.target.value,
                                      index,
                                      "last_name"
                                    )
                                  }
                                  required
                                />
                                <label
                                  htmlFor="inputheight1"
                                  className="label-new"
                                  style={{ fontSize: "12px" }}
                                >
                                  LAST NAME
                                </label>
                              </div>
                              {occupants.last_name?.length > 4 ? (
                                <p
                                  style={{
                                    fontSize: "16px",
                                    color: "green",
                                    fontWeight: "bold",
                                    position: "absolute",
                                    right: "15px",
                                    top: "10px",
                                    zIndex: "123",
                                  }}
                                >
                                  ✔
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div class="input-group my-3">
                              <div className="form-group-new">
                                <input
                                  className="form-control-new rounded-0 text-capitalize"
                                  placeholder=" "
                                  type="tel"
                                  value={occupants.sn}
                                  onChange={(e) =>
                                    handleInputChangeOccupants(
                                      e.target.value,
                                      index,
                                      "sn"
                                    )
                                  }
                                  required
                                />
                                <label
                                  htmlFor="inputheight1"
                                  className="label-new"
                                  style={{ fontSize: "12px" }}
                                >
                                  SECRUITY NUMBER
                                </label>
                              </div>
                              {occupants.sn?.length > 4 ? (
                                <p
                                  style={{
                                    fontSize: "16px",
                                    color: "green",
                                    fontWeight: "bold",
                                    position: "absolute",
                                    right: "15px",
                                    top: "10px",
                                    zIndex: "123",
                                  }}
                                >
                                  ✔
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div class="input-group my-3">
                              <div className="form-group-new">
                                <input
                                  className="form-control-new rounded-0 text-capitalize"
                                  placeholder=" "
                                  type="date"
                                  value={occupants.dob}
                                  onChange={(e) =>
                                    handleInputChangeOccupants(
                                      e.target.value,
                                      index,
                                      "dob"
                                    )
                                  }
                                  required
                                />
                                <label
                                  htmlFor="inputheight1"
                                  className="label-new"
                                  style={{ fontSize: "12px" }}
                                >
                                  Date Of Birth
                                </label>
                              </div>
                              {occupants.dob?.length > 4 ? (
                                <p
                                  style={{
                                    fontSize: "16px",
                                    color: "green",
                                    fontWeight: "bold",
                                    position: "absolute",
                                    right: "15px",
                                    top: "10px",
                                    zIndex: "123",
                                  }}
                                >
                                  ✔
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-1">
                            <div class="input-group my-3">
                              <div className="form-group-new">
                                <input
                                  className="form-control-new rounded-0 text-capitalize"
                                  placeholder=" "
                                  type="text"
                                  disabled
                                  value={occupants.age}
                                />
                                <label
                                  htmlFor="inputheight1"
                                  className="label-new"
                                  style={{ fontSize: "12px" }}
                                >
                                  Age
                                </label>
                              </div>
                              {occupants?.age?.length > 1 ? (
                                <p
                                  style={{
                                    fontSize: "16px",
                                    color: "green",
                                    fontWeight: "bold",
                                    position: "absolute",
                                    right: "15px",
                                    top: "10px",
                                    zIndex: "123",
                                  }}
                                >
                                  ✔
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="select-container my-2">
                              <select
                                name="estimated_credit_score"
                                required
                                formcontrolast_name="creditRange"
                                className={`form-control-select w-100 ${
                                  occupants.cosign
                                    ? "text-success"
                                    : "text-dark"
                                }`}
                                value={occupants.cosign}
                                onChange={(e) =>
                                  handleInputChangeOccupants(
                                    e.target.value,
                                    index,
                                    "cosign"
                                  )
                                }
                                onFocus={() =>
                                  document
                                    ?.querySelector(".label-select")
                                    ?.classList?.add("label-top")
                                }
                                onBlur={() =>
                                  !occupants.cosign &&
                                  document
                                    ?.querySelector(".label-select")
                                    ?.classList?.remove("label-top")
                                }
                              >
                                <option value="" disabled></option>
                                <option
                                  value="Occupant"
                                  className="ng-star-inserted"
                                >
                                  Occupant{" "}
                                  {occupants.cosign === "Occupant" ? (
                                    <span className="tick-mark2">✔</span>
                                  ) : null}
                                </option>
                                <option
                                  value="Occupant & Cosign"
                                  className="ng-star-inserted"
                                >
                                  Occupant & Cosign{" "}
                                  {occupants.cosign === "Occupant & Cosign" ? (
                                    <span className="tick-mark2">✔</span>
                                  ) : null}
                                </option>
                                <option
                                  value="Cosign Only"
                                  className="ng-star-inserted"
                                >
                                  Cosign Only{" "}
                                  {occupants.cosign === "Cosign Only" ? (
                                    <span className="tick-mark2">✔</span>
                                  ) : null}
                                </option>
                              </select>
                              <label
                                className={`label-new ${
                                  occupants.cosign ? "label-top" : ""
                                }`}
                                htmlFor="estimated_credit_score"
                              >
                                Occ/Cosign
                              </label>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="input-group my-2">
                              <div className="form-group-new">
                                <input
                                  className="form-control-new rounded-0 text-capitalize"
                                  placeholder=" "
                                  type="email"
                                  value={occupants.email}
                                  onChange={(e) =>
                                    handleInputChangeOccupants(
                                      e.target.value,
                                      index,
                                      "email"
                                    )
                                  }
                                  required
                                />
                                <label
                                  htmlFor="inputheight1"
                                  className="label-new"
                                >
                                  EMAIL ID
                                </label>
                              </div>
                              {occupants.email?.length > 4 ? (
                                <p
                                  style={{
                                    fontSize: "16px",
                                    color: "green",
                                    fontWeight: "bold",
                                    position: "absolute",
                                    right: "15px",
                                    top: "10px",
                                    zIndex: "123",
                                  }}
                                >
                                  ✔
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="input-group my-2">
                              <div className="form-group-new">
                                <input
                                  className="form-control-new rounded-0 text-capitalize"
                                  placeholder=" "
                                  type="tel"
                                  maxLength="14"
                                  value={occupants.phone}
                                  onChange={(e) =>
                                    handleInputChangeOccupants(
                                      e.target.value,
                                      index,
                                      "phone"
                                    )
                                  }
                                  required
                                />
                                <label
                                  htmlFor="inputheight1"
                                  className="label-new"
                                >
                                  PHONE
                                </label>
                              </div>
                              {occupants.phone?.length > 4 ? (
                                <p
                                  style={{
                                    fontSize: "16px",
                                    color: "green",
                                    fontWeight: "bold",
                                    position: "absolute",
                                    right: "15px",
                                    top: "10px",
                                    zIndex: "123",
                                  }}
                                >
                                  ✔
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="input-group my-2">
                              <div className="form-group-new">
                                <input
                                  className="form-control-new rounded-0 text-capitalize"
                                  placeholder=" "
                                  type="text"
                                  value={occupants.relationship}
                                  onChange={(e) =>
                                    handleInputChangeOccupants(
                                      e.target.value,
                                      index,
                                      "relationship"
                                    )
                                  }
                                  required
                                />
                                <label
                                  htmlFor="inputheight1"
                                  className="label-new"
                                >
                                  RELATIONSHIP
                                </label>
                              </div>
                              {occupants.relationship?.length > 4 ? (
                                <p
                                  style={{
                                    fontSize: "16px",
                                    color: "green",
                                    fontWeight: "bold",
                                    position: "absolute",
                                    right: "15px",
                                    top: "10px",
                                    zIndex: "123",
                                  }}
                                >
                                  ✔
                                </p>
                              ) : null}
                            </div>
                          </div>
                          {ConSingerOccupants?.length > 1 && (
                            <div
                              className="mt-4 btn btn-danger cursor-pointer"
                              style={{
                                display: "flex",
                                justifyContent: "start",
                                marginBottom: "20px",
                                marginLeft: "12px",
                                width: "100px",
                              }}
                              onClick={() =>
                                handleMinusClickOccupants(index, occupants)
                              }
                            >
                              Remove
                            </div>
                          )}
                        </div>
                      ))}
                      <div
                        className="col-md-3 btn btn-primary cursor-pointer"
                        style={{
                          marginBottom: "20px",
                          width: "100px",
                          marginLeft: "12px",
                        }}
                        onClick={handlePlusClickOccupants}
                      >
                        Add
                      </div>
                    </>
                  ) : null}
                  <h5
                    style={{
                      fontSize: "20px",
                      fontWeight: "500",
                      color: "black",
                    }}
                    className="mt-2"
                  >
                    Important Notice for Applicants:
                  </h5>
                  <label className="fontsbtn">
                  Any individual added here as a Cosigner or Occupant Cosigner will receive an application link to fully complete the co-application process. Please note that each Cosigner or Occupant Cosigner is required to pay an application fee.
                  </label>{" "}
                  <label className="fontsbtn mt-2">
                    Next is <span className="fontsbtncobor">Income</span>
                  </label>{" "}
                  <br />
                  <button
                    className="btn btn-primary rounded-0 mt-2 col-md-3 button-fw1 ms-2"
                    type="submit"
                  >
                    Save And Continue &nbsp;
                    <AiOutlineArrowRight />
                  </button>{" "}
                </>
              </div>
            </form>
          </div>
          <ProfileInfo />
          <div className="footerimage3">
            <img
              src="https://bankerbroker.developer-oa.xyz/assets/img/footercity.svg"
              width="100%"
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default TanCoSigner;
