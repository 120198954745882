/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect } from "react";
import { useLoadScript, StandaloneSearchBox } from "@react-google-maps/api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const libraries = ["places"];

const GoogleSerachThree = ({
  multipleAddressData,
  setMultipleAddressData,
  years,
  startDate,
}) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDXJS_VZMhnp0szh92aZGg8RHszz6RMQN8",
    libraries,
  });

  const calculateTotalYears = () => {
    return multipleAddressData?.reduce((total, employee) => {
      const yearss = parseInt(employee.total_year || 0, 10);
      const months = parseInt(employee.total_month || 0, 10);
      return total + yearss + months / 12;
    }, 0);
  };

  const handleStartDateChange = (date, index) => {
    const newAddresses = [...multipleAddressData];
    newAddresses[index].start_date = date;
    setMultipleAddressData(newAddresses);
    calculateTotalDuration(index, newAddresses);
    const totalYears = calculateTotalYears();
    if (totalYears + years < 5) {
      setMultipleAddressData([
        ...multipleAddressData,
        {
          address: "",
          start_date: "",
          end_date:
            multipleAddressData?.[multipleAddressData?.length - 1]?.start_date,
          moving_reason: "",
          rent_option: "",
          rent_monthly: "",
          total_year: "",
          total_month: "",
          company_boolean: false,
          company_name: "",
          first_name: "",
          last_name: "",
          phone: "",
          email: "",
        },
      ]);
    } else {
      const filteredAddresses = newAddresses.slice(0, index + 1);
      setMultipleAddressData(filteredAddresses);
    }
  };

  const handleEndDateChange = (date, index) => {
    const newAddresses = [...multipleAddressData];
    newAddresses[index].end_date = date;
    setMultipleAddressData(newAddresses);
    calculateTotalDuration(index, newAddresses);
  };

  const calculateTotalDuration = (index, addresses) => {
    const startDate = addresses[index].start_date;
    const endDate = addresses[index].end_date;
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const years = end.getFullYear() - start.getFullYear();
      const months = end.getMonth() - start.getMonth();
      const totalMonths = years * 12 + months;
      const totalYears = totalMonths / 12;
      const newAddresses = [...addresses];
      newAddresses[index].total_year = Math.floor(totalYears);
      newAddresses[index].total_month = totalMonths % 12;
      setMultipleAddressData(newAddresses);
    }
  };

  const handleInputChange = (value, index, field) => {
    if (field === "phone") {
      value = formatPhoneNumber(value);
    }
    const newAddresses = [...multipleAddressData];
    newAddresses[index][field] = value;
    setMultipleAddressData(newAddresses);
  };

  const formatPhoneNumber = (input) => {
    let inputPhoneNumber = input.replace(/\D/g, "");
    if (inputPhoneNumber.length > 10) {
      inputPhoneNumber = inputPhoneNumber.slice(0, 10);
    }
    let formattedPhoneNumber = "";
    if (inputPhoneNumber.length > 3) {
      formattedPhoneNumber = `(${inputPhoneNumber.substring(0, 3)})`;
      if (inputPhoneNumber.length > 6) {
        formattedPhoneNumber += ` ${inputPhoneNumber.substring(
          3,
          6
        )}-${inputPhoneNumber.substring(6)}`;
      } else {
        formattedPhoneNumber += ` ${inputPhoneNumber.substring(3)}`;
      }
    } else {
      formattedPhoneNumber = inputPhoneNumber;
    }
    return formattedPhoneNumber;
  };

  const searchBoxRefs = useRef([]);

  const onPlacesChanged = (index) => {
    const places = searchBoxRefs.current[index].getPlaces();
    if (places.length === 0) {
      return;
    }
    const place = places[0];
    const userInputAddress = place.formatted_address;
    handleInputChange(userInputAddress, index, "previous_address");
  };

  useEffect(() => {
    const newAddresses = [...multipleAddressData];
    newAddresses[0].end_date = startDate;
    setMultipleAddressData(multipleAddressData);
  }, [multipleAddressData, setMultipleAddressData]);

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading maps...</div>;

  return (
    <>
      {multipleAddressData?.map((address, index) => (
        <>
          <div className="col-md-6">
            <h5 className="form-label font-bold text-capitalize">
              Previous Address - List minimum 5 years
            </h5>
          </div>
          <div className="col-md-6 position-relative">
            <div className="form-group-new">
              <input
                className="form-control-new rounded-0 text-capitalize"
                type="text"
                placeholder=" "
                value={address?.moving_reason}
                onChange={(e) =>
                  handleInputChange(e.target.value, index, "moving_reason")
                }
                required
              />
              <label htmlFor="inputheight1" className="label-new">
                Reason For Moving
              </label>
            </div>
            {address?.moving_reason?.length > 4 && (
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  position: "absolute",
                  color: "green",
                  right: "6%",
                  bottom: "22px",
                  zIndex: "123",
                }}
              >
                ✔
              </p>
            )}
          </div>
          <StandaloneSearchBox
            onLoad={(ref) => (searchBoxRefs.current[index] = ref)}
            onPlacesChanged={() => onPlacesChanged(index)}
          >
            <div className="form-group-new">
              <input
                className="form-control-new rounded-0 text-capitalize"
                type="text"
                placeholder=" "
                required
                value={address?.previous_address}
                onChange={(e) =>
                  handleInputChange(e.target.value, index, "previous_address")
                }
              />
              <label htmlFor="inputheight1" className="label-new">
                Previous Address
              </label>
              {address?.previous_address?.length > 10 ? (
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    position: "absolute",
                    color: "green",
                    right: "30px",
                    bottom: "-8px",
                    zIndex: "123",
                  }}
                >
                  ✔
                </p>
              ) : null}
            </div>
          </StandaloneSearchBox>
          <div className="col-md-2">
            <div className="position-relative ms-1">
              <label
                className="label font-sm font-sm1 clr"
                style={{ marginTop: "14px" }}
              >
                From This Date.
                <br />
              </label>
              <div>
                <DatePicker
                  required
                  selected={address?.start_date}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  className="form-control w-100 h-48"
                  showFullMonthYearPicker
                  onChange={(date) => handleStartDateChange(date, index)}
                />
              </div>
              {address?.start_date &&
                address?.start_date?.toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "2-digit",
                }).length > 4 && (
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      position: "absolute",
                      color: "green",
                      right: "8%",
                      bottom: "0px",
                      zIndex: "123",
                    }}
                  >
                    ✔
                  </p>
                )}
            </div>
          </div>
          <div className="col-md-2">
            <div className="position-relative ms-1">
              <label
                className="label font-sm font-sm1 clr"
                style={{ marginTop: "14px" }}
              >
                To This Date.
                <br />
              </label>
              <div>
                <DatePicker
                  required
                  selected={address?.end_date}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  showFullMonthYearPicker
                  className="form-control w-100 h-48"
                  onChange={(date) => handleEndDateChange(date, index)}
                />
              </div>
              {address?.end_date &&
                address?.end_date?.toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "2-digit",
                }).length > 4 && (
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      position: "absolute",
                      color: "green",
                      right: "24px",
                      bottom: "0px",
                      zIndex: "123",
                    }}
                  >
                    ✔
                  </p>
                )}
            </div>
          </div>
          <div
            className="col-md-2 position-relative"
            style={{ marginTop: "28px" }}
          >
            <div className="form-group-new">
              <input
                className="form-control-new rounded-0 text-capitalize"
                type="text"
                placeholder=" "
                value={address?.total_year ? address?.total_year : ""}
                disabled
              />
              <label
                htmlFor="inputheight1"
                className="label-new"
                style={{ fontSize: "12px" }}
              >
                Total Year
              </label>
            </div>
          </div>
          <div
            className="col-md-2 position-relative"
            style={{ marginTop: "28px" }}
          >
            <div className="form-group-new">
              <input
                className="form-control-new rounded-0 text-capitalize"
                type="text"
                placeholder=" "
                value={address?.total_month ? address?.total_month : ""}
                disabled
              />
              <label
                htmlFor="inputheight1"
                className="label-new"
                style={{ fontSize: "12px" }}
              >
                Total Month
              </label>
            </div>
          </div>
          <div
            className="col-md-2 position-relative"
            style={{ marginTop: "28px" }}
          >
            <div className="select-container">
              <select
                required
                name="estimated_credit_score"
                formcontrolname="creditRange"
                className={`form-control-select w-100 ${
                  address?.rent_option ? "text-success" : "text-dark"
                }`}
                value={address?.rent_option}
                onChange={(e) =>
                  handleInputChange(e.target.value, index, "rent_option")
                }
                onFocus={() =>
                  document
                    ?.querySelector(".label-select")
                    ?.classList?.add("label-top")
                }
                onBlur={() =>
                  !address?.rent_option &&
                  document
                    ?.querySelector(".label-select")
                    ?.classList?.remove("label-top")
                }
              >
                <option value="" disabled></option>
                <option value="Rent">
                  Rent{" "}
                  {address?.rent_option === "Rent" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value="Own">
                  Own{" "}
                  {address?.rent_option === "Own" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
                <option value="Rent Free">
                  Rent Free{" "}
                  {address?.rent_option === "Rent Free" ? (
                    <span className="tick-mark2">✔</span>
                  ) : null}
                </option>
              </select>
              <label
                className={`label-new ${
                  address?.rent_option ? "label-top" : ""
                }`}
                htmlFor="estimated_credit_score"
                style={{ fontSize: "12px" }}
              >
                Housing
              </label>
            </div>
          </div>
          <div
            className="col-md-2 position-relative"
            style={{ marginTop: "28px" }}
          >
            <div className="form-group-new">
              <input
                className="form-control-new rounded-0 text-capitalize w-100"
                type="text"
                placeholder=" "
                value={
                  address?.rent_option === "Rent Free"
                    ? 0
                    : address?.rent_monthly
                }
                required
                onChange={(e) =>
                  handleInputChange(e.target.value, index, "rent_monthly")
                }
                disabled={address?.rent_option === "Rent Free"}
              />
              <label
                htmlFor="inputheight1"
                className="label-new"
                style={{ fontSize: "10px" }}
              >
                How Much Monthly
              </label>
            </div>
            {address?.rent_monthly?.length > 4 ? (
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  position: "absolute",
                  color: "green",
                  right: "24px",
                  bottom: "10px",
                  zIndex: "123",
                }}
              >
                ✔
              </p>
            ) : null}
          </div>
          <div className="col-md-12">
            <h5 className="form-label font-bold text-capitalize">
              Landlord Contact Info
            </h5>
            <p className="h6">
              Is landlord a company or an individual. To whom you send your
              cheques to?
            </p>
            <span className="mt-3">
              <input
                type="radio"
                name="company"
                checked={address?.company_boolean}
                onChange={(e) =>
                  handleInputChange(
                    !address?.company_boolean,
                    index,
                    "company_boolean"
                  )
                }
              />
              &nbsp; Company
            </span>
            <span className="ms-5">
              <input
                type="radio"
                name="company"
                onChange={(e) =>
                  handleInputChange(false, index, "company_boolean")
                }
              />
              &nbsp; Indiviual
            </span>
          </div>
          {address?.company_boolean ? (
            <div className="col-md-12 position-relative">
              <div className="form-group-new mt-2">
                <input
                  className="form-control-new rounded-0 text-capitalize"
                  type="text"
                  placeholder=" "
                  value={address?.company_name}
                  onChange={(e) =>
                    handleInputChange(e.target.value, index, "company_name")
                  }
                />
                <label htmlFor="inputheight1" className="label-new">
                  Company Name
                </label>
              </div>

              {address?.company_name?.length > 4 ? (
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    position: "absolute",
                    color: "green",
                    right: "28px",
                    bottom: "10px",
                    zIndex: "123",
                  }}
                >
                  ✔
                </p>
              ) : null}
            </div>
          ) : null}
          <div className="col-md-6 position-relative mt-2">
            <div className="form-group-new">
              <input
                className="form-control-new rounded-0 text-capitalize"
                type="text"
                placeholder=" "
                value={address?.first_name}
                onChange={(e) =>
                  handleInputChange(e.target.value, index, "first_name")
                }
                required
              />
              <label htmlFor="inputheight1" className="label-new">
                First Name
              </label>
            </div>

            {address?.first_name?.length > 4 ? (
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  position: "absolute",
                  color: "green",
                  right: "24px",
                  bottom: "10px",
                  zIndex: "123",
                }}
              >
                ✔
              </p>
            ) : null}
          </div>
          <div className="col-md-6 position-relative mt-2">
            <div className="form-group-new">
              <input
                className="form-control-new rounded-0 text-capitalize"
                type="text"
                placeholder=" "
                value={address?.last_name}
                onChange={(e) =>
                  handleInputChange(e.target.value, index, "last_name")
                }
                required
              />
              <label htmlFor="inputheight1" className="label-new">
                Last Name
              </label>
            </div>
            {address?.last_name?.length > 4 ? (
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  position: "absolute",
                  color: "green",
                  right: "24px",
                  bottom: "10px",
                  zIndex: "123",
                }}
              >
                ✔
              </p>
            ) : null}
          </div>
          <div className="col-md-6 position-relative mt-2">
            <div className="form-group-new">
              <input
                className="form-control-new rounded-0 text-capitalize"
                type="text"
                placeholder=" "
                value={address?.phone}
                onChange={(e) =>
                  handleInputChange(e.target.value, index, "phone")
                }
                required
              />
              <label htmlFor="inputheight1" className="label-new">
                Phone Number
              </label>
            </div>
            {address?.phone?.length > 4 ? (
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  position: "absolute",
                  color: "green",
                  right: "24px",
                  bottom: "10px",
                  zIndex: "123",
                }}
              >
                ✔
              </p>
            ) : null}
          </div>
          <div className="col-md-6 position-relative mt-2">
            <div className="form-group-new">
              <input
                className="form-control-new rounded-0"
                type="email"
                placeholder=" "
                value={
                  address?.email?.charAt(0).toUpperCase() +
                  address?.email?.slice(1).toLowerCase()
                    ? address?.email?.charAt(0).toUpperCase() +
                      address?.email?.slice(1).toLowerCase()
                    : ""
                }
                required
                onChange={(e) =>
                  handleInputChange(e.target.value, index, "email")
                }
              />
              <label htmlFor="inputheight1" className="label-new">
                Email
              </label>
            </div>
            {address?.email?.length > 4 ? (
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  position: "absolute",
                  color: "green",
                  right: "24px",
                  bottom: "18px",
                  zIndex: "123",
                }}
              >
                ✔
              </p>
            ) : null}
          </div>
        </>
      ))}
    </>
  );
};

export default GoogleSerachThree;
