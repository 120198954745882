/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { useLoadScript, StandaloneSearchBox } from "@react-google-maps/api";

const libraries = ["places"];

const GoogleAddressIncome = ({
  setGoogleData,
  setAddressCurrent,
  addressCurrent,
  setStreetAddress,
  setMultipleAddressData,
}) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDXJS_VZMhnp0szh92aZGg8RHszz6RMQN8",
    libraries,
  });

  const [addressDetails, setAddressDetails] = useState({});
  const searchBoxRef = useRef(null);

  useEffect(() => {
    setGoogleData(addressDetails);
  }, [addressDetails]);

  const parseAddressComponents = (components) => {
    const addressObject = {
      street_number: "",
      street_name: "",
      city: "",
      state: "",
      postal_code: "",
      apt_unit: "",
    };

    components?.forEach((component) => {
      const types = component.types;
      if (types.includes("street_number")) {
        addressObject.street_number = component.long_name;
      }
      if (types.includes("route")) {
        addressObject.street_name = component.long_name;
      }
      if (types.includes("locality")) {
        addressObject.city = component.long_name;
      }
      if (types.includes("administrative_area_level_1")) {
        addressObject.state = component.short_name;
      }
      if (types.includes("postal_code")) {
        addressObject.postal_code = component.long_name;
      }
      if (types.includes("subpremise")) {
        addressObject.apt_unit = component.long_name;
      }
    });

    return addressObject;
  };

  const onPlacesChanged = () => {
    const places = searchBoxRef.current.getPlaces();
    if (places.length === 0) {
      return;
    }
    const place = places[0];
    const parsedAddress = parseAddressComponents(place.address_components);
    setAddressDetails(parsedAddress);
    setAddressCurrent(place.formatted_address);
    setStreetAddress(place.formatted_address);
    setMultipleAddressData(parsedAddress);
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading maps...</div>;
  return (
    <StandaloneSearchBox
      onLoad={(ref) => (searchBoxRef.current = ref)}
      onPlacesChanged={onPlacesChanged}
    >
      <div className="form-group-new">
        <input
          className="form-control-new rounded-0 text-capitalize"
          type="text"
          placeholder=" "
          value={addressCurrent}
          onChange={(e) => setAddressCurrent(e.target.value)}
          required
        />
        <label htmlFor="inputheight1" className="label-new">
          Street Address
        </label>
        {addressDetails?.city?.length > 10 ? (
          <p
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              position: "absolute",
              color: "green",
              right: "30px",
              bottom: "-8px",
              zIndex: "123",
            }}
          >
            ✔
          </p>
        ) : null}
      </div>
    </StandaloneSearchBox>
  );
};

export default GoogleAddressIncome;
