/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from "react";
import Header from "../../../../Layout/Header";
import ProfileInfo from "../../Profile/ProfileInfo";
import { AiOutlineArrowRight } from "react-icons/ai";
import Baseurl from "../../../../../Baseurl";
import Swal from "sweetalert2";
import axios from "axios";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import html2pdf from "html2pdf.js";
import DocumentReviewSubmit from "./DocumentReviewSubmit";
import SidebarDashNew from "./SidebarDashNew";

export const TanDocument = () => {
  const [personalInfo, setPersonalInfo] = useState([]);
  const [income, setIncome] = useState([]);
  const [itsForm, setItsForm] = useState([]);
  const [coSinger, setCoSinger] = useState([]);
  const [additionalInfo, setAdditionalInfo] = useState([]);
  const [submitData, setSubmitData] = useState([]);
  // New Images Code Strat
  const [govermentPicture, setGovermentPicture] = useState([]);
  const [socialSecruityPicture, setSocialSecruityPicture] = useState([]);
  const [proofIncomePicture, setProofIncomePicture] = useState([]);
  const [otherDocumentPicture, setOtherDocumentPicture] = useState([]);

  const handleChangeGoverment = ({ fileList }) => {
    setGovermentPicture(fileList);
  };

  const handleRemoveGoverment = (file) => {
    setGovermentPicture((prevList) =>
      prevList.filter((item) => item.uid !== file.uid)
    );
    const reviewData = new FormData();
    reviewData.append("application_id", application_id);
    reviewData.append("id", file.id);
    let token = localStorage.getItem("usertoken");
    var config = {
      method: "post",
      url: `${Baseurl.baseurl}real-estate/rent/tenant/del/document-file`,
      data: reviewData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response);
        console.log(response?.data?.message);
        if (response?.data?.status === true) {
          setLoader(false);
          Swal.fire({
            toast: true,
            icon: "success",
            title: response?.data?.message,
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
      });
  };

  const handleChangeSecruity = ({ fileList }) => {
    setSocialSecruityPicture(fileList);
  };

  const handleRemoveSecruity = (file) => {
    setSocialSecruityPicture((prevList) =>
      prevList.filter((item) => item.uid !== file.uid)
    );
    const reviewData = new FormData();
    reviewData.append("application_id", application_id);
    reviewData.append("id", file.id);
    let token = localStorage.getItem("usertoken");
    var config = {
      method: "post",
      url: `${Baseurl.baseurl}real-estate/rent/tenant/del/document-file`,
      data: reviewData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response);
        console.log(response?.data?.message);
        if (response?.data?.status === true) {
          setLoader(false);
          Swal.fire({
            toast: true,
            icon: "success",
            title: response?.data?.message,
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
      });
  };

  const handleChangeProofIncome = ({ fileList }) => {
    setProofIncomePicture(fileList);
  };

  const handleRemoveProofIncome = (file) => {
    setProofIncomePicture((prevList) =>
      prevList.filter((item) => item.uid !== file.uid)
    );
    const reviewData = new FormData();
    reviewData.append("application_id", application_id);
    reviewData.append("id", file.id);
    let token = localStorage.getItem("usertoken");
    var config = {
      method: "post",
      url: `${Baseurl.baseurl}real-estate/rent/tenant/del/document-file`,
      data: reviewData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response);
        console.log(response?.data?.message);
        if (response?.data?.status === true) {
          setLoader(false);
          Swal.fire({
            toast: true,
            icon: "success",
            title: response?.data?.message,
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
      });
  };
  const handleChangeOtherDocument = ({ fileList }) => {
    setOtherDocumentPicture(fileList);
  };

  const handleRemoveOtherDocument = (file) => {
    setOtherDocumentPicture((prevList) =>
      prevList.filter((item) => item.uid !== file.uid)
    );
    const reviewData = new FormData();
    reviewData.append("application_id", application_id);
    reviewData.append("id", file.id);
    let token = localStorage.getItem("usertoken");
    var config = {
      method: "post",
      url: `${Baseurl.baseurl}real-estate/rent/tenant/del/document-file`,
      data: reviewData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response);
        console.log(response?.data?.message);
        if (response?.data?.status === true) {
          setLoader(false);
          Swal.fire({
            toast: true,
            icon: "success",
            title: response?.data?.message,
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
      });
  };

  // New Images Code End

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const [allPostData, setAllPostData] = useState();
  const [bund, setBund] = useState("");
  const application_id = localStorage.getItem("assignId");

  const location = useLocation();
  const postData = () => {
    const token = localStorage.getItem("usertoken");
    const reviewData = new FormData();
    reviewData.append("application_id", application_id);
    var config = {
      method: "post",
      url: `${Baseurl.baseurl}real-estate/rent/tenant/progress`,
      data: reviewData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        setAllPostData(response?.data?.data);
        console.log(response?.data?.data, "sidebar response");
        if (response?.data?.status === true) {
          console.log(response?.data?.message, "response?.data?.message");
          // history.push('/new_mortage')
          setLoader(false);

          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
        // console.log(allGet, "all data");
        console.log(response, "my response");
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setBund(error?.response?.data?.errors);
        Swal.fire({
          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };

  const constructImageUrl = (baseUrl, path, file) =>
    `${baseUrl}${path}/${file}`;

  const Get_Document = () => {
    const reviewData = new FormData();
    reviewData.append("application_id", application_id);
    setLoader(true);
    let token = localStorage.getItem("usertoken");
    var config = {
      method: "post",
      url: `${Baseurl.baseurl}real-estate/rent/tenant/get/document`,
      data: reviewData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        const res = response?.data?.data;
        const processedPictures = res?.map((item, index) => ({
          uid: index,
          name: item.file,
          status: "done",
          url: constructImageUrl(Baseurl.imgurl, item.path, item.file),
          type: item.type,
          id: item.id,
        }));

        const governmentPictures = processedPictures
          ?.filter((pic) => pic.type === "Government issued photo")
          ?.map((pic) => ({
            uid: pic.id,
            name: pic.name,
            status: pic.status,
            url: pic.url,
            id: pic.id,
          }));
        setGovermentPicture(governmentPictures);
        const socialPicture = processedPictures
          ?.filter((pic) => pic.type === "Social Security Card")
          ?.map((pic) => ({
            uid: pic.id,
            name: pic.name,
            status: pic.status,
            url: pic.url,
            id: pic.id,
          }));
        setSocialSecruityPicture(socialPicture);
        const proofIncomePicture = processedPictures
          ?.filter((pic) => pic.type === "Proof of income")
          ?.map((pic) => ({
            uid: pic.id,
            name: pic.name,
            status: pic.status,
            url: pic.url,
            id: pic.id,
          }));
        setProofIncomePicture(proofIncomePicture);
        const otherDocumentPicture = processedPictures
          ?.filter((pic) => pic.type === "Any other documents")
          ?.map((pic) => ({
            uid: pic.id,
            name: pic.name,
            status: pic.status,
            url: pic.url,
            id: pic.id,
          }));
        setOtherDocumentPicture(otherDocumentPicture);

        if (response?.data?.status === true) {
          setLoader(false);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setBund(error?.response?.data?.errors);
      });
  };

  const reviewDataSubmit = () => {
    const token = localStorage.getItem("usertoken");
    const application_id = localStorage.getItem("assignId");
    const reviewData = new FormData();
    reviewData.append("application_id", application_id);
    var config = {
      method: "post",
      url: `${Baseurl.baseurl}real-estate/rent/tenant/get/review/submit/form/data`,
      data: reviewData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then((response) => {
        setPersonalInfo(
          response?.data?.data?.personal_info[0]
            ? response?.data?.data?.personal_info[0]
            : response?.data?.data?.personal_info
        );
        setItsForm(
          response?.data?.data?.personal_info[0]?.its_form[0]
            ? response?.data?.data?.personal_info[0]?.its_form[0]
            : response?.data?.data?.personal_info?.its_form[0]
        );
        const filters = response?.data?.data?.co_signer?.filter(
          (fil) => fil?.type === "occupants"
        );
        setCoSinger(filters);
        setIncome(response?.data?.data?.income[0]);
        setAdditionalInfo(response?.data?.data?.additional_info[0]);
        setSubmitData(response?.data?.data?.review);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    postData();
    Get_Document();
    reviewDataSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [isOpen, setIsOpen] = useState(true);

  const AddBaseIncome = () => {
    setLoader(true);
    const reviewData = new FormData();
    reviewData.append("application_id", application_id);
    const allPictures = [
      ...govermentPicture?.map((file) => ({
        type: "Government issued photo",
        file,
      })),
      ...socialSecruityPicture?.map((file) => ({
        type: "Social Security Card",
        file,
      })),
      ...proofIncomePicture?.map((file) => ({ type: "Proof of income", file })),
      ...otherDocumentPicture?.map((file) => ({
        type: "Any other documents",
        file,
      })),
    ];
    allPictures?.forEach((item, index) => {
      if (item.file.originFileObj) {
        reviewData.append(`files[${index}][type]`, item.type);
        reviewData.append(`files[${index}][image]`, item.file.originFileObj);
      }
    });

    let token = localStorage.getItem("usertoken");
    var config = {
      method: "post",
      url: `${Baseurl.baseurl}real-estate/rent/tenant/store/document`,
      data: reviewData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response);
        console.log(response?.data?.message);
        if (response?.data?.status === true) {
          history.push("/rent/tanent/review");
          setLoader(false);
          Swal.fire({
            toast: true,
            icon: "success",
            title: response?.data?.message,
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setBund(error?.response?.data?.errors);
        Swal.fire({
          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };

  const convertToPdf = async () => {
    setLoader(true);
    const element = document.getElementById("my-component");
    const options = {
      margin: 10,
      filename: "my-component",
      image: { type: "jpeg", quality: 100 },
      html2canvas: {
        letterRendering: true,
        allowTaint: true,
        useCORS: true,
        logging: false,
        scale: 3,
      },
      jsPDF: { unit: "mm", format: "a3", orientation: "portrait" },
    };

    await html2pdf().from(element).set(options).save("my-component");
    setLoader(false);
  };

  const isDisabled = allPostData === 100;
  return (
    <>
      <Header />
      {loader ? <div className="loader"></div> : null}

      <div className="container-fluid">
        <div className="row">
          <>
            <SidebarDashNew submitDataId={submitData?.id} />
          </>

          <div
            className={
              isOpen === true
                ? "col-md-8 open he mt-5 mb-2 ps-lg-5"
                : isOpen === false
                ? "col-md-10 open nhi he mt-5 mb-2 ps-lg-5"
                : ""
            }
          >
            <div className="row">
              <div className="col-10">
                <h4 className="text-black fw-bold stated-ln1" id="uploadhdg1">
                  Upload your following documents:
                </h4>
                <div className="row">
                  <p className="pl-md-3">
                    You can upload a photo of the document taken with a camera
                    or a PDF or scanned image.
                  </p>

                  <div className="col-md-12" id="government-lf1">
                    <ul className={`${submitData?.id ? "disabled_side" : ""}`}>
                      <h5
                        className="text-black fw-bold stated-ln1"
                        id="uploadhdg1"
                      >
                        ➤ Government issued photo-id (e.g driver's license,
                        passport, military ID, etc )
                      </h5>
                      <Upload
                        multiple
                        fileList={govermentPicture}
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        listType="picture-card"
                        accept={"image/jpeg,image/png"}
                        onChange={handleChangeGoverment}
                        onRemove={handleRemoveGoverment}
                        beforeUpload={() => false}
                      >
                        {uploadButton}
                      </Upload>

                      <h5
                        className="text-black fw-bold stated-ln1"
                        id="uploadhdg1"
                      >
                        ➤ Social Secruity Card
                      </h5>
                      <Upload
                        multiple
                        fileList={socialSecruityPicture}
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        listType="picture-card"
                        accept={"image/jpeg,image/png"}
                        onChange={handleChangeSecruity}
                        onRemove={handleRemoveSecruity}
                        beforeUpload={() => false}
                      >
                        {uploadButton}
                      </Upload>
                      <h5
                        className="text-black fw-bold stated-ln1"
                        id="uploadhdg1"
                      >
                        ➤ Proof of income / employment ( like a paystub , bank
                        statement, etc.)
                      </h5>
                      <Upload
                        multiple
                        fileList={proofIncomePicture}
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        listType="picture-card"
                        accept={"image/jpeg,image/png"}
                        onChange={handleChangeProofIncome}
                        onRemove={handleRemoveProofIncome}
                        beforeUpload={() => false}
                      >
                        {uploadButton}
                      </Upload>
                      <h5
                        className="text-black fw-bold stated-ln1"
                        id="uploadhdg1"
                      >
                        ➤ Any other documents requested by the landlord
                      </h5>
                      <Upload
                        multiple
                        fileList={otherDocumentPicture}
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        listType="picture-card"
                        accept={"image/jpeg,image/png"}
                        onChange={handleChangeOtherDocument}
                        onRemove={handleRemoveOtherDocument}
                        beforeUpload={() => false}
                      >
                        {uploadButton}
                      </Upload>
                    </ul>
                  </div>
                </div>
                {submitData?.id ? (
                  <button
                    style={{
                      fontSize: "15px",
                      fontWeight: "600",
                      borderRadius: "6px",
                      color: "white",
                      float: "right;",
                    }}
                    className="btn btn-primary mb-2 rounded"
                    onClick={convertToPdf}
                  >
                    Download to Document &nbsp;
                    <AiOutlineArrowRight
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    />
                  </button>
                ) : null}

                <div className={`${submitData?.id ? "disabled_side" : ""}`}>
                  <div className="row mt-4">
                    <label className="text-scolor fontsbtn">
                      Next is <span className="fontsbtncobor">Review</span>
                    </label>
                  </div>
                  <button
                    className="btn btn-primary rounded"
                    id="btn-save-con1"
                    onClick={AddBaseIncome}
                  >
                    Save & Continue &nbsp;
                    <AiOutlineArrowRight />
                  </button>
                </div>
              </div>
            </div>
            <div className="footerimage">
              <img
                src="https://bankerbroker.developer-oa.xyz/assets/img/footercity.svg"
                width="100%"
                alt=""
              />
            </div>
          </div>
          <ProfileInfo />
        </div>
      </div>
      <div style={{ display: "none" }}>
        <div id="my-component">
          <DocumentReviewSubmit
            personalInfo={personalInfo}
            income={income}
            itsForm={itsForm}
            coSinger={coSinger}
            additionalInfo={additionalInfo}
            submitData={submitData}
          />
        </div>
      </div>
    </>
  );
};

export default TanDocument;
