/* eslint-disable no-mixed-operators */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-const-assign */
/* eslint-disable eqeqeq */
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { FaLock } from "react-icons/fa";
import Baseurl from "../../../../../Baseurl";
import Header from "../../../../Layout/Header";
import ProfileInfo from "../../Profile/ProfileInfo";
import Swal from "sweetalert2";
import { Link, useLocation, useHistory } from "react-router-dom";
import { FaCheckCircle, FaBars } from "react-icons/fa";
import { Progress } from "antd";
import GoogleSerach from "./GoogleSerach";
import GoogleSearchTwo from "./GoogleSearchTwo";
import GoogleSerachThree from "./GoogleSearchThree";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import EmergencyGoogleAddress from "./EmergancyGoogleAddress";
import SidebarDashNew from "./SidebarDashNew";

const TanPersonalInfo = () => {
  const [loader, setLoader] = useState(false);
  const [allPostData, setAllPostData] = useState();
  const [bund, setBund] = useState("");

  const application_id = JSON.parse(localStorage.getItem("userDetail"));
  let Id = localStorage.getItem("assignId");
  const reviewData = new FormData();
  reviewData.append("application_id", Id);

  const location = useLocation();
  const pers =
    location.pathname === "/rent/tenant/personalinfo"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100 ";
  const addit =
    location.pathname === "/rent/tanent/additionalinfo"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const cosi =
    location.pathname === "/heloc/tanent/Co-Borrowers"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const inc =
    location.pathname === "/rent/tanent/Income"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const back =
    location.pathname === "/rent/tenant/background"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const demo =
    location.pathname === "/rent/tanent/demographic"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const docu =
    location.pathname === "/heloc/tanent/declaration"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";

  const review =
    location.pathname === "/rent/tanent/demographic"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const postData = () => {
    try {
      const token = localStorage.getItem("usertoken");
      var config = {
        method: "post",
        url: `${Baseurl.baseurl}real-estate/rent/tenant/progress`,
        data: reviewData,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      axios(config)
        .then((response) => {
          setAllPostData(response?.data?.data);
          console.log(response?.data?.data, "sidebar response");
          if (response?.data?.status === true) {
            console.log(response?.data?.message, "response?.data?.message");
            setLoader(false);

            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          } else {
            setLoader(false);
          }
          // console.log(allGet, "all data");
          console.log(response, "my response");
        })
        .catch(function (error) {
          setLoader(false);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          setBund(error?.response?.data?.errors);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const [isOpen, setIsOpen] = useState(true);

  // First Name Se Le Kr Nick Name tak Ki State Start Hai
  const [id, Setid] = useState();
  const [FirstName, setFirstName] = useState("");
  const [Middle, setMiddleName] = useState("");
  const [Last, setLastName] = useState("");
  const [suffix, setSuffix] = useState("");
  const [suffixField, setSuffixField] = useState("");
  const [altname, setAltname] = useState(false);
  const [Alt, setAlt] = useState("");
  const [nickname, setNickname] = useState(false);
  const [Nick, setNick] = useState("");
  // First Name Se Le Kr Nick Name tak Ki State End Hai

  // Date Birth Se Le Kr AutoAge tak Ki State Start Hai
  const [dateBirth, setDateBirth] = useState("");
  const [autoAge, setAutoAge] = useState("");
  // Date Birth Se Le Kr AutoAge tak Ki State End Hai

  // CreditScore Se Le Kr HomePhone tak Ki State Start Hai
  const [CreditScore, setCreditScore] = useState("");
  const [Ssn, setSsn] = useState("");
  const [EmailID, setEmailID] = useState("");
  const [Phone, setPhone] = useState("");
  const [WorkPhone, setWorkPhone] = useState("");
  const [Ext, setExt] = useState("");
  const [HomePhone, setHomePhone] = useState("");
  // CreditScore Se Le Kr HomePhone tak Ki State End Hai

  // Mailing Se Le Kr MailingAddress tak Ki State Start Hai
  const [mailing, setMailing] = useState(false);
  const [addressMailing, setaddressMailing] = useState("");
  // Mailing Se Le Kr MailingAddress tak Ki State End Hai

  // / Multiple Address Se Le Kr Multiple Address tak Ki State End Hai
  const [addressCurrent, setAddressCurrent] = useState("");
  const [googleData, setGoogleData] = useState({
    street_number: "",
    street_name: "",
    city: "",
    state: "",
    postal_code: "",
    apt_unit: "",
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [totalYear, setTotalYear] = useState("");
  const [totalMonth, setTotalMonth] = useState("");
  const [rentOwnFree, setRentOwnFree] = useState("");
  const [howMuchMonthly, setHowMuchMonthly] = useState("");
  // / Multiple Address Se Le Kr Multiple Address tak Ki State End Hai

  const [multipleAddressData, setMultipleAddressData] = useState([
    {
      previous_address: "",
      start_date: "",
      end_date: startDate,
      moving_reason: "",
      rent_option: "",
      rent_monthly: "",
      total_year: "",
      total_month: "",
      company_boolean: false,
      company_name: "",
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
    },
  ]);

  // / Company Se Le Kr CompanyValue tak Ki State End Hai
  const [Company, setCompany] = useState(false);
  const [CompanyValue, setCompanyValue] = useState("");
  // / Company Se Le Kr CompanyValue tak Ki State End Hai

  // / FirstName Se Le Kr Email tak Ki State End Hai
  const [LandlordFirstName, setLandlordFirstName] = useState("");
  const [LandlordLastName, setLandlordLastName] = useState("");
  const [LandlordPhone, setLandlordPhone] = useState("");
  const [LandlordEmail, setLandlordEmail] = useState("");
  // / FirstName Se Le Kr Email tak Ki State End Hai

  // / EmergencyName Se Le Kr emergancyContactAddress tak Ki State End Hai
  const [EmergencyName, setEmergencyName] = useState("");
  const [EmergencyRelation, setEmergencyRelation] = useState("");
  const [EmergencyPhone, setEmergencyPhone] = useState("");
  const [emergancyContactAddress, setEmergancyContactAddress] = useState("");
  // / EmergencyName Se Le Kr emergancyContactAddress tak Ki State End Hai

  const calculateAgeFour = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  const handleDateChangeFour = (e) => {
    const dob = e.target.value;
    const calculatedAge = calculateAgeFour(dob);
    setDateBirth(dob);
    setAutoAge(calculatedAge);
  };

  const history = useHistory();

  const [ApiData, setApiData] = useState();

  const calculateDuration = (startDate, endDate) => {
    const years = endDate.diff(startDate, "years");
    startDate.add(years, "years");
    const months = endDate.diff(startDate, "months");
    return { total_year: years, total_month: months };
  };

  const updateDatesInArray = (array) => {
    return array?.map((item) => {
      const dateStart = moment(item?.start_date, "MM/YYYY");
      const dateEnd = moment(item?.end_date, "MM/YYYY");

      if (dateStart.isValid() && dateEnd.isValid()) {
        const { total_year, total_month } = calculateDuration(
          dateStart.clone(),
          dateEnd.clone()
        );

        let updatedItem = {
          ...item,
          start_date: dateStart.toDate(),
          end_date: dateEnd.toDate(),
          total_year,
          total_month,
        };
        return updatedItem;
      } else {
        return item;
      }
    });
  };

  const get = () => {
    setLoader(true);
    let Id = localStorage.getItem("assignId");
    let token = localStorage.getItem("usertoken");

    const Data = new FormData();
    Data.append("application_id", Id);

    var config = {
      method: "post",
      url: `${Baseurl.baseurl}real-estate/rent/tenant/get/personal/info`,
      data: Data,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config).then((response) => {
      setApiData(response?.data?.data);
      setFirstName(response?.data?.data?.user_profile?.first_name);
      setMiddleName(response?.data?.data?.middle_name);
      setLastName(response?.data?.data?.user_profile?.last_name);
      setSuffix(response?.data?.data?.suffix);
      setAltname(response?.data?.data?.alternate_names);
      setAlt(response?.data?.data?.have_alternate_names);
      setNickname(response?.data?.data?.nick_name);
      setNick(response?.data?.data?.have_nick_name);
      setDateBirth(response?.data?.data?.dob);
      setAutoAge(response?.data?.data?.age);
      setSsn(response?.data?.data?.ssn);
      setCreditScore(response?.data?.data?.credit_score);
      setEmailID(response?.data?.data?.email);
      setPhone(response?.data?.data?.cell_phone);
      setWorkPhone(response?.data?.data?.work_phone);
      setExt(response?.data?.data?.ext);
      setHomePhone(response?.data?.data?.home_phone);
      setLandlordFirstName(response?.data?.data?.landlord_first_name);
      setLandlordLastName(response?.data?.data?.landlord_last_name);
      setLandlordPhone(response?.data?.data?.landlord_phone_no);
      setLandlordEmail(response?.data?.data?.land_lord_email);
      setEmergencyName(response?.data?.data?.first_name);
      setEmergencyRelation(response?.data?.data?.em_relation);
      setEmergencyPhone(response?.data?.data?.em_phone_no);
      setEmergancyContactAddress(response?.data?.data?.em_address);
      setMailing(response?.data?.data?.mailing_address === 1 ? true : false);
      setaddressMailing(response?.data?.data?.address2);
      setAddressCurrent(response?.data?.data?.address);
      const dateStart = moment(response?.data?.data?.start_date, "MM/YYYY");
      const dateEnd = moment(response?.data?.data?.end_date, "MM/YYYY");
      if (dateStart?.isValid()) {
        setStartDate(dateStart?._d);
        setEndDate(dateEnd?._d);
      } else {
        console.error("Invalid date");
      }
      setRentOwnFree(response?.data?.data?.rent_option);
      Setid(response?.data?.data?.id);
      if (response?.data?.data?.its_form) {
        const updatedItsFormArray = updateDatesInArray(
          response?.data?.data?.its_form
        );
        setMultipleAddressData(updatedItsFormArray);
      }
      setHowMuchMonthly(response?.data?.data?.rent_monthly);
      setLoader(false);
    });
  };

  useEffect(() => {
    get();
    postData();
  }, []);

  const add = (e) => {
    e.preventDefault();
    setLoader(true);
    let Id = localStorage.getItem("assignId");
    let token = localStorage.getItem("usertoken");
    const Data = new FormData();
    Data.append("application_id", Id);
    Data.append("first_name", FirstName);
    Data.append("middle_name", Middle);
    Data.append("last_name", Last);
    Data.append("suffix", suffix);
    Data.append("alternate_names", altname ? 1 : 0);
    Data.append("have_alternate_names", Alt);
    Data.append("nick_name", nickname ? 1 : 0);
    Data.append("have_nick_name", Nick);
    Data.append("dob", dateBirth);
    Data.append("age", autoAge);
    Data.append("ssn", Ssn);
    Data.append("estimated_credit_score", CreditScore);
    Data.append("email", EmailID);
    Data.append("cell_phone", Phone);
    Data.append("work_phone", WorkPhone);
    Data.append("ext", Ext);
    Data.append("home_phone", HomePhone);
    Data.append("mailing_address", mailing ? 1 : 0);
    Data.append("address2", addressMailing);
    Data.append("address", addressCurrent);
    Data.append("have_mailing_street_no", googleData?.street_number);
    Data.append("have_mailing_street_name", googleData?.street_name);
    Data.append("have_mailing_state", googleData?.state);
    Data.append("have_mailing_city", googleData?.city);
    Data.append("have_mailing_zipcode", googleData?.postal_code);
    Data.append("have_mailing_unit", googleData?.apt_unit);
    Data.append(
      "start_date",
      startDate?.toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
      })
    );
    Data.append(
      "end_date",
      endDate?.toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
      })
    );
    Data.append("rent_option", rentOwnFree);
    Data.append("rent_monthly", howMuchMonthly);

    Data.append("landlord_type", "individual");
    Data.append("landlord_first_name", LandlordFirstName);
    Data.append("landlord_last_name", LandlordLastName);
    Data.append("land_lord_email", LandlordEmail);
    Data.append("landlord_phone_no", LandlordPhone);

    Data.append("em_name", EmergencyName);
    Data.append("em_relation", EmergencyRelation);
    Data.append("em_phone_no", EmergencyPhone);
    Data.append("em_address", emergancyContactAddress);

    if (multipleAddressData[0]?.moving_reason) {
      multipleAddressData?.map((add, index) => {
        Data.append(
          `submissions[${index}][start_date]`,
          add?.start_date?.toLocaleDateString("en-US", {
            year: "numeric",
            month: "2-digit",
          })
        );
        Data.append(
          `submissions[${index}][end_date]`,
          add?.end_date?.toLocaleDateString("en-US", {
            year: "numeric",
            month: "2-digit",
          })
        );
        Data.append(`submissions[${index}][first_name]`, add?.first_name);
        Data.append(`submissions[${index}][last_name]`, add?.last_name);
        Data.append(`submissions[${index}][phone]`, add?.phone);
        Data.append(`submissions[${index}][email]`, add?.email);
        Data.append(
          `submissions[${index}][previous_address]`,
          add.previous_address
        );
        Data.append(`submissions[${index}][moving_reason]`, add?.moving_reason);
        Data.append(`submissions[${index}][rent_option]`, add?.rent_option);
        Data.append(`submissions[${index}][rent_monthly]`, add?.rent_monthly);
      });
    }
    id && Data.append("id", id);

    var config = {
      method: "post",
      url: `${Baseurl.baseurl}real-estate/rent/tenant/store/personal/info`,
      data: Data,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then((response) => {
        console.log(response);
        setLoader(false);
        Swal.fire({
          toast: true,
          icon: "success",
          title: response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        history.push("/rent/tanent/cosigner");
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setBund(error?.response?.data?.errors);
        Swal.fire({
          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };

  const handlePhoneNumberChange = (event, state) => {
    let inputPhoneNumber = event.target.value.replace(/\D/g, ""); // remove non-numeric characters
    if (inputPhoneNumber.length > 10) {
      inputPhoneNumber = inputPhoneNumber.slice(0, 10); // truncate to 10 digits
    }
    let formattedPhoneNumber = "";
    if (inputPhoneNumber.length > 3) {
      formattedPhoneNumber = `(${inputPhoneNumber.substring(0, 3)})`;
      if (inputPhoneNumber.length > 6) {
        formattedPhoneNumber += ` ${inputPhoneNumber.substring(
          3,
          6
        )}-${inputPhoneNumber.substring(6)}`;
      } else {
        formattedPhoneNumber += ` ${inputPhoneNumber.substring(3)}`;
      }
    } else {
      formattedPhoneNumber = inputPhoneNumber;
    }
    state(formattedPhoneNumber);
  };

  const handlePhoneNumberChangeSocial = (event, state) => {
    let inputPhoneNumber = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    if (inputPhoneNumber.length > 9) {
      inputPhoneNumber = inputPhoneNumber.slice(0, 9); // Truncate to 9 digits
    }
    let formattedPhoneNumber = "";
    if (inputPhoneNumber.length > 4) {
      formattedPhoneNumber = `${inputPhoneNumber.substring(
        0,
        3
      )}-${inputPhoneNumber.substring(3, 5)}-${inputPhoneNumber.substring(5)}`;
    } else {
      formattedPhoneNumber = inputPhoneNumber;
    }

    state(formattedPhoneNumber);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    const formattedEmail =
      value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    setEmailID(formattedEmail);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  useEffect(() => {
    const currentDate = new Date();
    setEndDate(currentDate);
  }, []);

  const getDifference = (start, end) => {
    if (start && end) {
      const startYear = start.getFullYear();
      const endYear = end.getFullYear();
      const startMonth = start.getMonth();
      const endMonth = end.getMonth();

      let totalMonths = (endYear - startYear) * 12 + (endMonth - startMonth);
      const years = Math.floor(totalMonths / 12);
      const months = totalMonths % 12;

      return { years, months };
    }
    return { years: undefined, months: undefined };
  };

  const { years, months } = getDifference(startDate, endDate);

  useEffect(() => {
    if (years || months) {
      setTotalYear(years);
      setTotalMonth(months);
    }
  }, [years, months]);

  return (
    <>
      {loader ? <div className="loader"></div> : null}

      <Header />
      <form onSubmit={add}>
        <div className="container-fluid">
          <div className="row">
            <>
              <SidebarDashNew />
            </>

            <div
              className={
                isOpen === true
                  ? "col-md-8 open he mt-5 mb-2 ps-lg-4"
                  : isOpen === false
                  ? "col-md-12 open nhi he mt-5 mb-2 ps-lg-4"
                  : ""
              }
            >
              <div className="row">
                <div className="col-md-12">
                  <h5 className="form-label font-bold text-capitalize">
                    OK! now tell us a little bit about yourself.
                  </h5>
                  <label
                    className="mb-3 p-md-0 form-label font-bold"
                    style={{ textTransform: "none" }}
                  >
                    And for your peace of mind, we'll never sell your info to
                    3rd parties.
                  </label>
                </div>
                <div className="col-md-3 col-sm-12 wd8 position-relative">
                  <div className="form-group-new">
                    <input
                      className="form-control-new text-capitalize rounded-0"
                      // id="inputheight1"
                      type="text"
                      placeholder=" "
                      value={FirstName}
                      required
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                    <label htmlFor="inputheight1" className="label-new">
                      First Name
                    </label>
                  </div>
                  {FirstName?.length > 4 ? (
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        position: "absolute",
                        color: "green",
                        right: "15px",
                        bottom: "10px",
                        zIndex: "123",
                      }}
                    >
                      ✔
                    </p>
                  ) : null}
                  {bund?.first_name
                    ? bund?.first_name?.map((e) => (
                        <p className="text-danger">{e}</p>
                      ))
                    : null}
                </div>
                <div className="col-md-3 col-sm-12 wd8 position-relative">
                  <div className="form-group-new">
                    <input
                      className="form-control-new text-capitalize rounded-0"
                      // id="inputheight1"
                      type="text"
                      placeholder=" "
                      value={Middle}
                      required
                      onChange={(e) => setMiddleName(e.target.value)}
                    />
                    <label htmlFor="inputheight1" className="label-new">
                      Middle Name
                    </label>
                  </div>
                  {Middle?.length > 4 ? (
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        position: "absolute",
                        color: "green",
                        right: "15px",
                        bottom: "10px",
                        zIndex: "123",
                      }}
                    >
                      ✔
                    </p>
                  ) : null}
                  {bund?.middle_name
                    ? bund?.middle_name?.map((e) => (
                        <p className="text-danger">{e}</p>
                      ))
                    : null}
                </div>
                <div
                  className={`${
                    suffix === "Fill in your own" ? "col-md-2" : "col-md-3"
                  } col-sm-12 wd8 position-relative`}
                >
                  <div className="form-group-new">
                    <input
                      className="form-control-new text-capitalize rounded-0"
                      // id="inputheight1"
                      type="text"
                      placeholder=" "
                      value={Last}
                      required
                      onChange={(e) => setLastName(e.target.value)}
                    />
                    <label htmlFor="inputheight1" className="label-new">
                      Last Name
                    </label>
                  </div>
                  {Last?.length > 4 ? (
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        position: "absolute",
                        color: "green",
                        right: "15px",
                        bottom: "10px",
                        zIndex: "123",
                      }}
                    >
                      ✔
                    </p>
                  ) : null}
                  {bund?.last_name
                    ? bund?.last_name?.map((e) => (
                        <p className="text-danger">{e}</p>
                      ))
                    : null}
                </div>
                <div
                  className={`${
                    suffix === "Fill in your own" ? "col-md-2" : "col-md-3"
                  } col-sm-12 wd8 position-relative`}
                >
                  <div className="select-container ms-md-0">
                    <select
                      required
                      name="estimated_credit_score"
                      formcontrolname="creditRange"
                      className={`form-control-select w-100 ${
                        suffix ? "text-success" : "text-dark"
                      }`}
                      value={suffix}
                      onChange={(e) => setSuffix(e.target.value)}
                      onFocus={() =>
                        document
                          ?.querySelector(".label-select")
                          ?.classList?.add("label-top")
                      }
                      onBlur={() =>
                        !suffix &&
                        document
                          ?.querySelector(".label-select")
                          ?.classList?.remove("label-top")
                      }
                    >
                      <option value="" disabled></option>
                      <option value="Jr.">
                        Jr.{" "}
                        {suffix === "Jr." ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value="Sr.">
                        Sr.{" "}
                        {suffix === "Sr." ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value="I">
                        I{" "}
                        {suffix === "I" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value="II">
                        II{" "}
                        {suffix === "II" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value="III">
                        III{" "}
                        {suffix === "III" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value="IV">
                        IV{" "}
                        {suffix === "IV" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value="Esq.">
                        Esq.{" "}
                        {suffix === "Esq." ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value="Ph.D.">
                        Ph.D.{" "}
                        {suffix === "Ph.D." ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value="M.D.">
                        M.D.{" "}
                        {suffix === "M.D." ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value="D.D.S.">
                        D.D.S.{" "}
                        {suffix === "D.D.S." ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value="CPA">
                        CPA{" "}
                        {suffix === "CPA" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value="J.D.">
                        J.D.{" "}
                        {suffix === "J.D." ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value="Ms.">
                        Ms.{" "}
                        {suffix === "Ms." ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value="Mr.">
                        Mr.{" "}
                        {suffix === "Mr." ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value="Mrs.">
                        Mrs.{" "}
                        {suffix === "Mrs." ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value="Miss">
                        Miss{" "}
                        {suffix === "Miss" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value="Rev.">
                        Rev.{" "}
                        {suffix === "Rev." ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value="Rabbi">
                        Rabbi{" "}
                        {suffix === "Rabbi" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value="Imam">
                        Imam{" "}
                        {suffix === "Imam" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value="Ret.">
                        Ret.{" "}
                        {suffix === "Ret." ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value="Hon.">
                        Hon.{" "}
                        {suffix === "Hon." ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value="Fill in your own">
                        Fill in your own{" "}
                        {suffix === "Fill in your own" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                    </select>
                    <label
                      className={`label-new ${suffix ? "label-top" : ""}`}
                      htmlFor="estimated_credit_score"
                    >
                      Surfix
                    </label>
                  </div>
                  {bund?.suffix
                    ? bund?.suffix?.map((e) => (
                        <p className="text-danger">{e}</p>
                      ))
                    : null}
                </div>
                {suffix === "Fill in your own" ? (
                  <div className="col-md-2 wd8 position-relative">
                    <div className="form-group-new">
                      <input
                        className="w-100 form-control-new text-capitalize rounded-0"
                        type="text"
                        placeholder=" "
                        value={suffixField}
                        onChange={(e) => setSuffixField(e.target.value)}
                      />
                      <label
                        htmlFor="inputheight1"
                        className="label-new"
                        style={{ fontSize: "12px" }}
                      >
                        Fill in your own
                      </label>
                    </div>
                    {suffixField?.length > 4 ? (
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          position: "absolute",
                          color: "green",
                          right: "10px",
                          bottom: "10px",
                          zIndex: "123",
                        }}
                      >
                        ✔
                      </p>
                    ) : null}
                    {/* {bund?.first_name
                        ? bund?.first_name?.map((e) => (
                            <p className="text-danger">{e}</p>
                          ))
                        : null} */}
                  </div>
                ) : null}
                <div className="col-md-6">
                  <div className="position-relative">
                    <div className="mt-2 d-flex align-items-baseline">
                      &nbsp; &nbsp;
                      <input
                        type="checkbox"
                        checked={altname}
                        onChange={(e) => setAltname(!altname)}
                        value={altname}
                      />
                      <div className=""></div>
                      &nbsp; &nbsp;
                      <label className="alternate">
                        I have alternate names
                      </label>
                      <br />
                    </div>
                    {altname ? (
                      <div className="form-group-new mt-2">
                        <input
                          className="form-control-new text-capitalize rounded-0"
                          type="text"
                          placeholder=" "
                          value={Alt}
                          onChange={(e) => setAlt(e.target.value)}
                          required={altname ? true : false}
                        />
                        <label htmlFor="inputheight1" className="label-new">
                          Enter Your Alternate Name
                        </label>
                      </div>
                    ) : null}
                    {Alt?.length > 4 && altname ? (
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          position: "absolute",
                          color: "green",
                          left: "90%",
                          bottom: "-6px",
                          zIndex: "123",
                        }}
                      >
                        ✔
                      </p>
                    ) : null}
                    {bund?.have_alternate_names
                      ? bund?.have_alternate_names?.map((e) => (
                          <p className="text-danger">{e}</p>
                        ))
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="position-relative">
                    <div className="mt-2 d-flex align-items-baseline">
                      &nbsp; &nbsp;
                      <input
                        type="checkbox"
                        checked={nickname}
                        value={nickname}
                        onChange={() => setNickname(!nickname)}
                      />
                      &nbsp; &nbsp;
                      <label className="alternate">I have a nickname</label>
                    </div>

                    {nickname ? (
                      <div className="form-group-new mt-2">
                        <input
                          className="form-control-new text-capitalize rounded-0"
                          required={nickname ? true : false}
                          type="text"
                          placeholder=" "
                          value={Nick}
                          onChange={(e) => setNick(e.target.value)}
                        />
                        <label htmlFor="inputheight1" className="label-new">
                          Enter Your Nick Name
                        </label>
                      </div>
                    ) : null}
                    {Nick?.length > 4 && nickname ? (
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          position: "absolute",
                          color: "green",
                          left: "90%",
                          bottom: "-6px",
                          zIndex: "123",
                        }}
                      >
                        ✔
                      </p>
                    ) : null}
                    {bund?.have_nick_name
                      ? bund?.have_nick_name?.map((e) => (
                          <p className="text-danger">{e}</p>
                        ))
                      : null}
                  </div>
                </div>
                <div className="col-md-6 mt-2 position-relative">
                  <h5 className="form-label font-bold text-capitalize">
                    What is your date of birth?
                  </h5>
                  <div className="prefix-suffix width-300">
                    <div className="position-relative">
                      <div className="form-group-new">
                        <input
                          className="form-control-new text-capitalize rounded-0"
                          placeholder=" "
                          type="date"
                          value={dateBirth}
                          onChange={handleDateChangeFour}
                          required
                        />
                        <label htmlFor="inputheight1" className="label-new">
                          Date Of Birth
                        </label>
                      </div>
                    </div>
                    {dateBirth?.length > 4 ? (
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          position: "absolute",
                          color: "green",
                          right: "50px",
                          bottom: "10px",
                          zIndex: "123",
                        }}
                      >
                        ✔
                      </p>
                    ) : null}
                    {bund?.landlord_date
                      ? bund?.landlord_date?.map((e) => (
                          <p className="text-danger">{e}</p>
                        ))
                      : null}
                  </div>
                </div>
                <div className="col-md-6 mt-5 position-relative">
                  <div className="prefix-suffix width-300">
                    <div className="position-relative">
                      <div className="form-group-new">
                        <input
                          className="form-control-new text-capitalize rounded-0"
                          placeholder=" "
                          type="number"
                          value={autoAge && autoAge}
                          disabled
                        />
                        <label htmlFor="inputheight1" className="label-new">
                          Age
                        </label>
                      </div>
                    </div>
                    {autoAge?.length > 4 ? (
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          position: "absolute",
                          color: "green",
                          right: "50px",
                          bottom: "10px",
                          zIndex: "123",
                        }}
                      >
                        ✔
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group ng-star-inserted pl-md-3 w-100">
                    <h5 className="form-label font-bold text-capitalize">
                      What is your Social Security Number?
                    </h5>
                    <div className="prefix-suffix width-400 position-relative">
                      <div className="form-group-new">
                        <input
                          className="form-control-new text-capitalize rounded-0"
                          // id="inputheight1"
                          type="text"
                          placeholder=" "
                          value={Ssn}
                          required
                          onChange={(e) =>
                            handlePhoneNumberChangeSocial(e, setSsn)
                          }
                        />
                        <label htmlFor="inputheight1" className="label-new">
                          Social Security Number?
                        </label>
                      </div>
                      {Ssn?.length > 4 ? (
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            position: "absolute",
                            color: "green",
                            right: "16px",
                            bottom: "-8px",
                            zIndex: "123",
                          }}
                        >
                          ✔
                        </p>
                      ) : null}
                    </div>
                  </div>
                  {bund?.ssn
                    ? bund?.ssn.map((e) => <p className="text-danger">{e}</p>)
                    : null}
                  <div className="alternate-name-wrapper mt-gutter width-400 ng-star-inserted">
                    <p className="small-text pl-md-3">
                      <FaLock className="text-success7" />
                      Your application and all personal information are safely
                      stored on secured encrypted servers
                    </p>
                  </div>
                </div>
                <div className="col-md-12 position-relative">
                  <h5 className="form-label font-bold text-capitalize">
                    What is your estimated credit score?
                  </h5>
                  <div className="select-container">
                    <select
                      required
                      name="estimated_credit_score"
                      formcontrolname="creditRange"
                      className={`form-control-select w-100 ${
                        CreditScore ? "text-success" : "text-dark"
                      }`}
                      value={CreditScore}
                      onChange={(e) => setCreditScore(e.target.value)}
                      onFocus={() =>
                        document
                          ?.querySelector(".label-select")
                          ?.classList?.add("label-top")
                      }
                      onBlur={() =>
                        !CreditScore &&
                        document
                          ?.querySelector(".label-select")
                          ?.classList?.remove("label-top")
                      }
                    >
                      <option value="" disabled></option>
                      <option value="780+" className="ng-star-inserted">
                        780+{" "}
                        {CreditScore === "780+" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value="740 - 779" className="ng-star-inserted">
                        740 - 779{" "}
                        {CreditScore === "740 - 779" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value="700 - 739" className="ng-star-inserted">
                        700 - 739{" "}
                        {CreditScore === "700 - 739" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value="660 - 699" className="ng-star-inserted">
                        660 - 699{" "}
                        {CreditScore === "660 - 699" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value="620 - 659" className="ng-star-inserted">
                        620 - 659{" "}
                        {CreditScore === "620 - 659" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value="Below 620" className="ng-star-inserted">
                        Below 620{" "}
                        {CreditScore === "Below 620" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                    </select>
                    <label
                      className={`label-new ${CreditScore ? "label-top" : ""}`}
                      htmlFor="estimated_credit_score"
                    >
                      Select Credit Score
                    </label>
                  </div>
                  {bund?.estimated_credit_score
                    ? bund?.estimated_credit_score.map((e) => (
                        <p className="text-danger">{e}</p>
                      ))
                    : null}
                </div>
                <div className="col-md-12 position-relative mt-4">
                  <h5 className="form-label font-bold text-capitalize">
                    Please fill your contact information
                  </h5>
                </div>
                <div className="w-50 col-md-6 position-relative">
                  <div className="form-group-new">
                    <input
                      className="form-control-new rounded-0"
                      // id="inputheight1"
                      type="email"
                      placeholder=" "
                      value={EmailID}
                      required
                      onChange={(e) => handleEmailChange(e)}
                    />
                    <label htmlFor="inputheight1" className="label-new">
                      Email ID
                    </label>
                  </div>
                  {EmailID?.length > 16 ? (
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        position: "absolute",
                        color: "green",
                        right: "24px",
                        top: "14px",
                        zIndex: "123",
                      }}
                    >
                      ✔
                    </p>
                  ) : null}
                  {bund?.email
                    ? bund?.email.map((e) => <p className="text-danger">{e}</p>)
                    : null}
                </div>
                <div className="w-50 col-md-6 position-relative">
                  <div className="form-group-new">
                    <input
                      className="form-control-new rounded-0 text-capitalize"
                      // id="inputheight1"
                      type="text"
                      placeholder=" "
                      value={Phone}
                      required
                      onChange={(e) => handlePhoneNumberChange(e, setPhone)}
                    />
                    <label htmlFor="inputheight1" className="label-new">
                      Phone
                    </label>
                  </div>
                  {Phone?.length > 4 ? (
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        position: "absolute",
                        color: "green",
                        right: "24px",
                        top: "16px",
                        zIndex: "123",
                      }}
                    >
                      ✔
                    </p>
                  ) : null}
                  {bund?.cell_phone
                    ? bund?.cell_phone?.map((e) => (
                        <p className="text-danger">{e}</p>
                      ))
                    : null}
                </div>
                <div className="mt-2 w-50 col-md-6 d-flex position-relative">
                  <div className="form-group-new w-100">
                    <input
                      className="form-control-new rounded-0 text-capitalize"
                      // id="inputheight1"
                      type="text"
                      placeholder=" "
                      value={WorkPhone}
                      required
                      onChange={(e) => handlePhoneNumberChange(e, setWorkPhone)}
                    />
                    <label htmlFor="inputheight1" className="label-new">
                      Work Phone
                    </label>
                  </div>
                  {WorkPhone?.length > 4 ? (
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        position: "absolute",
                        color: "green",
                        top: "16%",
                        left: "44%",
                        zIndex: "123",
                      }}
                    >
                      ✔
                    </p>
                  ) : null}
                  {bund?.work_phone
                    ? bund?.work_phone?.map((e) => (
                        <p className="text-danger">{e}</p>
                      ))
                    : null}
                  <div className="form-group-new w-100">
                    <input
                      className="form-control-new rounded-0 text-capitalize"
                      required
                      type="number"
                      placeholder=" "
                      value={Ext}
                      onChange={(e) => setExt(e.target.value)}
                    />
                    <label htmlFor="inputheight1" className="label-new">
                      Optional Ext Phone
                    </label>
                  </div>
                  {Ext?.length > 4 ? (
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        position: "absolute",
                        color: "green",
                        top: "16%",
                        right: "22px",
                        zIndex: "123",
                      }}
                    >
                      ✔
                    </p>
                  ) : null}
                  {bund?.ext
                    ? bund?.ext?.map((e) => <p className="text-danger">{e}</p>)
                    : null}
                </div>
                <div className="mt-2 w-50 col-md-6 position-relative">
                  <div className="form-group-new">
                    <input
                      className="form-control-new rounded-0 text-capitalize"
                      // id="inputheight1"
                      type="tel"
                      placeholder=" "
                      value={HomePhone}
                      required
                      onChange={(e) => handlePhoneNumberChange(e, setHomePhone)}
                    />
                    <label htmlFor="inputheight1" className="label-new">
                      Home Phone
                    </label>
                  </div>
                  {HomePhone?.length > 4 ? (
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        position: "absolute",
                        color: "green",
                        right: "24px",
                        bottom: "10px",
                        zIndex: "123",
                      }}
                    >
                      ✔
                    </p>
                  ) : null}
                  {bund?.home_phone
                    ? bund?.home_phone?.map((e) => (
                        <p className="text-danger">{e}</p>
                      ))
                    : null}
                </div>

                <div className="col-md-12">
                  <div
                    className="font-xs text-dark font-medium mt-gutter-half clrh"
                    style={{
                      color: "#49545c",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    I confirm that I have read and agree to the{" "}
                    <a href="javascript:void(0);" class="">
                      {" "}
                      Consent{" "}
                    </a>{" "}
                    to contact <input type="checkbox" checked />
                  </div>
                </div>

                <div className="col-md-12 mt-4">
                  <div className="d-flex">
                    <div>
                      <h5 className="form-label font-bold text-capitalize">
                        Where do you live currently?
                      </h5>
                    </div>
                    <div className="ms-4 d-flex align-items-baseline mgn font-sm1 ">
                      <input
                        type="checkbox"
                        checked={mailing}
                        onChange={() => setMailing(!mailing)}
                      />
                      {bund?.mailing_address
                        ? bund?.mailing_address?.map((e) => (
                            <p className="text-danger">{e}</p>
                          ))
                        : null}
                      &nbsp; &nbsp;
                      <label className="label font-sm  font-sm1  clr ">
                        My mailing address is the same as my current address.
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <GoogleSerach
                    setAddressCurrent={setAddressCurrent}
                    addressCurrent={addressCurrent}
                    googleData={googleData}
                    setGoogleData={setGoogleData}
                  />
                </div>
                <div className="col-md-6 my-2 position-relative">
                  <div className="form-group-new">
                    <input
                      className="form-control-new rounded-0 text-capitalize"
                      // id="inputheight1"
                      type="text"
                      placeholder=" "
                      value={
                        googleData?.street_number
                          ? googleData?.street_number
                          : "Street Number"
                      }
                      required
                    />
                    <label htmlFor="inputheight1" className="label-new">
                      Street Number
                    </label>
                  </div>
                  {googleData?.street_number?.length >= 2 ? (
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        position: "absolute",
                        color: "green",
                        right: "24px",
                        bottom: "10px",
                        zIndex: "123",
                      }}
                    >
                      ✔
                    </p>
                  ) : null}
                  {bund?.have_mailing_street_type
                    ? bund?.have_mailing_street_type?.map((e) => (
                        <p className="text-danger">{e}</p>
                      ))
                    : null}
                </div>
                <div className="col-md-6 my-2 position-relative">
                  <div className="form-group-new">
                    <input
                      className="form-control-new rounded-0 text-capitalize"
                      // id="inputheight1"
                      type="text"
                      placeholder=" "
                      value={
                        googleData?.street_name
                          ? googleData?.street_name
                          : "Street"
                      }
                      required
                    />
                    <label htmlFor="inputheight1" className="label-new">
                      Street Name
                    </label>
                  </div>
                  {googleData?.street_name?.length >= 2 ? (
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        position: "absolute",
                        color: "green",
                        right: "24px",
                        bottom: "10px",
                        zIndex: "123",
                      }}
                    >
                      ✔
                    </p>
                  ) : null}
                  {bund?.have_mailing_street
                    ? bund?.have_mailing_street?.map((e) => (
                        <p className="text-danger">{e}</p>
                      ))
                    : null}
                </div>

                <div className="col-md-6 my-2 position-relative">
                  <div className="form-group-new">
                    <input
                      className="form-control-new rounded-0 text-capitalize"
                      // id="inputheight1"
                      type="text"
                      placeholder=" "
                      value={googleData?.state ? googleData?.state : "State"}
                      required
                    />
                    <label htmlFor="inputheight1" className="label-new">
                      State
                    </label>
                  </div>
                  {googleData?.state?.length >= 2 ? (
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        position: "absolute",
                        color: "green",
                        right: "24px",
                        bottom: "10px",
                        zIndex: "123",
                      }}
                    >
                      ✔
                    </p>
                  ) : null}
                  {bund?.have_mailing_state
                    ? bund?.have_mailing_state?.map((e) => (
                        <p className="text-danger">{e}</p>
                      ))
                    : null}
                </div>
                <div className="col-md-6 my-2 position-relative">
                  <div className="form-group-new">
                    <input
                      className="form-control-new rounded-0 text-capitalize"
                      // id="inputheight1"
                      type="text"
                      placeholder=" "
                      value={googleData?.city ? googleData?.city : "City"}
                      required
                    />
                    <label htmlFor="inputheight1" className="label-new">
                      City
                    </label>
                  </div>
                  {googleData?.city?.length >= 2 ? (
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        position: "absolute",
                        color: "green",
                        right: "24px",
                        bottom: "10px",
                        zIndex: "123",
                      }}
                    >
                      ✔
                    </p>
                  ) : null}
                  {bund?.have_mailing_city
                    ? bund?.have_mailing_city?.map((e) => (
                        <p className="text-danger">{e}</p>
                      ))
                    : null}
                </div>
                <div className="col-md-6 my-2 position-relative">
                  <div className="form-group-new">
                    <input
                      className="form-control-new rounded-0 text-capitalize"
                      // id="inputheight1"
                      type="text"
                      placeholder=" "
                      value={
                        googleData?.apt_unit ? googleData?.apt_unit : "Apt/unit"
                      }
                    />
                    <label htmlFor="inputheight1" className="label-new">
                      Apt/unit
                    </label>
                  </div>
                  {googleData?.apt_unit?.length >= 2 ? (
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        position: "absolute",
                        color: "green",
                        right: "24px",
                        bottom: "10px",
                        zIndex: "123",
                      }}
                    >
                      ✔
                    </p>
                  ) : null}
                  {bund?.have_mailing_unit
                    ? bund?.have_mailing_unit?.map((e) => (
                        <p className="text-danger">{e}</p>
                      ))
                    : null}
                </div>
                <div className="col-md-6 my-2 position-relative">
                  <div className="form-group-new">
                    <input
                      className="form-control-new rounded-0 text-capitalize"
                      // id="inputheight1"
                      type="text"
                      placeholder=" "
                      value={
                        googleData?.postal_code
                          ? googleData?.postal_code
                          : "Zip Code"
                      }
                      required
                    />
                    <label htmlFor="inputheight1" className="label-new">
                      Zip Code
                    </label>
                  </div>
                  {googleData?.postal_code?.length >= 2 ? (
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        position: "absolute",
                        color: "green",
                        right: "24px",
                        bottom: "10px",
                        zIndex: "123",
                      }}
                    >
                      ✔
                    </p>
                  ) : null}
                  {bund?.have_mailing_zipcode
                    ? bund?.have_mailing_zipcode?.map((e) => (
                        <p className="text-danger">{e}</p>
                      ))
                    : null}
                </div>
                <div className="col-md-2">
                  <div className="position-relative ms-1">
                    <label
                      className="label font-sm font-sm1 clr"
                      style={{ marginTop: "14px" }}
                    >
                      From This Date.
                      <br />
                    </label>
                    <div>
                      <DatePicker
                        selected={startDate}
                        dateFormat="MM/yyyy"
                        required
                        showMonthYearPicker
                        className="form-control w-100 h-48"
                        showFullMonthYearPicker
                        onChange={handleStartDateChange}
                      />
                    </div>
                    {startDate &&
                      startDate?.toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "2-digit",
                      }).length > 4 && (
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            position: "absolute",
                            color: "green",
                            right: "8%",
                            bottom: "0px",
                            zIndex: "123",
                          }}
                        >
                          ✔
                        </p>
                      )}
                    {/* {error?.new_start_date
                          ? error?.new_start_date?.map((e) => (
                              <p className="text-danger">{e}</p>
                            ))
                          : null} */}
                  </div>
                </div>
                <div className="col-md-2 px-2">
                  <div className="position-relative ms-1">
                    <label
                      className="label font-sm font-sm1 clr"
                      style={{ marginTop: "14px" }}
                    >
                      To This Date.
                      <br />
                    </label>
                    <div>
                      <DatePicker
                        selected={endDate}
                        dateFormat="MM/yyyy"
                        required
                        showMonthYearPicker
                        showFullMonthYearPicker
                        className="form-control w-100 h-48"
                        onChange={handleEndDateChange}
                      />
                    </div>
                    {endDate &&
                      endDate?.toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "2-digit",
                      }).length > 4 && (
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            position: "absolute",
                            color: "green",
                            right: "24px",
                            bottom: "0px",
                            zIndex: "123",
                          }}
                        >
                          ✔
                        </p>
                      )}
                    {/* {error?.new_end_date
                          ? error?.new_end_date?.map((e) => (
                              <p className="text-danger">{e}</p>
                            ))
                          : null} */}
                  </div>
                </div>
                <div
                  className="col-md-2 position-relative"
                  style={{ marginTop: "28px" }}
                >
                  <div className="form-group-new">
                    <input
                      className="form-control-new rounded-0 text-capitalize"
                      // id="inputheight1"
                      type="text"
                      placeholder=" "
                      value={totalYear}
                      disabled
                    />
                    <label
                      htmlFor="inputheight1"
                      className="label-new"
                      style={{ fontSize: "12px" }}
                    >
                      Total Year
                    </label>
                  </div>
                </div>
                <div
                  className="col-md-2 position-relative"
                  style={{ marginTop: "28px" }}
                >
                  <div className="form-group-new">
                    <input
                      className="form-control-new rounded-0 text-capitalize"
                      // id="inputheight1"
                      type="text"
                      placeholder=" "
                      value={totalMonth}
                      disabled
                    />
                    <label
                      htmlFor="inputheight1"
                      className="label-new"
                      style={{ fontSize: "12px" }}
                    >
                      Total Month
                    </label>
                  </div>
                </div>
                <div
                  className="col-md-2 position-relative"
                  style={{ marginTop: "28px" }}
                >
                  <div className="select-container">
                    <select
                      required
                      name="estimated_credit_score"
                      formcontrolname="creditRange"
                      className={`form-control-select w-100 ${
                        rentOwnFree ? "text-success" : "text-dark"
                      }`}
                      value={rentOwnFree}
                      onChange={(e) => setRentOwnFree(e.target.value)}
                      onFocus={() =>
                        document
                          ?.querySelector(".label-select")
                          ?.classList?.add("label-top")
                      }
                      onBlur={() =>
                        !rentOwnFree &&
                        document
                          ?.querySelector(".label-select")
                          ?.classList?.remove("label-top")
                      }
                    >
                      <option value="" disabled></option>
                      <option value="Rent">
                        Rent{" "}
                        {rentOwnFree === "Rent" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value="Own">
                        Own{" "}
                        {rentOwnFree === "Own" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value="Rent Free">
                        Rent Free{" "}
                        {rentOwnFree === "Rent Free" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                    </select>
                    <label
                      className={`label-new ${rentOwnFree ? "label-top" : ""}`}
                      htmlFor="estimated_credit_score"
                      style={{ fontSize: "12px" }}
                    >
                      Housing
                    </label>
                  </div>
                </div>
                <div
                  className="col-md-2 position-relative"
                  style={{ marginTop: "28px" }}
                >
                  <div className="form-group-new">
                    <input
                      className="form-control-new rounded-0 text-capitalize w-100"
                      // id="inputheight1"
                      type="text"
                      placeholder=" "
                      value={rentOwnFree === "Rent Free" ? 0 : howMuchMonthly}
                      onChange={(e) => setHowMuchMonthly(e.target.value)}
                      disabled={rentOwnFree === "Rent Free"}
                      required
                    />
                    <label
                      htmlFor="inputheight1"
                      className="label-new"
                      style={{ fontSize: "10px" }}
                    >
                      How Much Monthly
                    </label>
                  </div>
                  {howMuchMonthly?.length > 4 ? (
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        position: "absolute",
                        color: "green",
                        right: "24px",
                        bottom: "10px",
                        zIndex: "123",
                      }}
                    >
                      ✔
                    </p>
                  ) : null}
                </div>

                <div className="col-md-12">
                  <h5 className="form-label font-bold text-capitalize">
                    Landlord Contact Info
                  </h5>
                  <p className="h6">
                    Is landlord a company or an individual. To whom you send
                    your cheques to?
                  </p>
                  <span className="mt-3">
                    <input
                      type="radio"
                      name="company"
                      checked={Company}
                      onClick={() => setCompany(!Company)}
                    />
                    &nbsp; Company
                  </span>
                  <span className="ms-5">
                    <input
                      type="radio"
                      name="company"
                      onClick={() => setCompany(false)}
                    />
                    &nbsp; Indiviual
                  </span>
                </div>
                {Company ? (
                  <div className="col-md-12 position-relative">
                    <div className="form-group-new mt-2">
                      <input
                        className="form-control-new rounded-0 text-capitalize"
                        // id="inputheight1"
                        type="text"
                        placeholder=" "
                        value={CompanyValue}
                        onChange={(e) => setCompanyValue(e.target.value)}
                      />
                      <label htmlFor="inputheight1" className="label-new">
                        Company Name
                      </label>
                    </div>

                    {CompanyValue?.length > 4 ? (
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          position: "absolute",
                          color: "green",
                          right: "28px",
                          bottom: "10px",
                          zIndex: "123",
                        }}
                      >
                        ✔
                      </p>
                    ) : null}
                    {/* {bund?.landlord_first_name
                            ? bund?.landlord_first_name?.map((e) => (
                                <p className="text-danger">{e}</p>
                              ))
                            : null} */}
                  </div>
                ) : null}
                <div className="col-md-6 position-relative mt-2">
                  <div className="form-group-new">
                    <input
                      className="form-control-new rounded-0 text-capitalize"
                      required
                      type="text"
                      placeholder=" "
                      value={LandlordFirstName}
                      onChange={(e) => setLandlordFirstName(e.target.value)}
                    />
                    <label htmlFor="inputheight1" className="label-new">
                      First Name
                    </label>
                  </div>

                  {LandlordFirstName?.length > 4 ? (
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        position: "absolute",
                        color: "green",
                        right: "24px",
                        bottom: "10px",
                        zIndex: "123",
                      }}
                    >
                      ✔
                    </p>
                  ) : null}
                  {bund?.landlord_first_name
                    ? bund?.landlord_first_name?.map((e) => (
                        <p className="text-danger">{e}</p>
                      ))
                    : null}
                </div>
                <div className="col-md-6 position-relative  mt-2">
                  <div className="form-group-new">
                    <input
                      className="form-control-new rounded-0 text-capitalize"
                      required
                      type="text"
                      placeholder=" "
                      value={LandlordLastName}
                      onChange={(e) => setLandlordLastName(e.target.value)}
                    />
                    <label htmlFor="inputheight1" className="label-new">
                      Last Name
                    </label>
                  </div>
                  {LandlordLastName?.length > 4 ? (
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        position: "absolute",
                        color: "green",
                        right: "24px",
                        bottom: "10px",
                        zIndex: "123",
                      }}
                    >
                      ✔
                    </p>
                  ) : null}
                  {bund?.landlord_last_name
                    ? bund?.landlord_last_name?.map((e) => (
                        <p className="text-danger">{e}</p>
                      ))
                    : null}
                </div>
                <div className="col-md-6 position-relative mt-2">
                  <div className="form-group-new">
                    <input
                      className="form-control-new rounded-0 text-capitalize"
                      required
                      type="text"
                      placeholder=" "
                      value={LandlordPhone}
                      onChange={(e) =>
                        handlePhoneNumberChange(e, setLandlordPhone)
                      }
                    />
                    <label htmlFor="inputheight1" className="label-new">
                      Phone Number
                    </label>
                  </div>
                  {LandlordPhone?.length > 4 ? (
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        position: "absolute",
                        color: "green",
                        right: "24px",
                        bottom: "10px",
                        zIndex: "123",
                      }}
                    >
                      ✔
                    </p>
                  ) : null}
                  {bund?.landlord_phone_no
                    ? bund?.landlord_phone_no?.map((e) => (
                        <p className="text-danger">{e}</p>
                      ))
                    : null}
                </div>
                <div className="col-md-6 position-relative mt-2">
                  <div className="form-group-new">
                    <input
                      className="form-control-new rounded-0"
                      required
                      type="email"
                      placeholder=" "
                      value={
                        LandlordEmail?.charAt(0).toUpperCase() +
                        LandlordEmail?.slice(1).toLowerCase()
                          ? LandlordEmail?.charAt(0).toUpperCase() +
                            LandlordEmail?.slice(1).toLowerCase()
                          : ""
                      }
                      onChange={(e) => setLandlordEmail(e.target.value)}
                    />
                    <label htmlFor="inputheight1" className="label-new">
                      Email
                    </label>
                  </div>
                  {LandlordEmail?.length > 4 ? (
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        position: "absolute",
                        color: "green",
                        right: "24px",
                        bottom: "18px",
                        zIndex: "123",
                      }}
                    >
                      ✔
                    </p>
                  ) : null}
                  {bund?.land_lord_email
                    ? bund?.land_lord_email?.map((e) => (
                        <p className="text-danger">{e}</p>
                      ))
                    : null}
                </div>
                {months >= 0 && years >= 0 && years < 5 ? (
                  <GoogleSerachThree
                    multipleAddressData={multipleAddressData}
                    setMultipleAddressData={setMultipleAddressData}
                    years={years}
                    months={months}
                    startDate={startDate}
                  />
                ) : null}

                {mailing && (
                  <>
                    <h5 className="form-label font-bold text-capitalize">
                      Mailing Address - if different from Current Address
                    </h5>
                    <GoogleSearchTwo
                      setaddressMailing={setaddressMailing}
                      addressMailing={addressMailing}
                      mailing={mailing}
                    />
                  </>
                )}
                {bund?.address2
                  ? bund?.address2?.map((e) => (
                      <p className="text-danger">{e}</p>
                    ))
                  : null}

                <div className="col-md-12">
                  <h5 className="form-label font-bold text-capitalize">
                    Emergency Contact
                  </h5>
                </div>
                <div className="col-md-6 mt-2 position-relative">
                  <div className="form-group-new">
                    <input
                      className="form-control-new rounded-0 text-capitalize"
                      required
                      type="text"
                      placeholder=" "
                      value={EmergencyName}
                      onChange={(e) => setEmergencyName(e.target.value)}
                    />
                    <label htmlFor="inputheight1" className="label-new">
                      Full Name
                    </label>
                  </div>
                  {EmergencyName?.length > 4 ? (
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        position: "absolute",
                        color: "green",
                        right: "24px",
                        bottom: "10px",
                        zIndex: "123",
                      }}
                    >
                      ✔
                    </p>
                  ) : null}
                  {bund?.em_first_name
                    ? bund?.em_first_name?.map((e) => (
                        <p className="text-danger">{e}</p>
                      ))
                    : null}
                </div>
                <div className="col-md-6 mt-2 position-relative">
                  <div className="form-group-new">
                    <input
                      className="form-control-new rounded-0 text-capitalize"
                      required
                      type="text"
                      placeholder=" "
                      value={EmergencyRelation}
                      onChange={(e) => setEmergencyRelation(e.target.value)}
                    />
                    <label htmlFor="inputheight1" className="label-new">
                      Relationship
                    </label>
                  </div>
                  {EmergencyRelation?.length > 4 ? (
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        position: "absolute",
                        color: "green",
                        right: "24px",
                        bottom: "10px",
                        zIndex: "123",
                      }}
                    >
                      ✔
                    </p>
                  ) : null}
                  {bund?.em_relation
                    ? bund?.em_relation?.map((e) => (
                        <p className="text-danger">{e}</p>
                      ))
                    : null}
                </div>
                <div className="col-md-6 mt-2 position-relative">
                  <div className="form-group-new">
                    <input
                      className="form-control-new rounded-0 text-capitalize"
                      required
                      type="text"
                      placeholder=" "
                      value={EmergencyPhone}
                      onChange={(e) =>
                        handlePhoneNumberChange(e, setEmergencyPhone)
                      }
                    />
                    <label htmlFor="inputheight1" className="label-new">
                      Phone Number
                    </label>
                  </div>
                  {EmergencyPhone?.length > 4 ? (
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        position: "absolute",
                        color: "green",
                        right: "24px",
                        bottom: "10px",
                        zIndex: "123",
                      }}
                    >
                      ✔
                    </p>
                  ) : null}
                  {bund?.em_phone_no
                    ? bund?.em_phone_no?.map((e) => (
                        <p className="text-danger">{e}</p>
                      ))
                    : null}
                </div>
                <div className="col-md-6 mt-2 position-relative">
                  <EmergencyGoogleAddress
                    setEmergancyContactAddress={setEmergancyContactAddress}
                    emergancyContactAddress={emergancyContactAddress}
                  />
                </div>

                <div className="mt-4">
                  <label className="text-scolor fontsbtn text-center">
                    Next is{" "}
                    <span className="fontsbtncobor">
                      Additional Information
                    </span>
                  </label>
                  <br />
                  <button type="submit" className="btnn1 btnn-primary7 btn-lg">
                    Save & Continue &nbsp;
                    <AiOutlineArrowRight />
                  </button>
                </div>

                {/*  */}
              </div>
              <div className="footerimage">
                <img
                  src="https://bankerbroker.developer-oa.xyz/assets/img/footercity.svg"
                  width="100%"
                  alt=""
                />
              </div>
            </div>
            <ProfileInfo />
          </div>
        </div>
      </form>
    </>
  );
};

export default TanPersonalInfo;
