import React, { useState } from "react";

export const NumberShares = ({ setFinalInstitute, value, fieldsError }) => {
  const { numberOfShares } = value;

  const [editing, steediting] = useState(true);
  function handleChange(event, state, type) {
    console.log(event.target.value, "event.target.value");

    if (type === "finalInstitute") {
      steediting(false);
    } else {
      steediting(false);
    }
    const inputValue = event.target.value;

    const numericValue = inputValue.replace(/[^0-9]/g, "");

    const formattedValue = Number(numericValue).toLocaleString();
    console.log(formattedValue, "formattedValue");
    state({ ...value, [type]: formattedValue });
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col position-relative">
            <div className="mt-3">
              <h5 className="textdark">Number of shares</h5>
              <input
                type="text"
                className="form-control text-capitalize "
                placeholder="Required"
                onChange={(e) => {
                  setFinalInstitute((perv) => ({
                    ...perv,
                    numberOfShares: e.target.value,
                  }));
                  handleChange(e, setFinalInstitute, "numberOfShares");
                }}
                onBlur={() => steediting(true)}
                onFocus={() => steediting(false)}
                value={`${numberOfShares === undefined ? 0 : numberOfShares}${
                  editing === true ? ".00" : ""
                }`}
              />
              {numberOfShares.length < 3 ||
              numberOfShares === undefined ||
              numberOfShares === null ? null : (
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    position: "absolute",
                    right: "3%",
                    top: "55px",
                    color: "green",
                    zIndex: "123",
                  }}
                >
                  ✔
                </p>
              )}
            </div>
          </div>
          {fieldsError?.no_of_shares
            ? fieldsError?.no_of_shares.map((e) => (
                <p className="text-danger col-lg-12">{e}</p>
              ))
            : null}
        </div>
      </div>
    </>
  );
};

export default NumberShares;


