/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import GoogleAddressIncome from "./GoogleAddressIncome";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const EmployeeNewForm = ({
  setEmployerInformation,
  employerInformation,
  optionIncome,
  setOptionIncome,
}) => {
  const [totalyears, setTotalyears] = useState("");

  const formatIncome = (input) => {
    const numericValue = input.replace(/[^0-9]/g, "");
    return Number(numericValue).toLocaleString();
  };

  const formatPhoneNumber = (input) => {
    let inputPhoneNumber = input.replace(/\D/g, "");
    if (inputPhoneNumber.length > 10) {
      inputPhoneNumber = inputPhoneNumber.slice(0, 10);
    }
    let formattedPhoneNumber = "";
    if (inputPhoneNumber.length > 3) {
      formattedPhoneNumber = `(${inputPhoneNumber.substring(0, 3)})`;
      if (inputPhoneNumber.length > 6) {
        formattedPhoneNumber += ` ${inputPhoneNumber.substring(
          3,
          6
        )}-${inputPhoneNumber.substring(6)}`;
      } else {
        formattedPhoneNumber += ` ${inputPhoneNumber.substring(3)}`;
      }
    } else {
      formattedPhoneNumber = inputPhoneNumber;
    }
    return formattedPhoneNumber;
  };

  const handleInputChange = (value, index, field) => {
    if (field === "employer_phone") {
      value = formatPhoneNumber(value);
    } else if (field === "total_monthly_income") {
      value = formatIncome(value);
    }
    const newAddresses = [...employerInformation];
    newAddresses[index][field] = value;
    setEmployerInformation(newAddresses);
  };

  const handleAddressChange = (index, updatedAddress) => {
    const newAddresses = [...employerInformation];
    newAddresses[index].multipleAddressData = updatedAddress;
    setEmployerInformation(newAddresses);
  };

  const handleStreetAddressChange = (index, updatedStreetAddress) => {
    const newAddresses = [...employerInformation];
    newAddresses[index].street_address = updatedStreetAddress;
    setEmployerInformation(newAddresses);
  };

  const handleCurrentAddressChange = (index, updatedCurrentAddress) => {
    const newAddresses = [...employerInformation];
    newAddresses[index].street_address = updatedCurrentAddress;
    setEmployerInformation(newAddresses);
  };

  const calculateTotalYears = () => {
    return employerInformation.reduce((total, employee) => {
      const years = parseInt(employee?.total_year || 0, 10);
      const months = parseInt(employee?.total_month || 0, 10);
      return total + years + months / 12;
    }, 0);
  };

  const handleStartDateChange = (date, index) => {
    const newAddresses = [...employerInformation];
    newAddresses[index].start_date = date;
    newAddresses[index].startDateDisabled = true;
    setEmployerInformation(newAddresses);
    calculateTotalDuration(index, date, newAddresses[index].end_date);
    const totalYears = calculateTotalYears();
    if (totalYears < 2 && employerInformation?.[0]?.end_date) {
      setEmployerInformation([
        ...employerInformation,
        {
          source_of_income: false,
          employer_name: "",
          employer_phone: "",
          supervisor_name: "",
          supervisor_email: "",
          street_address: "",
          multipleAddressData: {
            city: "",
            state: "",
            postal_code: "",
            apt_unit: "",
          },
          job_title: "",
          start_date: null,
          end_date:
            employerInformation?.[employerInformation?.length - 1]?.start_date,
          startDateDisabled: false,
          endDateDisabled: false,
          total_year: "",
          total_month: "",
          ownership_interest: false,
          family_employed: false,
          dd214: "",
          total_monthly_income: "",
          ownership_share: "",
          optional_income: false,
          month_in_profession: "",
          year_in_profession: "",
        },
      ]);
    }
  };

  const handleEndDateChange = (date, index) => {
    const newAddresses = [...employerInformation];
    newAddresses[index].end_date = date;
    newAddresses[index].endDateDisabled = true;
    setEmployerInformation(newAddresses);
    calculateTotalDuration(index, newAddresses[index].start_date, date);
    const totalYears = calculateTotalYears();
    setTotalyears(totalYears);
    if (totalYears < 2 && employerInformation?.[0]?.end_date) {
      setEmployerInformation([
        ...employerInformation,
        {
          source_of_income: false,
          employer_name: "",
          employer_phone: "",
          supervisor_name: "",
          supervisor_email: "",
          street_address: "",
          multipleAddressData: {
            city: "",
            state: "",
            postal_code: "",
            apt_unit: "",
          },
          job_title: "",
          start_date: null,
          end_date:
            employerInformation?.[employerInformation?.length - 1]?.start_date,
          startDateDisabled: false,
          endDateDisabled: false,
          total_year: "",
          total_month: "",
          ownership_interest: false,
          family_employed: false,
          dd214: "",
          total_monthly_income: "",
          ownership_share: "",
          optional_income: false,
          month_in_profession: "",
          year_in_profession: "",
        },
      ]);
    }
  };

  const calculateTotalDuration = (index) => {
    const startDate = employerInformation[index].start_date;
    const endDate = employerInformation[index].end_date;
    if (startDate && endDate) {
      const years = endDate.getFullYear() - startDate.getFullYear();
      const months = endDate.getMonth() - startDate.getMonth();
      const totalMonths = years * 12 + months;
      const totalYears = totalMonths / 12;
      setTotalyears(Math.floor(totalYears));
      if (Math.floor(totalYears) < 2) {
        setEmployerInformation([
          ...employerInformation,
          {
            source_of_income: false,
            // military: false,
            // branch: "",
            // status: "",
            // pcs_status: "",
            // coe: "",
            employer_name: "",
            employer_phone: "",
            supervisor_name: "",
            supervisor_email: "",
            street_address: "",
            multipleAddressData: {
              city: "",
              state: "",
              postal_code: "",
              apt_unit: "",
            },
            job_title: "",
            start_date: null,
            end_date: null,
            startDateDisabled: false,
            endDateDisabled: false,
            total_year: "",
            total_month: "",
            ownership_interest: false,
            family_employed: false,
            dd214: "",
            total_monthly_income: "",
            ownership_share: "",
            optional_income: false,
            month_in_profession: "",
            year_in_profession: "",
          },
        ]);
      }
      const newAddresses = [...employerInformation];
      newAddresses[index].total_year = Math.floor(totalYears);
      newAddresses[index].total_month = totalMonths % 12;
      setEmployerInformation(newAddresses);
    }
  };

  useEffect(() => {
    const totalYears = calculateTotalYears();
    setTotalyears(totalYears);
  }, [totalyears, calculateTotalYears]);

  return (
    <>
      {employerInformation?.map((employee, index) => (
        <>
          <div className="p-0">
            <h5 className="income-hc1">
              {employee?.source_of_income
                ? "Employer Information"
                : "Previous Employement"}
            </h5>
          </div>
          {employee?.source_of_income ? (
            <>
              <div className="col-md-4">
                <div className="form-group mt-4 w-100">
                  <div className="select-container">
                    <select
                      required
                      name="estimated_credit_score"
                      formcontrolname="creditRange"
                      className={`form-control-select ${
                        employee?.source_of_income
                          ? "text-success"
                          : "text-dark"
                      }`}
                      value={employee?.source_of_income}
                      onChange={(e) =>
                        handleInputChange(
                          e.target.value,
                          index,
                          "source_of_income"
                        )
                      }
                      onFocus={() =>
                        document
                          ?.querySelector(".label-select")
                          ?.classList?.add("label-top")
                      }
                      onBlur={() =>
                        !employee?.source_of_income &&
                        document
                          ?.querySelector(".label-select")
                          ?.classList?.remove("label-top")
                      }
                    >
                      <option value="" disabled></option>
                      <option value={"Employed"}>
                        {" "}
                        Employed{" "}
                        {employee?.source_of_income === "Employed" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value={"Self Employed/1099"}>
                        {" "}
                        Self Employed/1099{" "}
                        {employee?.source_of_income === "Self Employed/1099" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value={"Independent Contractor"}>
                        {" "}
                        Independent Contractor{" "}
                        {employee?.source_of_income ===
                        "Independent Contractor" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value={"Retirement Income"}>
                        {" "}
                        Retirement Income{" "}
                        {employee?.source_of_income === "Retirement Income" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value={"Not Employed"}>
                        {" "}
                        Not Employed{" "}
                        {employee?.source_of_income === "Not Employed" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value={"I Do Not Earn Any Income"}>
                        {" "}
                        I Do Not Earn Any Income{" "}
                        {employee?.source_of_income ===
                        "I Do Not Earn Any Income" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                    </select>
                    <label
                      className={`label-new ${
                        employee?.source_of_income ? "label-top" : ""
                      }`}
                      htmlFor="estimated_credit_score"
                    >
                      Source Of Income
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-8 d-flex align-items-center px-0">
                <div className="d-flex align-items-center me-2">
                  &nbsp; &nbsp;
                  <input
                    type="checkbox"
                    onChange={() => {
                      handleInputChange(!employee?.military, index, "military");
                    }}
                    checked={employee?.military}
                    disabled={
                      employee?.source_of_income === "I Do Not Earn Any Income"
                        ? true
                        : false
                    }
                  />
                  &nbsp; &nbsp;
                  <label className="h6 text-muted mb-0">Military</label>
                </div>
                {employee?.military ? (
                  <div className="select-container">
                    <select
                      required={employee?.military ? true : false}
                      name="estimated_credit_score"
                      formcontrolname="creditRange"
                      onFocus={() =>
                        document
                          ?.querySelector(".label-select")
                          ?.classList?.add("label-top")
                      }
                      onBlur={() =>
                        !employee?.branch &&
                        document
                          ?.querySelector(".label-select")
                          ?.classList?.remove("label-top")
                      }
                      class={`form-select rounded-0 ${
                        employee?.branch === undefined ||
                        employee?.branch === null ||
                        employee?.branch === ""
                          ? "text-dark"
                          : "text-success"
                      }`}
                      disabled={
                        employee?.source_of_income ===
                        "I Do Not Earn Any Income"
                          ? true
                          : false
                      }
                      value={employee?.branch}
                      onChange={(e) => {
                        handleInputChange(e.target.value, index, "branch");
                      }}
                      style={{ padding: "12px 10px" }}
                    >
                      <option value="" disabled></option>
                      <option value={"Army"}>
                        {" "}
                        Army{" "}
                        {employee?.branch === "Army" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value={"Navy"}>
                        {" "}
                        Navy{" "}
                        {employee?.branch === "Navy" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value={"Coast Guard"}>
                        {" "}
                        Coast Guard{" "}
                        {employee?.branch === "Coast Guard" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value={"Marine Corps"}>
                        {" "}
                        Marine Corps{" "}
                        {employee?.branch === "Marine Corps" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value={"Air Force"}>
                        {" "}
                        Air Force{" "}
                        {employee?.branch === "Air Force" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value={"Space Force"}>
                        {" "}
                        Space Force{" "}
                        {employee?.branch === "Space Force" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value={"Other VA Eligbility"}>
                        {" "}
                        Other VA Eligbility{" "}
                        {employee?.branch === "Other VA Eligbility" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value={"Military Spouse"}>
                        {" "}
                        Military Spouse{" "}
                        {employee?.branch === "Military Spouse" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                    </select>
                    <label
                      className={`label-new ${
                        employee?.branch ? "label-top" : ""
                      }`}
                      htmlFor="estimated_credit_score"
                    >
                      Military
                    </label>
                  </div>
                ) : null}
                {employee?.branch && employee?.military ? (
                  <div className="select-container">
                    <select
                      required={
                        employee?.branch && employee?.military ? true : false
                      }
                      name="estimated_credit_score"
                      formcontrolname="creditRange"
                      className={`form-control-select w-100 ${
                        employee?.status ? "text-success" : "text-dark"
                      }`}
                      value={employee?.status}
                      disabled={
                        employee?.source_of_income ===
                        "I Do Not Earn Any Income"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        handleInputChange(e.target.value, index, "status");
                      }}
                      onFocus={() =>
                        document
                          ?.querySelector(".label-select")
                          ?.classList?.add("label-top")
                      }
                      onBlur={() =>
                        !employee?.status &&
                        document
                          ?.querySelector(".label-select")
                          ?.classList?.remove("label-top")
                      }
                    >
                      <option value="" disabled></option>
                      <option value={"Seperated"}>
                        {" "}
                        Seperated{" "}
                        {employee?.status === "Seperated" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value={"Currently Serving"}>
                        {" "}
                        Currently Serving{" "}
                        {employee?.status === "Currently Serving" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                    </select>
                    <label
                      className={`label-new ${
                        employee?.status ? "label-top" : ""
                      }`}
                      htmlFor="estimated_credit_score"
                    >
                      Military
                    </label>
                  </div>
                ) : null}
                {employee?.status === "Currently Serving" &&
                employee?.military ? (
                  <div className="select-container">
                    <select
                      required={
                        employee?.status === "Currently Serving" &&
                        employee?.military
                          ? true
                          : false
                      }
                      name="estimated_credit_score"
                      formcontrolname="creditRange"
                      className={`form-control-select w-100 ${
                        employee?.pcs_status ? "text-success" : "text-dark"
                      }`}
                      value={employee?.pcs_status}
                      disabled={
                        employee?.source_of_income ===
                        "I Do Not Earn Any Income"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        handleInputChange(e.target.value, index, "pcs_status");
                      }}
                      onFocus={() =>
                        document
                          ?.querySelector(".label-select")
                          ?.classList?.add("label-top")
                      }
                      onBlur={() =>
                        !employee?.pcs_status &&
                        document
                          ?.querySelector(".label-select")
                          ?.classList?.remove("label-top")
                      }
                    >
                      <option value="" disabled></option>
                      <option value={" PCS Move"}>
                        {" "}
                        PCS Move{" "}
                        {employee?.pcs_status === " PCS Move" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value={"Not A PCS Move"}>
                        {" "}
                        Not A PCS Move{" "}
                        {employee?.pcs_status === "Not A PCS Move" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                    </select>
                    <label
                      className={`label-new ${
                        employee?.pcs_status ? "label-top" : ""
                      }`}
                      htmlFor="estimated_credit_score"
                    >
                      Military
                    </label>
                  </div>
                ) : null}
              </div>
              <div className="col-md-12">
                <div>
                  <label className="form-label col-10 text-capitalize">
                    Certificate Of Eligbility (COE)
                  </label>
                  <div className="stateagent mt-3">
                    <div className="d-flex">
                      <div className="col-6 col-md-4 col-lg-3 me-2">
                        <div className="select-container">
                          <select
                            required
                            name="estimated_credit_score"
                            formcontrolname="creditRange"
                            className={`form-control-select w-100 ${
                              employee?.coe ? "text-success" : "text-dark"
                            }`}
                            onChange={(e) => {
                              handleInputChange(e.target.value, index, "coe");
                            }}
                            value={employee?.coe}
                            disabled={
                              employee?.source_of_income ===
                              "I Do Not Earn Any Income"
                                ? true
                                : false
                            }
                            onFocus={() =>
                              document
                                ?.querySelector(".label-select")
                                ?.classList?.add("label-top")
                            }
                            onBlur={() =>
                              !employee?.coe &&
                              document
                                ?.querySelector(".label-select")
                                ?.classList?.remove("label-top")
                            }
                          >
                            <option value="" disabled></option>
                            <option value={"Yes"}>
                              {" "}
                              Yes{" "}
                              {employee?.coe === "Yes" ? (
                                <span className="tick-mark2">✔</span>
                              ) : null}
                            </option>
                            <option value={"No"}>
                              {" "}
                              No{" "}
                              {employee?.coe === "No" ? (
                                <span className="tick-mark2">✔</span>
                              ) : null}
                            </option>
                          </select>
                          <label
                            className={`label-new ${
                              employee?.coe ? "label-top" : ""
                            }`}
                            htmlFor="estimated_credit_score"
                          >
                            (COE)
                          </label>
                        </div>
                      </div>
                      <div className="col-6 col-md-4 col-lg-3 pe-4">
                        <div className="select-container">
                          <select
                            required
                            name="estimated_credit_score"
                            formcontrolname="creditRange"
                            className={`form-control-select w-100 ${
                              employee?.dd214 ? "text-success" : "text-dark"
                            }`}
                            onChange={(e) => {
                              handleInputChange(e.target.value, index, "dd214");
                            }}
                            value={employee?.dd214}
                            disabled={
                              employee?.source_of_income ===
                              "I Do Not Earn Any Income"
                                ? true
                                : false
                            }
                            onFocus={() =>
                              document
                                ?.querySelector(".label-select")
                                ?.classList?.add("label-top")
                            }
                            onBlur={() =>
                              !employee?.dd214 &&
                              document
                                ?.querySelector(".label-select")
                                ?.classList?.remove("label-top")
                            }
                          >
                            <option value="" disabled></option>
                            <option value={"Yes"}>
                              {" "}
                              Yes{" "}
                              {employee?.dd214 === "Yes" ? (
                                <span className="tick-mark2">✔</span>
                              ) : null}
                            </option>
                            <option value={"No"}>
                              {" "}
                              No{" "}
                              {employee?.dd214 === "No" ? (
                                <span className="tick-mark2">✔</span>
                              ) : null}
                            </option>
                          </select>
                          <label
                            className={`label-new ${
                              employee?.dd214 ? "label-top" : ""
                            }`}
                            htmlFor="estimated_credit_score"
                          >
                            DD214
                          </label>
                        </div>
                      </div>
                      {employee?.military ? (
                        <div className="col-6 col-md-4 col-lg-3 pe-4">
                          <img
                            src="https://media.tenor.com/cKEVF70bnp0AAAAj/thank-you-usa.gif"
                            alt=""
                            style={{ height: "160px", width: "100%" }}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}

          <div
            className="mt-4 input-group w-50 col-md-6 flex-column"
            style={{ height: "80px" }}
          >
            <div>
              <div className="form-group-new">
                <input
                  className="form-control-new rounded-0 text-capitalize"
                  placeholder=" "
                  type="text"
                  required
                  value={employee?.employer_name}
                  onChange={(e) => {
                    handleInputChange(e.target.value, index, "employer_name");
                  }}
                  disabled={
                    employee?.source_of_income === "I Do Not Earn Any Income"
                      ? true
                      : false
                  }
                />
                <label htmlFor="inputheight1" className="label-new">
                  Employer or Buisness Name
                </label>
              </div>

              {employee?.employer_name?.length > 4 ? (
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    position: "absolute",
                    color: "green",
                    right: "24px",
                    bottom: "32px",
                    zIndex: "123",
                  }}
                >
                  ✔
                </p>
              ) : null}
            </div>
          </div>
          <div
            className="mt-4 input-group w-50 col-md-6 flex-column"
            style={{ height: "80px" }}
          >
            <div>
              <div className="form-group-new">
                <input
                  className="form-control-new rounded-0 text-capitalize"
                  placeholder=" "
                  type="tel"
                  required
                  value={employee?.employer_phone}
                  onChange={(e) => {
                    handleInputChange(e.target.value, index, "employer_phone");
                  }}
                  disabled={
                    employee?.source_of_income === "I Do Not Earn Any Income"
                      ? true
                      : false
                  }
                />
                <label htmlFor="inputheight1" className="label-new">
                  Employer Phone
                </label>
              </div>
              {employee?.employer_phone?.length > 4 ? (
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    position: "absolute",
                    color: "green",
                    right: "24px",
                    bottom: "32px",
                    zIndex: "123",
                  }}
                >
                  ✔
                </p>
              ) : null}
            </div>
          </div>
          <div
            className="input-group w-50 col-md-6 flex-column"
            style={{ height: "80px" }}
          >
            <div>
              <div className="form-group-new">
                <input
                  className="form-control-new rounded-0 text-capitalize"
                  placeholder=" "
                  type="text"
                  onChange={(e) => {
                    handleInputChange(e.target.value, index, "supervisor_name");
                  }}
                  value={employee?.supervisor_name}
                  disabled={
                    employee?.source_of_income === "I Do Not Earn Any Income"
                      ? true
                      : false
                  }
                  required
                />
                <label htmlFor="inputheight1" className="label-new">
                  Supervisor Name or Last Name
                </label>
              </div>
              {employee?.supervisor_name?.length > 4 ? (
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    position: "absolute",
                    color: "green",
                    right: "24px",
                    bottom: "32px",
                    zIndex: "123",
                  }}
                >
                  ✔
                </p>
              ) : null}
            </div>
          </div>
          <div
            className="input-group w-50 col-md-6 flex-column"
            style={{ height: "80px" }}
          >
            <div>
              <div className="form-group-new">
                <input
                  className="form-control-new rounded-0 text-capitalize"
                  placeholder=" "
                  type="email"
                  onChange={(e) => {
                    handleInputChange(
                      e.target.value,
                      index,
                      "supervisor_email"
                    );
                  }}
                  value={employee?.supervisor_email}
                  disabled={
                    employee?.source_of_income === "I Do Not Earn Any Income"
                      ? true
                      : false
                  }
                  required
                />
                <label htmlFor="inputheight1" className="label-new">
                  Supervisor or HR Email
                </label>
              </div>
              {employee?.supervisor_email?.length > 4 ? (
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    position: "absolute",
                    color: "green",
                    right: "24px",
                    bottom: "32px",
                    zIndex: "123",
                  }}
                >
                  ✔
                </p>
              ) : null}
            </div>
          </div>

          <div className="col-md-6 my-2">
            <GoogleAddressIncome
              source_of_income={employee?.source_of_income}
              key={index}
              addressCurrent={employee.street_address}
              setGoogleData={(updatedAddress) =>
                handleAddressChange(index, updatedAddress)
              }
              setStreetAddress={(updatedStreetAddress) =>
                handleStreetAddressChange(index, updatedStreetAddress)
              }
              setMultipleAddressData={(updatedAddress) =>
                handleAddressChange(index, updatedAddress)
              }
              setAddressCurrent={(updatedCurrentAddress) =>
                handleCurrentAddressChange(index, updatedCurrentAddress)
              }
            />
          </div>
          <div className="col-md-6 my-2 position-relative">
            <div className="form-group-new">
              <input
                className="form-control-new rounded-0 text-capitalize"
                placeholder=" "
                type="text"
                value={
                  employee?.multipleAddressData?.apt_unit
                    ? employee?.multipleAddressData?.apt_unit
                    : ""
                }
                disabled={
                  employee?.source_of_income === "I Do Not Earn Any Income"
                    ? true
                    : false
                }
              />
              <label htmlFor="inputheight1" className="label-new">
                Apt/unit
              </label>
            </div>
            {employee?.multipleAddressData?.apt_unit?.length > 2 ? (
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  position: "absolute",
                  color: "green",
                  right: "24px",
                  bottom: "10px",
                  zIndex: "123",
                }}
              >
                ✔
              </p>
            ) : null}
          </div>
          <div className="col-md-6 my-2 position-relative">
            <div className="form-group-new">
              <input
                className="form-control-new rounded-0 text-capitalize"
                placeholder=" "
                type="text"
                value={
                  employee?.multipleAddressData?.city
                    ? employee?.multipleAddressData?.city
                    : ""
                }
                disabled={
                  employee?.source_of_income === "I Do Not Earn Any Income"
                    ? true
                    : false
                }
                required
              />
              <label htmlFor="inputheight1" className="label-new">
                City
              </label>
            </div>
            {employee?.multipleAddressData?.city?.length > 2 ? (
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  position: "absolute",
                  color: "green",
                  right: "24px",
                  bottom: "10px",
                  zIndex: "123",
                }}
              >
                ✔
              </p>
            ) : null}
          </div>
          <div className="col-md-3 my-2 position-relative">
            <div className="form-group-new">
              <input
                className="form-control-new rounded-0 text-capitalize"
                placeholder=" "
                type="text"
                value={
                  employee?.multipleAddressData?.state
                    ? employee?.multipleAddressData?.state
                    : ""
                }
                disabled={
                  employee?.source_of_income === "I Do Not Earn Any Income"
                    ? true
                    : false
                }
                required
              />
              <label htmlFor="inputheight1" className="label-new">
                State
              </label>
            </div>
            {employee?.multipleAddressData?.state?.length > 2 ? (
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  position: "absolute",
                  color: "green",
                  right: "24px",
                  bottom: "10px",
                  zIndex: "123",
                }}
              >
                ✔
              </p>
            ) : null}
          </div>
          <div className="col-md-3 my-2 position-relative">
            <div className="form-group-new">
              <input
                className="form-control-new rounded-0 text-capitalize"
                placeholder=" "
                type="number"
                value={
                  employee?.multipleAddressData?.postal_code
                    ? employee?.multipleAddressData?.postal_code
                    : ""
                }
                disabled={
                  employee?.source_of_income === "I Do Not Earn Any Income"
                    ? true
                    : false
                }
                required
              />
              <label htmlFor="inputheight1" className="label-new">
                Zip Code
              </label>
            </div>
            {employee?.multipleAddressData?.postal_code?.length > 2 ? (
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  position: "absolute",
                  color: "green",
                  right: "24px",
                  bottom: "10px",
                  zIndex: "123",
                }}
              >
                ✔
              </p>
            ) : null}
          </div>

          <div className="mt-3 p-0 ">
            <h5 className="income-hc1">Employee Information</h5>
          </div>

          <div className="col-md-6">
            <label className="label font-sm font-sm1 clr my-2">
              Job Title
              <br />
            </label>
            <div
              className="input-group w-100 flex-column"
              style={{ height: "84px" }}
            >
              <div>
                <div className="form-group-new">
                  <input
                    className="form-control-new rounded-0 text-capitalize"
                    placeholder=" "
                    type="text"
                    onChange={(e) => {
                      handleInputChange(e.target.value, index, "job_title");
                    }}
                    value={employee?.job_title}
                    disabled={
                      employee?.source_of_income === "I Do Not Earn Any Income"
                        ? true
                        : false
                    }
                    required
                  />
                  <label htmlFor="inputheight1" className="label-new">
                    Job Title
                  </label>
                </div>
                {employee?.job_title?.length > 4 ? (
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      position: "absolute",
                      color: "green",
                      right: "18px",
                      bottom: "32px",
                      zIndex: "123",
                    }}
                  >
                    ✔
                  </p>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="position-relative">
              <label className="label font-sm font-sm1 clr my-2">
                Start Date.
                <br />
              </label>
              <div>
                <DatePicker
                  selected={employee?.start_date}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  required
                  className="form-control my-2 w-100"
                  showFullMonthYearPicker
                  onChange={(date) => handleStartDateChange(date, index)}
                  disabled={
                    employee?.startDateDisabled ||
                    employee?.source_of_income === "I Do Not Earn Any Income"
                  }
                />
              </div>
              {employee?.start_date &&
                employee?.start_date?.toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "2-digit",
                }).length > 4 && (
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      position: "absolute",
                      color: "green",
                      right: "8%",
                      bottom: "0px",
                      zIndex: "123",
                    }}
                  >
                    ✔
                  </p>
                )}
            </div>
          </div>
          <div className="col-md-3">
            <div className="position-relative">
              <label className="label font-sm font-sm1 clr my-2">
                End Date.
                <br />
              </label>
              <div>
                <DatePicker
                  selected={employee?.end_date}
                  dateFormat="MM/yyyy"
                  required
                  showMonthYearPicker
                  showFullMonthYearPicker
                  className="form-control my-2 w-100"
                  onChange={(date) => handleEndDateChange(date, index)}
                  disabled={
                    employee?.endDateDisabled ||
                    employee?.source_of_income === "I Do Not Earn Any Income"
                  }
                />
              </div>
              {employee?.end_date &&
                employee?.end_date?.toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "2-digit",
                }).length > 4 && (
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      position: "absolute",
                      color: "green",
                      right: "24px",
                      bottom: "0px",
                      zIndex: "123",
                    }}
                  >
                    ✔
                  </p>
                )}
            </div>
          </div>

          <div className="d-flex justify-content-between">
            <label className="label font-sm font-sm1 clr my-2">
              How long on this job?
            </label>
            {totalyears >= 2 ? (
              <div
                className="d-flex align-items-baseline pt-2"
                style={{ marginLeft: "-15px", marginBottom: "40px" }}
              >
                &nbsp; &nbsp;
                <input
                  type="checkbox"
                  onChange={(e) => setOptionIncome(!optionIncome)}
                  checked={optionIncome}
                  disabled={
                    employee?.source_of_income === "I Do Not Earn Any Income"
                      ? true
                      : false
                  }
                />
                &nbsp; &nbsp;
                <label className="h6 text-muted mb-0">
                  Optional Income Detail
                </label>
              </div>
            ) : null}
          </div>

          <div className="col-md-4 mt-2">
            <div className="input-group w-100">
              <div className="form-group-new">
                <input
                  className="form-control-new rounded-0 text-capitalize"
                  placeholder=" "
                  type="text"
                  value={employee?.total_year}
                  disabled
                />
                <label htmlFor="inputheight1" className="label-new">
                  Total Years
                </label>
              </div>
              {employee?.total_year ? (
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    position: "absolute",
                    color: "green",
                    right: "18px",
                    bottom: "-14px",
                    zIndex: "123",
                  }}
                >
                  ✔
                </p>
              ) : null}
            </div>
          </div>
          <div className="col-md-4 mt-2">
            <div className="input-group w-100">
              <div className="form-group-new">
                <input
                  className="form-control-new rounded-0 text-capitalize"
                  placeholder=" "
                  type="text"
                  value={employee?.total_month}
                  disabled
                />
                <label htmlFor="inputheight1" className="label-new">
                  Total Months
                </label>
              </div>
              {employee?.total_month ? (
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    position: "absolute",
                    color: "green",
                    right: "18px",
                    bottom: "-14px",
                    zIndex: "123",
                  }}
                >
                  ✔
                </p>
              ) : null}
            </div>
          </div>

          <div className="col-md-4 mt-2">
            <p
              className="fw-semibold total-fnt1 mb-2"
              style={{ marginTop: "-30px" }}
            >
              Income One
            </p>
            <div className="input-group w-100">
              <div className="form-group-new">
                <input
                  className="form-control-new rounded-0 text-capitalize"
                  placeholder=" "
                  type="text"
                  onChange={(e) => {
                    handleInputChange(
                      e.target.value,
                      index,
                      "total_monthly_income"
                    );
                  }}
                  value={
                    employee?.total_monthly_income
                      ? "$" + employee?.total_monthly_income
                      : ""
                  }
                  disabled={
                    employee?.source_of_income === "I Do Not Earn Any Income"
                      ? true
                      : false
                  }
                  required
                />
                <label
                  htmlFor="inputheight1"
                  className="label-new"
                  style={{ fontSize: "12px" }}
                >
                  Total Monthly Income (Before tax)
                </label>
              </div>
              {employee?.total_monthly_income ? (
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    position: "absolute",
                    color: "green",
                    right: "18px",
                    bottom: "-14px",
                    zIndex: "123",
                  }}
                >
                  ✔
                </p>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 mt-4">
            <div className="d-flex align-items-baseline pt-2">
              &nbsp; &nbsp;
              <input
                type="checkbox"
                onChange={(e) => {
                  handleInputChange(
                    !employee?.ownership_interest,
                    index,
                    "ownership_interest"
                  );
                }}
                checked={employee?.ownership_interest}
                disabled={
                  employee?.source_of_income === "I Do Not Earn Any Income"
                    ? true
                    : false
                }
              />
              &nbsp; &nbsp;
              <label className="h6 text-muted mb-0">
                I have ownership Interest in this business.
              </label>
            </div>
          </div>
          <div className="col-md-6 mt-4">
            <div
              className="d-flex align-items-baseline pt-2"
              style={{ marginLeft: "5px" }}
            >
              &nbsp; &nbsp;
              <input
                type="checkbox"
                onChange={(e) => {
                  handleInputChange(
                    !employee?.family_employed,
                    index,
                    "family_employed"
                  );
                }}
                checked={employee?.family_employed}
                disabled={
                  employee?.source_of_income === "I Do Not Earn Any Income"
                    ? true
                    : false
                }
              />
              &nbsp; &nbsp;
              <label className="h6 text-muted mb-0">
                I am employed by a family member , property seller , real estate
                agent , or other party to this loan.
              </label>
            </div>
          </div>
          {employee?.ownership_interest ? (
            <div class="input-group my-4">
              <span
                class="input-group-text text-capitalize emp-wd5"
                id="basic-addon1"
              >
                Overnership Share
              </span>
              <select
                class="form-select"
                id="inputGroupSelect02"
                onChange={(e) => {
                  handleInputChange(e.target.value, index, "ownership_share");
                }}
                required={employee?.ownership_interest ? true : false}
                value={employee?.ownership_share}
              >
                <option value="25% OR MORE">25% OR MORE</option>
                <option value="MONTHLY">LESS THAN 25%</option>
              </select>
            </div>
          ) : null}
          <div className="col-md-6">
            <div class="input-group my-4">
              <div className="form-group-new">
                <input
                  className="form-control-new w-100 rounded-0 text-capitalize ms-0"
                  placeholder=" "
                  type="number"
                  onChange={(e) => {
                    handleInputChange(
                      e.target.value,
                      index,
                      "year_in_profession"
                    );
                  }}
                  value={employee?.year_in_profession}
                  required
                />
                <label htmlFor="inputheight1" className="label-new">
                  Year in profession
                </label>
              </div>
              {employee?.year_in_profession?.length > 4 ? (
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    position: "absolute",
                    color: "green",
                    right: "2%",
                    bottom: "-8px",
                    zIndex: "123",
                  }}
                >
                  ✔
                </p>
              ) : null}
            </div>
          </div>
          <div className="col-md-6">
            <div class="input-group my-4">
              <div className="form-group-new">
                <input
                  className="form-control-new w-100 rounded-0 text-capitalize ms-0"
                  placeholder=" "
                  type="number"
                  onChange={(e) => {
                    handleInputChange(
                      e.target.value,
                      index,
                      "month_in_profession"
                    );
                  }}
                  required
                  value={employee?.month_in_profession}
                />
                <label htmlFor="inputheight1" className="label-new">
                  Month in profession
                </label>
              </div>
              {employee?.month_in_profession?.length >= 7 ? (
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    position: "absolute",
                    color: "green",
                    right: "2%",
                    bottom: "-8px",
                    zIndex: "123",
                  }}
                >
                  ✔
                </p>
              ) : null}
            </div>
          </div>
        </>
      ))}
    </>
  );
};

export default EmployeeNewForm;
