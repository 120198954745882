/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-lone-blocks */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Header from "../../../Layout/Header";
import Mortageside from "./Mortageside";
import { AiOutlineArrowRight } from "react-icons/ai";
import { RiArrowDropDownLine } from "react-icons/ri";
import { Link, useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import Baseurl from "../../../../Baseurl";
import ProfileInfo from "../Profile/ProfileInfo";
import { FaBars, FaCheckCircle } from "react-icons/fa";
import { BiDollar } from "react-icons/bi";
import { Progress } from "antd";
import footer from "../../../../Images/footercity.svg";

const Mortageinfo = () => {
  const [allPostData, setAllPostData] = useState();
  const [Id, Setid] = useState();

  const application_id = localStorage.getItem("assignId");
  const reviewData = new FormData();
  reviewData.append("application_id", application_id);

  const postData = () => {
    const token = localStorage.getItem("usertoken");

    var config = {
      method: "post",
      url: `${Baseurl.baseurl}application/completion`,
      data: reviewData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        setAllPostData(response?.data?.data);
        console.log(response?.data?.data, "sidebar response");
        if (response?.data?.status === true) {
          console.log(response?.data?.message, "response?.data?.message");
          setLoader(false);

          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
        console.log(response, "my response");
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setBund(error?.response?.data?.errors);
      });
  };

  const location = useLocation();
  const mort =
    location.pathname === "/mortage_info"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100 ";
  const pers =
    location.pathname === "/personal_info"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const cobo =
    location.pathname === "/Co-Borrowers"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const inc =
    location.pathname === "/Income"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const ass =
    location.pathname === "/assets"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const real =
    location.pathname === "/Real_state"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const Decl =
    location.pathname === "/declaration"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";

  const demo =
    location.pathname === "/demographic"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";

  const credit =
    location.pathname === "/credit"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";

  const review =
    location.pathname === "/review"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const [loader, setLoader] = useState(false);
  const [bund, setBund] = useState();
  const Assign_id = localStorage.getItem("assignId");
  const history = useHistory();
  const [purchase_process, setPurchase_process] = useState(null);
  const [loanPurpose, setLoanPurpose] = useState("");
  const [property_type, setProperty_type] = useState("");
  const [down_percent, setDown_percent] = useState("");
  const [comfortable_monthly, setComfortable_monthly] = useState();
  const [propertyType, setPropertyType] = useState("");
  const [residentialType, setResidentalType] = useState("");
  const [commercialType, setCommercialType] = useState("");
  const [use_of_property, setUse_of_property] = useState("");
  const [operateBusiness, setOperateBusiness] = useState("");
  const [noUnits, setNoUnits] = useState("");
  const [description, setDescription] = useState("");
  const [solarInstall, setSolarInstall] = useState(false);
  const [solarPrice, setSolarPrice] = useState(false);
  const [solarPayment, setSolarPayment] = useState(false);
  const [real_estate_agent, setReal_estate_agent] = useState(false);

  const [price_of_property, setPrice_of_property] = useState("");
  const [down_payment, setDown_Payment] = useState("");
  const [down_payment_sources, setDown_Payment_sources] = useState("");
  const [down_payment_sources_amount, setDown_Payment_sources_amount] =
    useState("");
  const [agent_email_id, setAgent_email_id] = useState("");
  const [agent_full_name, setAgent_full_name] = useState("");
  const [agent_cell, setAgent_cell] = useState("");
  const [agent_company, setAgent_company] = useState("");
  const [down_payment_sources_extra, setDown_payment_sources_extra] =
    useState("");
  const [
    down_payment_sources_amount_extra,
    setDown_payment_sources_amount_extra,
  ] = useState("");
  useEffect(() => {
    postData();
    Get_Borrower();
  }, []);

  const Get_Borrower = () => {
    setLoader(true);
    let token = localStorage.getItem("usertoken");
    var config = {
      method: "post",
      url: `${Baseurl.baseurl}get/mortgage/info`,

      data: reviewData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        if (response?.data?.status === true) {
          setLoader(false);
          setPurchase_process(response?.data?.data?.purchase_process || "");
          setProperty_type(response?.data?.data?.property_type || "");
          setDown_percent(response?.data?.data?.down_percent || "");
          setComfortable_monthly(
            response?.data?.data?.comfortable_monthly_ho_payment || ""
          );
          setReal_estate_agent(
            response?.data?.data?.real_estate_agent == 1 ? true : false || ""
          );
          setUse_of_property(response?.data?.data?.use_of_property);
          setPrice_of_property(response?.data?.data?.price_of_property || "");
          setDown_Payment(response?.data?.data?.down_payment || "");
          setDown_Payment_sources(
            response?.data?.data?.down_payment_sources || ""
          );
          setDown_Payment_sources_amount(
            response?.data?.data?.down_payment_sources_amount || ""
          );
          setAgent_email_id(response?.data?.data?.agent_email_id || "");
          setAgent_full_name(response?.data?.data?.agent_full_name || "");
          setAgent_cell(response?.data?.data?.agent_cell || "");
          setAgent_company(response?.data?.data?.agent_company || "");
          setDown_payment_sources_extra(
            response?.data?.data?.down_payment_sources_extra || ""
          );
          setDown_payment_sources_amount_extra(
            response?.data?.data?.down_payment_sources_amount_extra || ""
          );
          Setid(response?.data?.data?.id);
          console.log(
            response?.data?.data?.operate_business,
            "setDown_payment_sources_extra"
          );
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setBund(error?.response?.data?.errors);
        console.log(error, "error");
      });
  };
  const [editing, steediting] = useState(true);
  const [editing2, steediting2] = useState(true);

  function handleChange(event, state, type) {
    if (type === "price_of_property") {
      steediting2(false);
    } else {
      steediting(false);
    }
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9]/g, "");
    const formattedValue = Number(numericValue).toLocaleString();
    state(formattedValue);
  }

  const Info = (e) => {
    e.preventDefault();
    setLoader(true);
    let token = localStorage.getItem("usertoken");
    const Data = new FormData();
    Data.append("application_id", Assign_id);
    Data.append("amount_of_loan", Number(currencyValue.replace(/,/g, "")));
    Data.append("purchase_process", purchase_process);
    Data.append("property_type", property_type);
    Data.append("use_of_property", use_of_property);
    Data.append(
      "comfortable_monthly_ho_payment",
      Number(comfortable_monthly.replace(/,/g, ""))
    );
    Data.append("real_estate_agent", real_estate_agent ? 1 : 0);
    Data.append("price_of_property", price_of_property);
    Data.append("down_payment", down_payment);
    Data.append("down_percent", down_percent);
    Data.append("down_payment_sources", down_payment_sources);
    Data.append("down_payment_sources_amount", down_payment);
    Data.append("agent_email_id", agent_email_id);
    Data.append("agent_full_name", agent_full_name);
    Data.append("agent_cell", agent_cell);
    Data.append("agent_company", agent_company);
    Id && Data.append("id", Id);
    var config = {
      method: "post",
      url: `${Baseurl.baseurl}store/mortgage/info`,
      data: Data,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setLoader(true);
        history.push("/personal_info");
        if (response.data.status === true) {
          setLoader(false);

          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setBund(error?.response?.data?.errors);
      });
  };

  const [isOpen, setIsOpen] = useState(true);
  const handleToggle = () => {
    if (isOpen === false) {
      setIsOpen(true);
    }
    if (isOpen === true) {
      setIsOpen(false);
    } else {
      console.log(isOpen, "hui");
    }
    console.log(isOpen, "huihui");
  };
  const result =
    Number(price_of_property?.replaceAll(",", "")) - Number(down_payment);
  const currencyValue = Math.abs(result).toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <>
      {loader ? <div className="loader"></div> : null}

      <Header />
      <form onSubmit={Info}>
        <div className="container-fluid">
          <div className="row">
            <FaBars
              className=" none"
              onClick={() => {
                handleToggle();
              }}
            />

            <FaBars
              className=" block"
              id="topnav-hamburger-icon"
              onClick={() => {
                handleToggle();
              }}
            />

            <div
              className={
                isOpen === true
                  ? "col-md-2 ps-0 sidebarmain fixed_side sidebar-nav open "
                  : "d-none"
              }
            >
              <div className="px-4 my-3">
                <Link to="#">Dashboard</Link>
                <Progress percent={allPostData} status="actice" />
              </div>
              <div className="greyline"></div>
              <Link to={"/mortage_info"}>
                <div className={mort}>
                  <div className="sidecircle">
                    {location.pathname === "/mortage_info" ? (
                      <FaCheckCircle className="checkicon" />
                    ) : null}
                  </div>
                  <div className="mort grey_color fw-500">
                    Property & Loan Info
                  </div>
                  <div></div>
                </div>
              </Link>
              <Link to={"/personal_info"}>
                <div className={pers}>
                  <div className="sidecircle">
                    {location.pathname === "/personal_info" ? (
                      <>
                        <FaCheckCircle className="checkicon" />
                      </>
                    ) : null}
                  </div>
                  <div className="mort grey_color fw-500">Personal Info</div>
                  <div></div>
                </div>
              </Link>
              <Link to={"/Co-Borrowers"}>
                <div className={cobo}>
                  <div className="sidecircle">
                    {location.pathname === "/Co-Borrowers" ? (
                      <>
                        <FaCheckCircle className="checkicon" />
                      </>
                    ) : null}
                  </div>
                  <div className="mort grey_color fw-500">Co-Borrowers</div>
                  <div></div>
                </div>
              </Link>
              <Link to={"/Income"}>
                <div className={inc}>
                  <div className="sidecircle">
                    {location.pathname === "/Income" ? (
                      <FaCheckCircle className="checkicon" />
                    ) : null}
                  </div>
                  <div className="mort grey_color fw-500">Income</div>
                  <div></div>
                </div>
              </Link>
              <Link to={"assets"}>
                <div className={ass}>
                  <div className="sidecircle">
                    {location.pathname === "/assets" ? (
                      <FaCheckCircle className="checkicon" />
                    ) : null}{" "}
                  </div>
                  <div className="mort grey_color fw-500">Assets</div>
                  <div></div>
                </div>
              </Link>
              <Link to={"/Real_state"}>
                <div className={real}>
                  <div className="sidecircle">
                    {location.pathname === "Real_state" ? (
                      <FaCheckCircle className="checkicon" />
                    ) : null}
                  </div>
                  <div className="mort grey_color fw-500">Real State</div>
                  <div></div>
                </div>
              </Link>
              <Link to={"/declaration"}>
                <div className={Decl}>
                  <div className="sidecircle">
                    {location.pathname === "/declaration" ? (
                      <FaCheckCircle className="checkicon" />
                    ) : null}
                  </div>
                  <div className="mort grey_color fw-500">Declaration</div>
                  <div></div>
                </div>
              </Link>
              <Link to={"/demographic"}>
                <div className={demo}>
                  <div className="sidecircle">
                    {location.pathname === "/demographic" ? (
                      <FaCheckCircle className="checkicon" />
                    ) : null}
                  </div>
                  <div className="mort grey_color fw-500">Demographic</div>
                  <div></div>
                </div>
              </Link>
              <Link to={"/credit"}>
                <div className={credit}>
                  <div className="sidecircle">
                    {location.pathname === "/credit" ? (
                      <FaCheckCircle className="checkicon" />
                    ) : null}
                  </div>
                  <div className="mort grey_color fw-500">Credit</div>
                  <div></div>
                </div>
              </Link>
              <Link to={"/purchase/review"}>
                <div className={review}>
                  <div className="sidecircle">
                    {location.pathname === "/purchase/review" ? (
                      <FaCheckCircle className="checkicon" />
                    ) : null}
                  </div>
                  <div className="mort grey_color fw-500">
                    Review and Submit
                  </div>
                  <div></div>
                </div>
              </Link>
            </div>
            <div
              className={
                isOpen === true
                  ? "col-md-8 open he my-5 ps-lg-5"
                  : isOpen === false
                  ? "col-md-10 open nhi he my-5 ps-lg-5"
                  : ""
              }
            >
              <div className="row">
                <div className="col-md-6">
                  <h5
                    className="font266"
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "black",
                    }}
                  >
                    Where are you in the purchase process?
                  </h5>
                  <div className="input-group126  my-2">
                    <select
                      required
                      onChange={(e) => setPurchase_process(e.target.value)}
                      value={purchase_process}
                      className={`form-select py-2 text-primary rounded-0 ${
                        purchase_process === undefined ||
                        purchase_process === null ||
                        purchase_process === ""
                          ? "text-dark"
                          : "text-success"
                      }`}
                    >
                      <option selected="" value={""} disabled="">
                        Select
                      </option>
                      <option value={"Just Getting Started"}>
                        Just Getting Started{" "}
                        {purchase_process === "Just Getting Started" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value={"Making Offers"}>
                        Making Offers
                        {purchase_process === "Making Offers" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value={"Found a Property / Offer Pending"}>
                        Found a Property / Offer Pending
                        {purchase_process ===
                        "Found a Property / Offer Pending" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value={"Under Contract"}>
                        Under Contract
                        {purchase_process === "Under Contract" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                    </select>
                  </div>
                  {bund?.purchase_process
                    ? bund?.purchase_process.map((e) => (
                        <p className="text-danger">{e}</p>
                      ))
                    : null}
                </div>
                <div className="col-md-6">
                  <h5
                    className="font266"
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "black",
                    }}
                  >
                    Loan Purpose
                  </h5>
                  <div className="input-group126  my-2">
                    <select
                      required
                      onChange={(e) => setLoanPurpose(e.target.value)}
                      value={loanPurpose}
                      className={`form-select py-2 text-primary rounded-0 ${
                        loanPurpose === undefined ||
                        loanPurpose === null ||
                        loanPurpose === ""
                          ? "text-dark"
                          : "text-success"
                      }`}
                    >
                      <option selected="" disabled="" value={""}>
                        Select
                      </option>
                      <option value={"Purchase"}>
                        Purchase{" "}
                        {loanPurpose === "Purchase" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value={"Refinance"}>
                        Refinance
                        {loanPurpose === "Refinance" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value={"2nd"}>
                        2nd
                        {loanPurpose === "2nd" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value={"Third"}>
                        Third
                        {loanPurpose === "Third" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value={"Heloc"}>
                        Heloc
                        {loanPurpose === "Heloc" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4 mt-4">
                  <h5
                    className="font266"
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "black",
                    }}
                  >
                    What is the approximate price of the property <br /> you are
                    looking at?
                  </h5>
                  <div className="input-group126 position-relative my-2">
                    <BiDollar
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        position: "absolute",
                        left: "10px",
                        top: "10px",
                        zIndex: "123",
                      }}
                    />
                    <input
                      onChange={(e) =>
                        handleChange(
                          e,
                          setPrice_of_property,
                          "price_of_property"
                        )
                      }
                      className="form-control text-capitalize 123 m-0 ps-4"
                      type="text"
                      onBlur={() => steediting2(true)}
                      onFocus={() => steediting2(false)}
                      value={`${
                        price_of_property === undefined ? 0 : price_of_property
                      }${editing2 === true ? ".00" : ""}`}
                      required
                    />
                    {price_of_property?.length < 4 ||
                    price_of_property === "0" ? null : (
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          position: "absolute",
                          color: "green",
                          right: "18px",
                          top: "8px",
                          zIndex: "123",
                        }}
                      >
                        ✔
                      </p>
                    )}
                    {bund?.price_of_property
                      ? bund?.price_of_property.map((e) => (
                          <p className="text-danger">{e}</p>
                        ))
                      : null}
                  </div>
                </div>
                <div className="col-md-4 mt-4">
                  <h5
                    className="font266"
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "black",
                    }}
                  >
                    How much is your down payment?
                  </h5>
                  <div className="d-flex align-items-center mt-3">
                    <div className="input-group126 position-relative w-100">
                      <BiDollar
                        style={{
                          fontSize: "16px",
                          fontWeight: "600",
                          position: "absolute",
                          left: "1px",
                          bottom: "11px",
                          zIndex: "123",
                        }}
                      />{" "}
                      <input
                        className="form-control text-capitalize 123 mt-2  ps-3"
                        type="number"
                        required
                        onBlur={() => steediting2(true)}
                        onFocus={() => steediting2(false)}
                        value={`${
                          down_payment === undefined ? 0 : down_payment
                        }${editing2 === true ? ".00" : ""}`}
                        onChange={(e) => {
                          setDown_percent(
                            (
                              (Number(e.target.value) /
                                Number(price_of_property.replaceAll(",", ""))) *
                              100
                            ).toFixed(1)
                          );
                          setDown_Payment(e.target.value);
                          console.log(
                            Number(e.target.value / 100) *
                              Number(price_of_property.replaceAll(",", ""))
                          );
                        }}
                      />
                    </div>

                    <div className="position-relative">
                      <input
                        className="form-control text-capitalize  mt-2  ps-3"
                        type="text"
                        required
                        value={down_percent}
                        onChange={(e) => {
                          setDown_Payment(
                            Number(e.target.value / 100) *
                              Number(price_of_property.replaceAll(",", ""))
                          );
                          setDown_percent(e.target.value);
                        }}
                      />
                      <span
                        style={{
                          fontSize: "15px",
                          fontWeight: "600",
                          position: "absolute",
                          left: "4px",
                          bottom: "8px",
                        }}
                      >
                        %
                      </span>
                    </div>
                  </div>

                  {bund?.down_payment
                    ? bund?.down_payment.map((e) => (
                        <p className="text-danger">{e}</p>
                      ))
                    : null}
                  {bund?.down_percent
                    ? bund?.down_percent.map((e) => (
                        <p className="text-danger">{e}</p>
                      ))
                    : null}
                </div>
                <div className="col-md-4 mt-4">
                  <h5
                    className="font266"
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "black",
                    }}
                  >
                    Down payment source(s) and amount
                  </h5>
                  <div className="d-flex align-items-center mt-3">
                    <div className="input-group126 position-relative w-100">
                      <select
                        required
                        formcontrolname="FundsType"
                        name="donpaymentsources"
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          height: "37px",
                          marginTop: "24px",
                        }}
                        onChange={(e) =>
                          setDown_Payment_sources(e.target.value)
                        }
                        value={down_payment_sources}
                        className={`form-control123 mt-2 ps-3  ${
                          down_payment_sources === undefined ||
                          down_payment_sources === null ||
                          down_payment_sources === ""
                            ? "text-dark"
                            : "text-success"
                        }`}
                      >
                        <option
                          selected=""
                          disabled=""
                          className="ng-tns-c161-6 ng-star-inserted"
                        >
                          Select
                        </option>

                        <option
                          className="ng-tns-c161-6 ng-star-inserted"
                          value="CheckingSavings"
                        >
                          Checking/Savings
                          {down_payment_sources === "CheckingSavings" ? (
                            <span className="tick-mark2">✔</span>
                          ) : null}
                        </option>

                        <option
                          className="ng-tns-c161-6 ng-star-inserted"
                          value="GiftFunds"
                        >
                          Gift
                          {down_payment_sources === "GiftFunds" ? (
                            <span className="tick-mark2">✔</span>
                          ) : null}
                        </option>

                        <option
                          className="ng-tns-c161-6 ng-star-inserted"
                          value="StocksAndBonds"
                        >
                          Stocks and Bonds
                          {down_payment_sources === "StocksAndBonds" ? (
                            <span className="tick-mark2">✔</span>
                          ) : null}
                        </option>

                        <option
                          className="ng-tns-c161-6 ng-star-inserted"
                          value="EquityOnSoldProperty"
                        >
                          Equity On Sold Property
                          {down_payment_sources === "EquityOnSoldProperty" ? (
                            <span className="tick-mark2">✔</span>
                          ) : null}
                        </option>

                        <option
                          className="ng-tns-c161-6 ng-star-inserted"
                          value="EquityOnPendingSale"
                        >
                          Equity from Pending Sale of Property
                          {down_payment_sources === "EquityOnPendingSale" ? (
                            <span className="tick-mark2">✔</span>
                          ) : null}
                        </option>

                        <option
                          className="ng-tns-c161-6 ng-star-inserted"
                          value="EquityOnSubjectProperty"
                        >
                          Equity from Subject Property
                          {down_payment_sources ===
                          "EquityOnSubjectProperty" ? (
                            <span className="tick-mark2">✔</span>
                          ) : null}
                        </option>

                        <option
                          className="ng-tns-c161-6 ng-star-inserted"
                          value="Other"
                        >
                          Other funds source
                          {down_payment_sources === "Other" ? (
                            <span className="tick-mark2">✔</span>
                          ) : null}
                        </option>

                        <option
                          className="ng-tns-c161-6 ng-star-inserted"
                          value="BridgeLoan"
                        >
                          Bridge Loan
                          {down_payment_sources === "BridgeLoan" ? (
                            <span className="tick-mark2">✔</span>
                          ) : null}
                        </option>

                        <option
                          className="ng-tns-c161-6 ng-star-inserted"
                          value="CashOnHand"
                        >
                          Cash On Hand
                          {down_payment_sources === "CashOnHand" ? (
                            <span className="tick-mark2">✔</span>
                          ) : null}
                        </option>

                        <option
                          className="ng-tns-c161-6 ng-star-inserted"
                          value="CashOrOtherEquity"
                        >
                          Cash Or Other Equity
                          {down_payment_sources === "CashOrOtherEquity" ? (
                            <span className="tick-mark2">✔</span>
                          ) : null}
                        </option>

                        <option
                          className="ng-tns-c161-6 ng-star-inserted"
                          value="Contribution"
                        >
                          Contribution
                          {down_payment_sources === "Contribution" ? (
                            <span className="tick-mark2">✔</span>
                          ) : null}
                        </option>

                        <option
                          className="ng-tns-c161-6 ng-star-inserted"
                          value="CreditCard"
                        >
                          Credit Card
                          {down_payment_sources === "CreditCard" ? (
                            <span className="tick-mark2">✔</span>
                          ) : null}
                        </option>

                        <option
                          className="ng-tns-c161-6 ng-star-inserted"
                          value="DepositOnSalesContract"
                        >
                          Deposit On Sales Contract
                          {down_payment_sources === "DepositOnSalesContract" ? (
                            <span className="tick-mark2">✔</span>
                          ) : null}
                        </option>

                        <option
                          className="ng-tns-c161-6 ng-star-inserted"
                          value="ExcessDeposit"
                        >
                          Excess Deposit
                          {down_payment_sources === "ExcessDeposit" ? (
                            <span className="tick-mark2">✔</span>
                          ) : null}
                        </option>

                        <option
                          className="ng-tns-c161-6 ng-star-inserted"
                          value="ForgivableSecuredLoan"
                        >
                          Forgivable Secured Loan
                          {down_payment_sources === "ForgivableSecuredLoan" ? (
                            <span className="tick-mark2">✔</span>
                          ) : null}
                        </option>

                        <option
                          className="ng-tns-c161-6 ng-star-inserted"
                          value="Grant"
                        >
                          Grant
                          {down_payment_sources === "Grant" ? (
                            <span className="tick-mark2">✔</span>
                          ) : null}
                        </option>

                        <option
                          className="ng-tns-c161-6 ng-star-inserted"
                          value="HousingRelocation"
                        >
                          Housing Relocation
                          {down_payment_sources === "HousingRelocation" ? (
                            <span className="tick-mark2">✔</span>
                          ) : null}
                        </option>

                        <option
                          className="ng-tns-c161-6 ng-star-inserted"
                          value="LifeInsuranceCashValue"
                        >
                          Life Insurance Cash Value
                          {down_payment_sources === "LifeInsuranceCashValue" ? (
                            <span className="tick-mark2">✔</span>
                          ) : null}
                        </option>

                        <option
                          className="ng-tns-c161-6 ng-star-inserted"
                          value="LotEquity"
                        >
                          LotEquity
                          {down_payment_sources === "LotEquity" ? (
                            <span className="tick-mark2">✔</span>
                          ) : null}
                        </option>

                        <option
                          className="ng-tns-c161-6 ng-star-inserted"
                          value="19: MortgageCreditCertificates"
                        >
                          Mortgage Credit Certificates
                          {down_payment_sources ===
                          "19: MortgageCreditCertificates" ? (
                            <span className="tick-mark2">✔</span>
                          ) : null}
                        </option>

                        <option
                          className="ng-tns-c161-6 ng-star-inserted"
                          value="MortgageRevenueBond"
                        >
                          Mortgage Revenue Bond
                          {down_payment_sources === "MortgageRevenueBond" ? (
                            <span className="tick-mark2">✔</span>
                          ) : null}
                        </option>

                        <option
                          className="ng-tns-c161-6 ng-star-inserted"
                          value="OtherEquity"
                        >
                          Other Equity
                          {down_payment_sources === "OtherEquity" ? (
                            <span className="tick-mark2">✔</span>
                          ) : null}
                        </option>

                        <option
                          className="ng-tns-c161-6 ng-star-inserted"
                          value="PledgedCollateral"
                        >
                          Pledged Collateral
                          {down_payment_sources === "PledgedCollateral" ? (
                            <span className="tick-mark2">✔</span>
                          ) : null}
                        </option>

                        <option
                          className="ng-tns-c161-6 ng-star-inserted"
                          value="PremiumFunds"
                        >
                          Premium Funds
                          {down_payment_sources === "PremiumFunds" ? (
                            <span className="tick-mark2">✔</span>
                          ) : null}
                        </option>

                        <option
                          className="ng-tns-c161-6 ng-star-inserted"
                          value="RentWithOptionToPurchase"
                        >
                          Rent With Option To Purchase
                          {down_payment_sources ===
                          "RentWithOptionToPurchase" ? (
                            <span className="tick-mark2">✔</span>
                          ) : null}
                        </option>

                        <option
                          className="ng-tns-c161-6 ng-star-inserted"
                          value="RetirementFunds"
                        >
                          Retirement Funds
                          {down_payment_sources === "RetirementFunds" ? (
                            <span className="tick-mark2">✔</span>
                          ) : null}
                        </option>

                        <option
                          className="ng-tns-c161-6 ng-star-inserted"
                          value="SaleOfChattel"
                        >
                          Sale Of Chattel
                          {down_payment_sources === "SaleOfChattel" ? (
                            <span className="tick-mark2">✔</span>
                          ) : null}
                        </option>

                        <option
                          className="ng-tns-c161-6 ng-star-inserted"
                          value="SalesPriceAdjustment"
                        >
                          Sales Price Adjustment
                          {down_payment_sources === "SalesPriceAdjustment" ? (
                            <span className="tick-mark2">✔</span>
                          ) : null}
                        </option>

                        <option
                          className="ng-tns-c161-6 ng-star-inserted"
                          value="SecondaryFinancing"
                        >
                          Secondary Financing
                          {down_payment_sources === "SecondaryFinancing" ? (
                            <span className="tick-mark2">✔</span>
                          ) : null}
                        </option>

                        <option
                          className="ng-tns-c161-6 ng-star-inserted"
                          value="SecuredLoan"
                        >
                          Secured Loan
                          {down_payment_sources === "SecuredLoan" ? (
                            <span className="tick-mark2">✔</span>
                          ) : null}
                        </option>

                        <option
                          className="ng-tns-c161-6 ng-star-inserted"
                          value="30: SweatEquity"
                        >
                          Sweat Equity
                          {down_payment_sources === "30: SweatEquity" ? (
                            <span className="tick-mark2">✔</span>
                          ) : null}
                        </option>

                        <option
                          className="ng-tns-c161-6 ng-star-inserted"
                          value="TradeEquity"
                        >
                          Trade Equity
                          {down_payment_sources === "TradeEquity" ? (
                            <span className="tick-mark2">✔</span>
                          ) : null}
                        </option>

                        <option
                          className="ng-tns-c161-6 ng-star-inserted"
                          value="TrustFunds"
                        >
                          Trust Funds
                          {down_payment_sources === "TrustFunds" ? (
                            <span className="tick-mark2">✔</span>
                          ) : null}
                        </option>

                        <option
                          className="ng-tns-c161-6 ng-star-inserted"
                          value="UnsecuredBorrowedFunds"
                        >
                          Unsecured Borrowed Funds
                          {down_payment_sources === "UnsecuredBorrowedFunds" ? (
                            <span className="tick-mark2">✔</span>
                          ) : null}
                        </option>
                      </select>
                    </div>
                    <div className="position-relative">
                      <input
                        className="form-control text-capitalize  mt-2 ps-3"
                        type="number"
                        required
                        onChange={(e) =>
                          setDown_Payment_sources_amount(e.target.value)
                        }
                        value={down_payment}
                      />
                      <BiDollar
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          position: "absolute",
                          left: "2px",
                          bottom: "12px",
                          zIndex: "123",
                        }}
                      />
                    </div>
                  </div>
                  {bund?.down_payment_sources
                    ? bund?.down_payment_sources.map((e) => (
                        <p className="text-danger">{e}</p>
                      ))
                    : null}
                  {bund?.down_payment_sources_amount
                    ? bund?.down_payment_sources_amount.map((e) => (
                        <p className="text-danger">{e}</p>
                      ))
                    : null}
                </div>
                <div className="col-md-4 mt-4">
                  <label className="h6 text-primary mt-2">
                    Your loan amount will be
                  </label>
                  <h2 className="text-primary bold">${currencyValue}</h2>
                </div>
                <div className="col-md-8 mt-4">
                  <h5
                    className="font266"
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "black",
                    }}
                  >
                    What is a comfortable monthly housing payment?
                  </h5>
                  <div className="input-group126 position-relative my-2">
                    <BiDollar
                      className="textHover126"
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        position: "absolute",
                        left: "10px",
                        top: "10px",
                        zIndex: "123",
                      }}
                    />
                    <input
                      className="form-control text-capitalize 123 m-0 ps-4"
                      type="text"
                      required
                      onChange={(e) => {
                        handleChange(
                          e,
                          setComfortable_monthly,
                          "comfortable_monthly"
                        );
                      }}
                      onBlur={() => steediting(true)}
                      onFocus={() => steediting(false)}
                      value={
                        comfortable_monthly !== undefined
                          ? comfortable_monthly
                          : ""
                      }
                    />
                    {comfortable_monthly?.length < 4 ||
                    comfortable_monthly === "0" ? null : (
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          position: "absolute",
                          color: "green",
                          right: "18px",
                          top: "8px",
                          zIndex: "123",
                        }}
                      >
                        ✔
                      </p>
                    )}
                    {bund?.comfortable_monthly_ho_payment
                      ? bund?.comfortable_monthly_ho_payment.map((e) => (
                          <p className="text-danger">{e}</p>
                        ))
                      : null}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mt-4">
                    <h5
                      className="font266"
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "black",
                      }}
                    >
                      Property Type
                    </h5>
                    <div className="input-group126 position-relative my-2">
                      <select
                        required
                        onChange={(e) => setPropertyType(e.target.value)}
                        value={propertyType}
                        className={`form-select py-2 text-primary rounded-0 ${
                          propertyType === undefined ||
                          propertyType === null ||
                          propertyType === ""
                            ? "text-dark"
                            : "text-success"
                        }`}
                      >
                        <option selected="" disabled="" value="">
                          Select Property Type
                        </option>
                        <option value={"Residential"}>
                          {" "}
                          Residential
                          {propertyType === "Residential" ? (
                            <span className="tick-mark2">✔</span>
                          ) : null}
                        </option>
                        <option value={"Commercial"}>
                          {" "}
                          Commercial
                          {propertyType === "Commercial" ? (
                            <span className="tick-mark2">✔</span>
                          ) : null}
                        </option>
                      </select>
                    </div>
                  </div>
                  {propertyType === "Residential" ? (
                    <div className="col-md-6 mt-4">
                      <h5
                        className="font266"
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "black",
                        }}
                      >
                        Residential Property Sub Type
                      </h5>
                      <div className="input-group126 position-relative my-2">
                        <select
                          required
                          onChange={(e) => setResidentalType(e.target.value)}
                          value={residentialType}
                          className={`form-select py-2 text-primary rounded-0 ${
                            residentialType === undefined ||
                            residentialType === null ||
                            residentialType === ""
                              ? "text-dark"
                              : "text-success"
                          }`}
                        >
                          <option selected="" disabled="" value="">
                            Select Property Type
                          </option>
                          <option value={"Residential"}>
                            {" "}
                            Residential
                            {residentialType === "Residential" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Residential Income/Rental Property"}>
                            {" "}
                            Residential Income/Rental Property
                            {residentialType ===
                            "Residential Income/Rental Property" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Residential Subdivision"}>
                            {" "}
                            Residential Subdivision
                            {residentialType === "Residential Subdivision" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Restaurant"}>
                            {" "}
                            Restaurant
                            {residentialType === "Restaurant" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Retail"}>
                            {" "}
                            Retail
                            {residentialType === "Retail" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Retail - Anchored"}>
                            {" "}
                            Retail - Anchored
                            {residentialType === "Retail - Anchored" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Retail - Single Tenant"}>
                            {" "}
                            Retail - Single Tenant
                            {residentialType === "Retail - Single Tenant" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Retail - Strip Center"}>
                            {" "}
                            Retail - Strip Center
                            {residentialType === "Retail - Strip Center" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"School"}>
                            {" "}
                            School
                            {residentialType === "School" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Self Storage"}>
                            {" "}
                            Self Storage
                            {residentialType === "Self Storage" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Special Purpose"}>
                            {" "}
                            Special Purpose
                            {residentialType === "Special Purpose" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                        </select>
                      </div>
                    </div>
                  ) : null}
                  {propertyType === "Commercial" ? (
                    <div className="col-md-6 mt-4">
                      <h5
                        className="font266"
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "black",
                        }}
                      >
                        Commercial Property Sub Type
                      </h5>
                      <div className="input-group126 position-relative my-2">
                        <select
                          required
                          onChange={(e) => setCommercialType(e.target.value)}
                          value={commercialType}
                          className={`form-select py-2 text-primary rounded-0 ${
                            commercialType === undefined ||
                            commercialType === null ||
                            commercialType === ""
                              ? "text-dark"
                              : "text-success"
                          }`}
                        >
                          <option selected="" disabled="" value={""}>
                            Select Property Type
                          </option>
                          <option value={"Agricultural"}>
                            {" "}
                            Agricultural
                            {commercialType === "Agricultural" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Apartment - Affordable"}>
                            {" "}
                            Apartment - Affordable
                            {commercialType === "Apartment - Affordable" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Apartment - Conventional"}>
                            {" "}
                            Apartment - Conventional
                            {commercialType === "Apartment - Conventional" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Apartment - Senior Independent"}>
                            {" "}
                            Apartment - Senior Independent
                            {commercialType ===
                            "Apartment - Senior Independent" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Apartment - Student Housing"}>
                            {" "}
                            Apartment - Student Housing
                            {commercialType ===
                            "Apartment - Student Housing" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Apartments"}>
                            {" "}
                            Apartments
                            {commercialType === "Apartments" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Assisted Living & Nursing Homes"}>
                            {" "}
                            Assisted Living & Nursing Homes
                            {commercialType ===
                            "Assisted Living & Nursing Homes" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Auto Dealership"}>
                            {" "}
                            Auto Dealership
                            {commercialType === "Auto Dealership" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Auto Repair"}>
                            {" "}
                            Auto Repair
                            {commercialType === "Auto Repair" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Bar/Tavern"}>
                            {" "}
                            Bar/Tavern
                            {commercialType === "Bar/Tavern" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Bowling Alley"}>
                            {" "}
                            Bowling Alley
                            {commercialType === "Bowling Alley" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Car Wash"}>
                            {" "}
                            Car Wash
                            {commercialType === "Car Wash" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Church"}>
                            {" "}
                            Church
                            {commercialType === "Church" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Commercial Construction"}>
                            {" "}
                            Commercial Construction
                            {commercialType === "Commercial Construction" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Condominiums/Townhomes"}>
                            {" "}
                            Condominiums/Townhomes
                            {commercialType === "Condominiums/Townhomes" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Day Care"}>
                            {" "}
                            Day Care
                            {commercialType === "Day Care" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Entertainment/Recreational"}>
                            {" "}
                            Entertainment/Recreational
                            {commercialType === "Entertainment/Recreational" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Fitness Center"}>
                            {" "}
                            Fitness Center
                            {commercialType === "Fitness Center" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Funeral Home"}>
                            {" "}
                            Funeral Home
                            {commercialType === "Funeral Home" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Gas Station"}>
                            {" "}
                            Gas Station
                            {commercialType === "Gas Station" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Golf Course"}>
                            {" "}
                            Golf Course
                            {commercialType === "Golf Course" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"HEALTHCARE"}>
                            {" "}
                            HEALTHCARE
                            {commercialType === "HEALTHCARE" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Hospitality"}>
                            {" "}
                            Hospitality
                            {commercialType === "Hospitality" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Hospitality - Flagged"}>
                            {" "}
                            Hospitality - Flagged
                            {commercialType === "Hospitality - Flagged" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Hospitality - Independent"}>
                            {" "}
                            Hospitality - Independent
                            {commercialType === "Hospitality - Independent" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"HOTEL"}>
                            {" "}
                            HOTEL
                            {commercialType === "HOTEL" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Housing"}>
                            {" "}
                            Housing
                            {commercialType === "Housing" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Industrial"}>
                            {" "}
                            Industrial
                            {commercialType === "Industrial" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Land/Lot"}>
                            {" "}
                            Land/Lot
                            {commercialType === "Land/Lot" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Marina"}>
                            {" "}
                            Marina
                            {commercialType === "Marina" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Manufactured Housing"}>
                            {" "}
                            Manufactured Housing
                            {commercialType === "Manufactured Housing" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Manufactured/Mobile Home Park"}>
                            {" "}
                            Manufactured/Mobile Home Park
                            {commercialType ===
                            "Manufactured/Mobile Home Park" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Medical - Assisted Living"}>
                            {" "}
                            Medical - Assisted Living
                            {commercialType === "Medical - Assisted Living" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Medical - Hospital"}>
                            {" "}
                            Medical - Hospital
                            {commercialType === "Medical - Hospital" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Medical - Skilled Nursing"}>
                            {" "}
                            Medical - Skilled Nursing
                            {commercialType === "Medical - Skilled Nursing" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Medical/Healthcare"}>
                            {" "}
                            Medical/Healthcare
                            {commercialType === "Medical/Healthcare" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Mixed Use"}>
                            {" "}
                            Mixed Use
                            {commercialType === "Mixed Use" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"MULTIFAMILY"}>
                            {" "}
                            MULTIFAMILY
                            {commercialType === "MULTIFAMILY" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Nursing Home"}>
                            {" "}
                            Nursing Home
                            {commercialType === "Nursing Home" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Office"}>
                            {" "}
                            Office
                            {commercialType === "Office" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Office - General"}>
                            {" "}
                            Office - General
                            {commercialType === "Office - General" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Office - Medical"}>
                            {" "}
                            Office - Medical
                            {commercialType === "Office - Medical" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Other"}>
                            {" "}
                            Other
                            {commercialType === "Other" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value={"Religious"}>
                            {" "}
                            Religious
                            {commercialType === "Religious" ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                        </select>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="col-md-3 mt-4">
                  <h5
                    className="font266"
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "black",
                    }}
                  >
                    How will you be using this property?
                  </h5>
                  <div className="input-group126 position-relative my-2">
                    <select
                      required
                      onChange={(e) => setUse_of_property(e.target.value)}
                      value={use_of_property}
                      className={`form-select py-2 text-primary rounded-0 ${
                        use_of_property === undefined ||
                        use_of_property === null ||
                        use_of_property === ""
                          ? "text-dark"
                          : "text-success"
                      }`}
                    >
                      <option selected="" disabled="" value={""}>
                        Select Occupancy
                      </option>
                      <option value={"Owner Occupied"}>
                        {" "}
                        Owner Occupied
                        {use_of_property === "Owner Occupied" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value={"None Owner"}>
                        {" "}
                        None Owner
                        {use_of_property === "None Owner" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value={"Vacation Home"}>
                        {" "}
                        Vacation Home
                        {use_of_property === "Vacation Home" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value={"Investment"}>
                        {" "}
                        Investment
                        {use_of_property === "Investment" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-md-3 mt-4">
                  <h5
                    className="font266"
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "black",
                    }}
                  >
                    Operate Buisness On Property
                  </h5>
                  <div className="input-group126 position-relative my-2">
                    <select
                      required
                      onChange={(e) => setOperateBusiness(e.target.value)}
                      value={operateBusiness}
                      className={`form-select py-2 text-primary rounded-0 ${
                        operateBusiness === undefined ||
                        operateBusiness === null ||
                        operateBusiness === ""
                          ? "text-dark"
                          : "text-success"
                      }`}
                    >
                      <option selected="" disabled="" value={""}>
                        Select Operate Buisness
                      </option>
                      <option value={"Yes"}>
                        {" "}
                        Yes
                        {operateBusiness === "Yes" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                      <option value={"No"}>
                        {" "}
                        No
                        {operateBusiness === "No" ? (
                          <span className="tick-mark2">✔</span>
                        ) : null}
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-md-3 mt-4">
                  <h5
                    className="font266"
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "black",
                    }}
                  >
                    No of Units
                  </h5>
                  <div className="input-group126 position-relative my-2 mt-4">
                    <input
                      required
                      onChange={(e) => setNoUnits(e.target.value)}
                      className="form-control text-capitalize 123 m-0 ps-4"
                      type="number"
                      value={noUnits}
                    />
                    {noUnits?.length > 4 ? (
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          position: "absolute",
                          color: "green",
                          right: "18px",
                          top: "8px",
                          zIndex: "123",
                        }}
                      >
                        ✔
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-3 mt-4">
                  <h5
                    className="font266"
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "black",
                    }}
                  >
                    Description
                  </h5>
                  <div className="input-group126 position-relative my-2 mt-4">
                    <input
                      required
                      onChange={(e) => setDescription(e.target.value)}
                      className="form-control text-capitalize 123 m-0 ps-4"
                      type="text"
                      value={description}
                    />
                    {description?.length > 4 ? (
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          position: "absolute",
                          color: "green",
                          right: "18px",
                          top: "8px",
                          zIndex: "123",
                        }}
                      >
                        ✔
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6 my-2">
                  <h5
                    className="font266"
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "black",
                    }}
                  >
                    Does this Home have solar panels installed?
                  </h5>
                  <div className="stateagent mt-2">
                    <div className="d-flex gap-4">
                      <div>
                        <input
                          type="radio"
                          className="btn-check"
                          id="solarinstallyes"
                          name="install"
                          defaultChecked={
                            solarInstall ? "btn btn-outline-primary" : null
                          }
                          onChange={() => setSolarInstall(true)}
                        />
                        <label
                          className="btn px-3 py-2 btnx404 btn-link rounded-0"
                          for="solarinstallyes"
                        >
                          Yes {solarInstall ? "✔" : null}
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          className="btn-check"
                          id="solarinstallno"
                          name="install"
                          onClick={() => setSolarInstall(false)}
                          defaultChecked={
                            solarInstall ? null : "btn btn-outline-primary"
                          }
                        />
                        <label
                          className="btn px-3 py-2 btnx404 btn-link rounded-0"
                          for="solarinstallno"
                        >
                          No {solarInstall ? null : "✔"}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 my-2">
                  <h5
                    className="font266"
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "black",
                    }}
                  >
                    Solar Panels included in sale price?
                  </h5>
                  <div className="stateagent mt-2">
                    <div className="d-flex gap-4">
                      <div>
                        <input
                          type="radio"
                          className="btn-check"
                          id="solarpriceyes"
                          name="price"
                          defaultChecked={
                            solarPrice ? "btn btn-outline-primary" : null
                          }
                          onChange={() => setSolarPrice(true)}
                        />
                        <label
                          className="btn px-3 py-2 btnx404 btn-link rounded-0"
                          for="solarpriceyes"
                        >
                          Yes {solarPrice ? "✔" : null}
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          className="btn-check"
                          id="solarpriceno"
                          name="price"
                          onClick={() => setSolarPrice(false)}
                          defaultChecked={
                            solarPrice ? null : "btn btn-outline-primary"
                          }
                        />
                        <label
                          className="btn px-3 py-2 btnx404 btn-link rounded-0"
                          for="solarpriceno"
                        >
                          No {solarPrice ? null : "✔"}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 my-2">
                  <h5
                    className="font266"
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "black",
                    }}
                  >
                    Do you want your tax & Insurance to be included in your
                    payments?
                  </h5>
                  <div className="stateagent mt-2">
                    <div className="d-flex gap-4">
                      <div>
                        <input
                          type="radio"
                          className="btn-check"
                          id="solarpaymentyes"
                          name="payment"
                          defaultChecked={
                            solarPayment ? "btn btn-outline-primary" : null
                          }
                          onChange={() => setSolarPayment(true)}
                        />
                        <label
                          className="btn px-3 py-2 btnx404 btn-link rounded-0"
                          for="solarpaymentyes"
                        >
                          Yes {solarPayment ? "✔" : null}
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          className="btn-check"
                          id="solarpaymentno"
                          name="payment"
                          onClick={() => setSolarPayment(false)}
                          defaultChecked={
                            solarPayment ? null : "btn btn-outline-primary"
                          }
                        />
                        <label
                          className="btn px-3 py-2 btnx404 btn-link rounded-0"
                          for="solarpaymentno"
                        >
                          No {solarPayment ? null : "✔"}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 my-2">
                  <h5
                    className="font266"
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "black",
                    }}
                  >
                    Are you currently working with a real estate agent?
                  </h5>
                  <div className="stateagent mt-2">
                    <div className="d-flex gap-4">
                      <div>
                        <input
                          type="radio"
                          className="btn-check"
                          id="males"
                          name="agent"
                          defaultChecked={
                            real_estate_agent ? "btn btn-outline-primary" : null
                          }
                          onChange={() => setReal_estate_agent(true)}
                        />
                        <label
                          className="btn px-3 py-2 btnx404 btn-link rounded-0"
                          for="males"
                        >
                          Yes {real_estate_agent ? "✔" : null}
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          className="btn-check"
                          id="maless"
                          name="agent"
                          onClick={() => setReal_estate_agent(false)}
                          defaultChecked={
                            real_estate_agent ? null : "btn btn-outline-primary"
                          }
                        />
                        <label
                          className="btn px-3 py-2 btnx404 btn-link rounded-0"
                          for="maless"
                        >
                          No {real_estate_agent ? null : "✔"}
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* {real_estate_agent == 1 ? (
                    <div className="agent_details col-md-10 position-relative">
                      <label className="h6 text-muted mt-3 mb-1">
                        Please provide agent details
                      </label>

                      <div className="input-group mt-2">
                        <span className="input-group-label width-135">
                          Email ID
                        </span>
                        <input
                          type="email"
                          name="agent_email_id"
                          id="agent_email_id"
                          placeholder="Required"
                          className="form-control text-capitalize  text-lowercase col-md-8 text-capitalize"
                          value={agent_email_id}
                          onChange={(e) => setAgent_email_id(e.target.value)}
                        />
                      </div>
                      {agent_email_id?.length < 4 ? null : (
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            position: "absolute",
                            color: "green",
                            right: "24px",
                            bottom: "134px",
                            zIndex: "123",
                          }}
                        >
                          ✔
                        </p>
                      )}
                      {bund?.agent_email_id
                        ? bund?.agent_email_id.map((e) => (
                            <p className="text-danger">{e}</p>
                          ))
                        : null}
                      <div className="input-group mt-2">
                        <span className="input-group-label width-135">
                          Full Name
                        </span>
                        <input
                          type="text"
                          placeholder="Required"
                          className="form-control text-capitalize  text-lowercase col-md-6 text-capitalize"
                          onChange={(e) => setAgent_full_name(e.target.value)}
                          value={agent_full_name}
                        />
                      </div>
                      {agent_full_name?.length < 4 ? null : (
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            position: "absolute",
                            color: "green",
                            right: "24px",
                            bottom: "86px",
                            zIndex: "123",
                          }}
                        >
                          ✔
                        </p>
                      )}
                      {bund?.agent_full_name
                        ? bund?.agent_full_name.map((e) => (
                            <p className="text-danger">{e}</p>
                          ))
                        : null}
                      <div className="input-group mt-2">
                        <span className="input-group-label width-135">
                          Cell Phone
                        </span>
                        <input
                          type="text"
                          placeholder="Required"
                          className="form-control text-capitalize  text-lowercase col-md-6"
                          onChange={(e) => {
                            handleChange(e, setAgent_cell, "agent_cell");
                          }}
                          onBlur={() => steediting(true)}
                          onFocus={() => steediting(false)}
                          value={`${agent_cell === undefined ? 0 : agent_cell}${
                            editing === true ? ".00" : ""
                          }`}
                          pattern="^[\d,]+$"
                        />
                      </div>
                      {agent_cell?.length < 4 || agent_cell === "0" ? null : (
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            position: "absolute",
                            color: "green",
                            right: "24px",
                            bottom: "36px",
                            zIndex: "123",
                          }}
                        >
                          ✔
                        </p>
                      )}
                      {bund?.agent_cell
                        ? bund?.agent_cell.map((e) => (
                            <p className="text-danger">{e}</p>
                          ))
                        : null}
                      <div className="input-group mt-2">
                        <span className="input-group-label width-135">
                          Company
                        </span>
                        <input
                          type="text"
                          defaultValue={agent_company}
                          onChange={(e) => setAgent_company(e.target.value)}
                          placeholder="Required"
                          className="form-control text-capitalize  text-lowercase col-md-6 text-capitalize"
                        />
                      </div>
                      {agent_company?.length < 4 ? null : (
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            position: "absolute",
                            color: "green",
                            right: "24px",
                            bottom: "-10px",
                            zIndex: "123",
                          }}
                        >
                          ✔
                        </p>
                      )}
                      {bund?.agent_company
                        ? bund?.agent_company.map((e) => (
                            <p className="text-danger">{e}</p>
                          ))
                        : null}
                    </div>
                  ) : null} */}
                </div>

                <div className="col-md-4 my-4">
                  <label className="text-secondary h6">
                    Next is <span className="text-dark">Personal Info</span>
                  </label>
                  <button className="btn btn-primary rounded" type="submit">
                    Save And Continue &nbsp;
                    <AiOutlineArrowRight />
                  </button>
                </div>
                <div className="border-bottom"></div>
                <img src={footer} alt="img" width="96%" />
              </div>
            </div>
            <ProfileInfo />
          </div>
        </div>
      </form>
    </>
  );
};

export default Mortageinfo;
