/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Header from "../Layout/Header";
import Table from "react-bootstrap/Table";
import ProfileInfo from "./mortage_type/Profile/ProfileInfo";
import { FaArrowRight, FaEye, FaFileInvoice } from "react-icons/fa";
import Swal from "sweetalert2";
import axios from "axios";
import Baseurl from "../../Baseurl";
import { useHistory } from "react-router-dom";
import SidebarDash from "./mortage_type/heloc/Tanent/SidebarDash";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";

function Dashboard() {
  const history = useHistory();
  const Assign_id = localStorage.getItem("borreower_id");
  const [loader, setLoader] = useState(false);
  const [fieldsError, setFieldsError] = useState();
  const [dataFromREsponse, setDataFromResponse] = useState({});
  const { mortgage_purchase, mortgage_refinance, real_estate_rent__tenant } = dataFromREsponse;
  const Data = new FormData();
  Data.append("Application_id", Assign_id);
  const [Document_id, setDocument_id] = useState([]);
  const handleCheckbox = (id) => {
    if (id !== null && id !== undefined) {
      const isIdInArray = Document_id.includes(id);

      if (isIdInArray) {
        setDocument_id(Document_id.filter((documentId) => documentId !== id));
      } else {
        setDocument_id([...Document_id, id]);
      }
    } else {
      console.log("Invalid id:", id);
    }
  };

  if (Document_id !== undefined && Document_id !== null) {
    localStorage.setItem("Document_id", JSON.stringify(Document_id));
    console.log(Document_id, "Document_id");
  } else {
    console.log(
      "Document_id is undefined or null, not setting in localStorage"
    );
  }
  let token = localStorage.getItem("usertoken");
  const getData = () => {
    setLoader(true);
    var config = {
      method: "Get",
      url: `${Baseurl.baseurl}all/completed/applications`,
      data: Data,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then(function (response) {
        if (response?.data?.status === true) {
          Get_Profile();
          setLoader(false);
          setDataFromResponse(response?.data?.data);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setFieldsError(error?.response?.data?.errors);
      });
  };

  const Get_Profile = () => {
    setLoader(true);
    var config = {
      method: "get",
      url: `${Baseurl.baseurl}get/borrower/profile/data`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response?.data?.data, "resprofile");
        localStorage.setItem("image_path", response?.data?.data?.image_path);
        localStorage.setItem("image", response?.data?.data?.image);
        if (response?.data?.status === true) {
          setLoader(false);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        Swal.fire({
          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };

  useEffect(() => {
    Get_Profile();
    getData();
  }, []);

  const user = JSON.parse(localStorage.getItem("userDetail"));

  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <Header />
      <hr />
      <div
        className="container-fluid ps-4"
        style={{ position: "fixed", zIndex: "-9999" }}
      >
        <div className="row">
          <SidebarDash />
          <div className="col-md-8 new-mr1">
            <div className="row">
              <div className="col-md-8 mt-3">
                <h4 className="text-black font-weight-bold" style={{ cursor: "pointer" }}>
                 Hi {user?.first_name + " " + user?.last_name}
                </h4>
              </div>
              <div className="col-md-8 d-flex align-items-baseline">
                <a href="/selectType" className="btn btn-primary px-4 me-2">
                  New <FaArrowRight />
                </a>
                <p className="text-center" style={{ cursor: "pointer" }}>
                  Click New to start a new transaction
                </p>
              </div>
              <div className="col-md-8 mt-2">
                <p style={{ cursor: "pointer" }}>
                  Transactions you have already started - Click on Resume to
                  finalize
                </p>
              </div>
            </div>
            <div className="row" style={{ overflow: "auto", width: "100%" }}>
              <Table striped bordered hover className="m-2">
                <thead>
                  <tr>
                    <th className="text-center" style={{ minWidth: "140px" }}>
                      #
                    </th>
                    <th className="text-center" style={{ minWidth: "140px" }}>
                      Transaction Type
                    </th>
                    <th className="text-center" style={{ minWidth: "140px" }}>
                      Prop Type
                    </th>
                    <th className="text-center" style={{ minWidth: "140px" }}>
                      Value
                    </th>
                    <th className="text-center" style={{ minWidth: "140px" }}>
                      LTV
                    </th>
                    <th className="text-center" style={{ minWidth: "140px" }}>
                      Loan Amount
                    </th>
                    <th className="text-center" style={{ minWidth: "140px" }}>
                      Loan Program
                    </th>
                    <th className="text-center" style={{ minWidth: "140px" }}>
                      Occupancy
                    </th>
                    <th className="text-center" style={{ minWidth: "140px" }}>
                      Purpose
                    </th>
                    <th className="text-center" style={{ minWidth: "140px" }}>
                      Resume
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {mortgage_purchase
                    ? mortgage_purchase?.map((e, i) => {
                        const date = new Date(e?.created_at.toString());
                        const options = {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        };
                        const formattedDate = date.toLocaleString(
                          "en-US",
                          options
                        );
                        return (
                          <>
                            <tr>
                              <td className="text-center">{e?.id}</td>
                              <td className="text-center">Purchase</td>
                              <td className="text-center">{formattedDate}</td>
                              <td>
                                <p
                                  className="text-center"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    history.push(
                                      `/reviewapplication/review/${e.id}/mpa`
                                    )
                                  }
                                >
                                  View <FaEye />
                                </p>
                              </td>
                              <td className="text-center">
                                {e?.documentation?.borrower_signature === null
                                  ? "No"
                                  : "yes"}
                              </td>
                              <td>
                                <div className="text-center">
                                  {e?.documentation?.broker_doc_id
                                    ? "Yes"
                                    : "No"}
                                </div>
                              </td>
                              <td>
                                <div className="text-center">
                                  {e?.documentation?.broker_doc_id
                                    ? "Yes"
                                    : "No"}
                                </div>
                              </td>
                              <td>
                                <div className="text-center">
                                  <Form.Check
                                    inline
                                    label="Add Signature"
                                    onClick={() =>
                                      handleCheckbox(
                                        e?.documentation?.broker_doc_id
                                      )
                                    }
                                    type="checkbox"
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="text-center">
                                  <Link to={`/Borrower/Documents/${e?.id}`}>
                                    <button className="btn btn-primary shadow btn-xs sharp">
                                      <FaFileInvoice />
                                    </button>
                                  </Link>
                                </div>
                              </td>
                              <td>
                                <div className="text-center">
                                  <a to={`javascript:void(0)`}>
                                    <button className="btn btn-primary shadow btn-xs sharp">
                                      Resume
                                    </button>
                                  </a>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    : null}
                  {/* {mortgage_refinance
                    ? mortgage_refinance?.map((e, i) => {
                        const date = new Date(e?.created_at.toString());
                        const options = {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        };
                        const formattedDate = date.toLocaleString(
                          "en-US",
                          options
                        );
                        return (
                          <>
                            <tr>
                              <td className="text-center">{e?.id}</td>
                              <td className="text-center">Refinance</td>
                              <td className="text-center">{formattedDate}</td>
                              <td>
                                <p
                                  className="text-center"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    history.push(
                                      `/reviewapplication/review/${e.id}/mra`
                                    )
                                  }
                                >
                                  View <FaEye />
                                </p>
                              </td>
                              <td className="text-center">
                                {e?.documentation?.borrower_signature === null
                                  ? "No"
                                  : "yes"}
                              </td>
                              <td>
                                <div className="text-center">Yes</div>
                              </td>
                              <td>
                                <div className="text-center">
                                  {e?.documentation?.broker_doc_id === null
                                    ? "No"
                                    : "Yes"}
                                </div>
                              </td>
                              <td>
                                <div className="text-center">
                                  <Form.Check
                                    inline
                                    label="Add Signature"
                                    onClick={() =>
                                      handleCheckbox(
                                        e?.documentation?.broker_doc_id
                                      )
                                    }
                                    type="checkbox"
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="text-center">
                                  <Link to={`/Borrower/Documents/${e?.id}`}>
                                    <button className="btn btn-primary shadow btn-xs sharp">
                                      <FaFileInvoice />
                                    </button>
                                  </Link>
                                </div>
                              </td>
                              <td>
                                <div className="text-center">
                                  <Link to={`javascript:void(0)`}>
                                    <button className="btn btn-primary shadow btn-xs sharp">
                                      Resume
                                    </button>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    : null} */}
                  {/* {real_estate_rent__tenant
                    ? real_estate_rent__tenant?.map((e, i) => {
                        const date = new Date(e?.created_at.toString());
                        const options = {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        };
                        const formattedDate = date.toLocaleString(
                          "en-US",
                          options
                        );
                        return (
                          <>
                            <tr>
                              <td className="text-center">{e?.id}</td>
                              <td className="text-center">Tenant</td>
                              <td className="text-center">{formattedDate}</td>
                              <td>
                                <p
                                  className="text-center"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    history.push(
                                      `/reviewapplication/review/${e.id}/new`
                                    )
                                  }
                                >
                                  View <FaEye />
                                </p>
                              </td>
                              <td className="text-center">
                                {e?.documentation?.borrower_signature === null
                                  ? "No"
                                  : "yes"}
                              </td>
                              <td>
                                <td>
                                  <div className="text-center">
                                    {e?.documentation?.broker_doc_id === null
                                      ? "No"
                                      : "Yes"}
                                  </div>
                                </td>
                                <td>
                                  <div className="text-center">
                                    {e?.documentation?.broker_doc_id === null
                                      ? "No"
                                      : "Yes"}
                                  </div>
                                </td>
                                <td>
                                  <div className="text-center">
                                    <Form.Check
                                      inline
                                      label="Add Signature"
                                      onClick={() =>
                                        handleCheckbox(
                                          e?.documentation?.broker_doc_id
                                        )
                                      }
                                      type="checkbox"
                                    />
                                  </div>
                                </td>
                                <div className="text-center">
                                  <Link to={`/Borrower/Documents/${e?.id}`}>
                                    <button className="btn btn-primary shadow btn-xs sharp">
                                      <FaFileInvoice />
                                    </button>
                                  </Link>
                                </div>
                              </td>
                              <td>
                                <div className="text-center">
                                  <Link to={`javascript:void(0)`}>
                                    <button className="btn btn-primary shadow btn-xs sharp">
                                      Resume
                                    </button>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    : null} */}
                </tbody>
              </Table>
            </div>
          </div>
          <ProfileInfo />
        </div>
      </div>
    </>
  );
}

export default Dashboard;
