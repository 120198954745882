/* eslint-disable eqeqeq */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Baseurl from "../../../../../Baseurl";
import Swal from "sweetalert2";
import axios from "axios";
import { useHistory } from "react-router-dom";

function Base({ setpanel }) {
  const [editing, steediting] = useState(true);
  function handleChanges(event, state, type) {
    if (type === "price_of_property") {
      steediting(false);
    } else {
      steediting(false);
    }
    const inputValue = event.target.value;
    // Remove all non-numeric characters from the input value
    const numericValue = inputValue.replace(/[^0-9]/g, "");
    // Format the numeric value using toLocaleString
    const formattedValue = Number(numericValue).toLocaleString();
    state(formattedValue);
  }

  const Assign_id = localStorage.getItem("assignId");
  const [currentEmployee, setcurrentEmployee] = useState(false);
  // const [base_employment_income,Setbase_employment_income] = useState("")
  const [current, Setcurrent] = useState(0);
  const [employee_name, SetEmployee_name] = useState("");
  const [start_date, SetStart_date] = useState("");
  const [current_emp, Set_current_emp] = useState(0);
  const [end_date, Set_end_date] = useState("");
  const [office_address, Set_office_address] = useState("");
  const [position, Setposition] = useState("");
  const [work_phone, Set_work_phone] = useState("");
  const [ext, Set_ext] = useState("");
  const [base_type, Setbase_type] = useState("");
  const [base, Set_base] = useState("");
  const [employed_family_member, Setemployed_family_member] = useState(0);
  const [bonus_type, Set_bonus_type] = useState("");
  const [commission_type, Set_commission_type] = useState("");
  const [overtime_type, Set_overtime_type] = useState("");
  const [other_type, Set_other_type] = useState("");
  const [bonus, Setbonus] = useState("");
  const [commission, Setcommission] = useState("");
  const [overtime, Set_overtime] = useState("");
  const [other, Set_other] = useState("");
  const [self_employed, Setself_employed] = useState(0);
  const [ownership_share_ratio, setownership_share_ratio] = useState("");
  const [loader, setLoader] = useState(false);
  const [showfirstform, setshowfirstform] = useState(false);
  const [co_Borrower, setCo_Borrower] = useState(false);
  const [bund, setBund] = useState("");
  const [primary, Setprimary] = useState(0);
  const [foreign_income, Set_foreign_income] = useState(0);
  const [month_profession, Set_month_profession] = useState(0);
  const [ownership_share, Set_ownership_share] = useState(0);
  const [seasonal_income, Set_seasonal_income] = useState(0);
  const [year_profession, Set_year_profession] = useState(0);
  console.log(month_profession, "Set_month_profession");

  const handlePhoneNumberChange = (event, state) => {
    let inputPhoneNumber = event.target.value.replace(/\D/g, ""); // remove non-numeric characters
    if (inputPhoneNumber.length > 10) {
      inputPhoneNumber = inputPhoneNumber.slice(0, 10); // truncate to 10 digits
    }
    let formattedPhoneNumber = "";
    if (inputPhoneNumber.length > 3) {
      formattedPhoneNumber = `(${inputPhoneNumber.substring(0, 3)})`;
      if (inputPhoneNumber.length > 6) {
        formattedPhoneNumber += ` ${inputPhoneNumber.substring(
          3,
          6
        )}-${inputPhoneNumber.substring(6)}`;
      } else {
        formattedPhoneNumber += ` ${inputPhoneNumber.substring(3)}`;
      }
    } else {
      formattedPhoneNumber = inputPhoneNumber;
    }
    state(formattedPhoneNumber);
  };
  // const [income,Setincome] = usestate()
  const Ext = ext?.replace(/\D/g, "");
  const BorrowerData = new FormData();
  BorrowerData.append("base_employment_income", 1);
  BorrowerData.append("military_employment_income", 0);
  BorrowerData.append("other_income", 0);
  BorrowerData.append("application_id", Assign_id);
  BorrowerData.append("current", current);
  BorrowerData.append("employee_name", employee_name);
  BorrowerData.append("start_date", start_date);
  BorrowerData.append("current_emp", current_emp);
  BorrowerData.append("self_employed", self_employed);
  BorrowerData.append("end_date", end_date);
  BorrowerData.append("office_address", office_address);
  BorrowerData.append("position", position);
  BorrowerData.append("work_phone", work_phone);
  BorrowerData.append("ext", Ext);
  BorrowerData.append("base_type", base_type);
  BorrowerData.append("base", base);
  BorrowerData.append("employed_family_member", employed_family_member ? 1 : 0);
  BorrowerData.append("bonus_type", bonus_type);
  BorrowerData.append("commission_type", commission_type);
  BorrowerData.append("overtime_type", overtime_type);
  BorrowerData.append("other_type", other_type);
  BorrowerData.append("bonus", bonus);
  BorrowerData.append("commission", commission);
  BorrowerData.append("overtime", overtime);
  BorrowerData.append("other", other);
  BorrowerData.append("primary", primary);
  BorrowerData.append("foreign_income", foreign_income);
  BorrowerData.append("month_profession", month_profession);
  BorrowerData.append("ownership_share", ownership_share);
  BorrowerData.append("ownership_share_ratio", ownership_share_ratio);
  BorrowerData.append("seasonal_income", seasonal_income);
  BorrowerData.append("year_profession", year_profession);

  console.log(employee_name, "employee_name");

  const AddBaseIncome = () => {
    setLoader(true);
    let token = localStorage.getItem("usertoken");
    var config = {
      method: "post",
      url: `${Baseurl.baseurl}add/income`,
      data: BorrowerData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response);
        setCo_Borrower(response?.data?.message);
        if (response?.data?.status === true) {
          window.location.reload();
          console.log(response?.data?.message, "response?.data?.message");
          setpanel("");
          setshowfirstform(false);
          setLoader(false);
          Swal.fire({
            toast: true,
            icon: "success",
            title: response?.data?.message,
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setBund(error?.response?.data?.errors);
        Swal.fire({
          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };
  function handleSelectChange(event) {
    Setbase_type(event.target.value);
    console.log(event.target.value, "event.target.value");
  }
  function handleSelectChangeForBonus(event) {
    Set_bonus_type(event.target.value);
    console.log(event.target.value, "event.target.value");
  }
  function handleSelectChangeForCommision(event) {
    Set_commission_type(event.target.value);
    console.log(event.target.value, "event.target.value");
  }
  function handleSelectChangeForOwnership_share(event) {
    Set_ownership_share(event.target.value);
    console.log(event.target.value, "event.target.value");
  }
  function handleSelectChangeForOwnership_share_type(event) {
    setownership_share_ratio(event.target.value);
    console.log(event.target.value, "event.target.value");
  }
  function handleSelectChangeForOverTime(event) {
    Set_overtime_type(event.target.value);
    console.log(event.target.value, "event.target.value");
  }
  function handleSelectChangeForOther(event) {
    Set_other_type(event.target.value);
    console.log(event.target.value, "event.target.value other");
  }
  function handleSelectChangeForSeasonal_income(event) {
    {
      event.target.checked ? Set_seasonal_income(1) : Set_seasonal_income(0);
    }
  }
  console.log(foreign_income, "foreign_income(");
  function handleSelectChangeForForiegnIncome(event) {
    {
      event.target.checked ? Set_foreign_income(1) : Set_foreign_income(0);
    }
    console.log(event.target.value, "event.target.value");
  }
  console.log(current_emp, "current_emp if");
  const handleChange = (event) => {
    if (event.target.checked) {
      Set_current_emp(1);
    } else {
      Set_current_emp(0);
    }
  };
  const handleChange1 = (event) => {
    if (event.target.checked) {
      Setcurrent(1);
    } else {
      Setcurrent(0);
      // console.log(current_emp,"current_emp else")
    }
    // setIsSubscribed(current => !current);
  };
  const handleChange2 = (event) => {
    if (event.target.checked) {
      Setself_employed(1);
    } else {
      Setself_employed(0);
    }
  };
  const handleChangeforEmployFamily = (event) => {
    if (event.target.checked) {
      Setemployed_family_member(1);
    } else {
      Setemployed_family_member(0);
    }
  };
  const handleChange0 = (event) => {
    if (event.target.checked) {
      Setprimary(1);
    } else {
      Setprimary(0);
    }
  };

  console.log(base_type, "base_type");
  const handleSubmit = (event) => {
    event.preventDefault();
  };
  const onCancel = () => {
    setpanel("");
  };

  return (
    <>
      {loader ? <div className="loader"></div> : null}

      <div className="row">
        <div className="col-md-8">
          <h4 className="mt-4 text-black">Base Employment Income</h4>
          <div className="d-flex">
            <div class="form-check">
              <input
                type="checkbox"
                class="form-check-label"
                onChange={handleChange1}
              />
              &nbsp; Current
              {/* </label> */}
            </div>
            {current == 1 ? (
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-label"
                  onChange={handleChange0}
                  checked
                />
                &nbsp; Primary
                {/* </label> */}
              </div>
            ) : null}

            <div class="form-check ms-3 ms-md-5">
              <input
                type="checkbox"
                class="form-check-label"
                onChange={handleChange2}
              />
              &nbsp; Self Employed
              {/* </label> */}
            </div>
          </div>

          <h6 className="mt-5 mb-3">EMPLOYMENT DETAILS</h6>

          <form action="" onSubmit={handleSubmit}>
            <div class="input-group mb-3">
              <span
                style={{ fontWeight: "600" }}
                class="input-group-text fontSW27 htx404"
                id="basic-addon1"
              >
                Employer Name
              </span>
              <input
                type="text"
                class="form-control rounded-0 fontSW28 text-capitalize"
                placeholder="Required"
                aria-label="Username"
                aria-describedby=""
                onChange={(e) => SetEmployee_name(e.target.value)}
              />
              {employee_name?.length < 4 ? null : (
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    position: "absolute",
                    color: "green",
                    right: "28%",
                    zIndex: "123",
                    top: "10px",
                  }}
                >
                  ✔
                </p>
              )}
              <span
                style={{ fontWeight: "600" }}
                class="input-group-text rounded-0 fontSW27 htx404"
                id="basic-addon1"
              >
                +
              </span>
            </div>
            {bund?.employee_name
              ? bund?.employee_name.map((e) => (
                  <p className="text-danger col-lg-12">{e}</p>
                ))
              : null}
            <div class="input-group mb-3">
              <span
                style={{ fontWeight: "600", maxWidth: "25%" }}
                class="input-group-text ps-3 fontSW27"
                id="basic-addon1"
              >
                Start Date
              </span>
              <input
                style={{ fontSize: "14px", fontWeight: "600" }}
                type="date"
                class="form-control rounded-0 fontSW28 "
                placeholder="Required"
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={(e) => SetStart_date(e.target.value)}
              />
              {start_date?.length < 4 ? null : (
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    position: "absolute",
                    color: "green",
                    right: "8%",
                    zIndex: "123",
                    top: "10px",
                  }}
                >
                  ✔
                </p>
              )}
            </div>
            {bund?.start_date
              ? bund?.start_date.map((e) => (
                  <p className="text-danger col-lg-12">{e}</p>
                ))
              : null}
            <div class="form-check my-2">
              <input
                type="checkbox"
                class="form-check-label"
                onChange={handleChange}
              />
              &nbsp; Current Employee?
              {/* </label> */}
            </div>
            {current == 1 ? (
              <div>
                <div class="input-group mb-3 ">
                  <span
                    style={{ fontWeight: "600", maxWidth: "25%" }}
                    className="input-group-text fontSW27"
                    id="basic-addon1"
                  >
                    Year in Profession
                  </span>
                  <input
                    type="number"
                    class="form-control rounded-0 fontSW28"
                    placeholder="Required"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    onChange={(e) => Set_year_profession(e.target.value)}
                  />
                  {year_profession?.length < 4 ? null : (
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        position: "absolute",
                        color: "green",
                        right: "2%",
                        zIndex: "123",
                        top: "10px",
                      }}
                    >
                      ✔
                    </p>
                  )}
                </div>
                {bund?.year_profession
                  ? bund?.year_profession.map((e) => (
                      <p className="text-danger col-lg-12">{e}</p>
                    ))
                  : null}
                <div class="input-group mb-3">
                  <span
                    style={{ fontWeight: "600", maxWidth: "25%" }}
                    className="input-group-text fontSW27"
                    id="basic-addon1"
                  >
                    Month in Profession
                  </span>
                  <input
                    type="number"
                    class="form-control rounded-0 input26clr"
                    placeholder="Required"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    onChange={(e) => Set_month_profession(e.target.value)}
                  />
                  {month_profession?.length < 4 ? null : (
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        position: "absolute",
                        color: "green",
                        right: "2%",
                        zIndex: "123",
                        top: "10px",
                      }}
                    >
                      ✔
                    </p>
                  )}
                </div>
                {bund?.month_profession
                  ? bund?.month_profession.map((e) => (
                      <p className="text-danger col-lg-12">{e}</p>
                    ))
                  : null}
              </div>
            ) : (
              <div class="input-group mb-3">
                <span
                  style={{ fontWeight: "600", maxWidth: "25%" }}
                  className="input-group-text fontSW27"
                  id="basic-addon1"
                >
                  End Date
                </span>
                <input
                  type="date"
                  style={{ fontWeight: "600" }}
                  class="form-control rounded-0 fontSW28"
                  placeholder="Required"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  onChange={(e) => Set_end_date(e.target.value)}
                />
                {end_date?.length < 4 ? null : (
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      position: "absolute",
                      color: "green",
                      right: "8%",
                      zIndex: "123",
                      top: "10px",
                    }}
                  >
                    ✔
                  </p>
                )}
              </div>
            )}
            <div class="input-group mb-3">
              <span
                style={{ fontWeight: "600", maxWidth: "25%" }}
                class="input-group-text fontSW27"
                id="basic-addon1"
              >
                Office Address
              </span>
              <input
                type="text"
                class="form-control fontSW28 rounded-0 input26clr text-capitalize"
                placeholder="Required"
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={(e) => Set_office_address(e.target.value)}
              />
              {office_address?.length < 4 ? null : (
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    position: "absolute",
                    color: "green",
                    right: "2%",
                    zIndex: "123",
                    top: "10px",
                  }}
                >
                  ✔
                </p>
              )}
            </div>
            {bund?.office_address
              ? bund?.office_address.map((e) => (
                  <p className="text-danger col-lg-12">{e}</p>
                ))
              : null}
            <div class="input-group mb-3">
              <span
                style={{ fontWeight: "600", maxWidth: "25%" }}
                class="input-group-text fontSW27"
                id="basic-addon1"
              >
                Position
              </span>
              <input
                type="text"
                class="form-control fontSW28 rounded-0 input26clr text-capitalize"
                placeholder="Required"
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={(e) => Setposition(e.target.value)}
              />
              {position?.length < 4 ? null : (
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    position: "absolute",
                    color: "green",
                    right: "2%",
                    zIndex: "123",
                    top: "10px",
                  }}
                >
                  ✔
                </p>
              )}
            </div>
            {bund?.position
              ? bund?.position.map((e) => (
                  <p className="text-danger col-lg-12">{e}</p>
                ))
              : null}
            <div class="input-group mb-3 position-relative">
              <span
                style={{ fontWeight: "600" }}
                class="input-group-text fontSW27"
                id="basic-addon1"
              >
                Work Phone
              </span>
              <input
                type="text"
                class="form-control fontSW28 rounded-0 input26clr"
                placeholder="(xxx) xxx-xxxx"
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={(e) => handlePhoneNumberChange(e, Set_work_phone)}
                value={work_phone}
              />
              {work_phone?.length < 14 ? null : (
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    position: "absolute",
                    color: "green",
                    right: "35%",
                    zIndex: "123",
                    top: "10px",
                  }}
                >
                  ✔
                </p>
              )}

              <input
                type="text"
                class="form-control rounded-0 input26clr"
                placeholder="Ext."
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={(e) => handlePhoneNumberChange(e, Set_ext)}
                value={ext}
              />
              {ext?.length < 14 ? null : (
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    position: "absolute",
                    color: "green",
                    right: "2%",
                    zIndex: "123",
                    top: "10px",
                  }}
                >
                  ✔
                </p>
              )}
            </div>
            <div className="d-flex justify-content-between align-items-center">
              {bund?.work_phone
                ? bund?.work_phone.map((e) => (
                    <p className="text-danger col-lg-7">{e}</p>
                  ))
                : null}

              {bund?.ext
                ? bund?.ext.map((e) => (
                    <p className="text-danger col-lg-5">{e}</p>
                  ))
                : null}
            </div>

            <h6 className="mt-5 mb-3">INCOME DETAILS</h6>

            <div class="input-group mb-3">
              <span class="input-group-text fw-bold fontSW27" id="basic-addon1">
                Base
              </span>
              <input
                type="text"
                class="form-control fontSW28  rounded-0 fw-bold fontSW28"
                placeholder="Amount Required"
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={(e) => handleChanges(e, Set_base, "base")}
                onBlur={() => steediting(true)}
                onFocus={() => steediting(false)}
                value={`${base === undefined ? 0 : base}${
                  editing === true ? ".00" : ""
                }`}
              />
              {base?.length < 4 ? null : (
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    position: "absolute",
                    color: "green",
                    right: "36%",
                    zIndex: "123",
                    top: "10px",
                  }}
                >
                  ✔
                </p>
              )}
              <select
                id="inputGroupSelect02"
                onChange={(e) => handleSelectChange(e)}
                value={base_type}
                className={`form-select fw-bold rounded-0 fontSW28  ${
                  base_type === undefined ||
                  base_type === null ||
                  base_type === ""
                    ? "text-dark"
                    : "text-success"
                }`}
              >
                <option selected disabled value="">
                  select
                </option>
                <option value="ANNUALLY">ANNUALLY</option>
                <option value="MONTHLY">MONTHLY</option>
              </select>
              <br />
            </div>
            <div className="row">
              {bund?.base
                ? bund?.base.map((e) => (
                    <p className="text-danger col-lg-6">{e}</p>
                  ))
                : null}
              {bund?.base_type
                ? bund?.base_type.map((e) => (
                    <p className="text-danger col-6  text-start">{e}</p>
                  ))
                : null}
            </div>

            {self_employed == 1 ? (
              <>
                <div class="input-group mb-3">
                  <span class="input-group-text" id="basic-addon1">
                    Overnership Share
                  </span>
                  <input
                    type="text"
                    class="form-control fontSW28  rounded-0 fw-bold fontSW28"
                    placeholder="Optional"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    onChange={(e) => handleChanges(e, handleSelectChangeForOwnership_share, "ownership_share")}
                    onBlur={() => steediting(true)}
                    onFocus={() => steediting(false)}
                    value={`${ownership_share === undefined ? 0 : ownership_share}${
                      editing === true ? ".00" : ""
                    }`}
                  />
                  {ownership_share?.length < 4 ? null : (
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        position: "absolute",
                        color: "green",
                        right: "36%",
                        zIndex: "123",
                        top: "10px",
                      }}
                    >
                      ✔
                    </p>
                  )}
                  <select
                    class="form-select rounded-0"
                    id="inputGroupSelect02"
                    onChange={(e) =>
                      handleSelectChangeForOwnership_share_type(e)
                    }
                  >
                    <option selected disabled>
                      select
                    </option>
                    <option value="25%_OR_MORE">25% OR MORE</option>
                    <option value="LESS_THAN_25%">LESS THAN 25%</option>
                  </select>
                </div>

                {bund?.ownership_share
                  ? bund?.ownership_share.map((e) => (
                      <p className="text-danger col-lg-12">{e}</p>
                    ))
                  : null}
              </>
            ) : null}
            <div class="input-group mb-3">
              <span class="input-group-text fw-bold fontSW27" id="basic-addon1">
                Bonus
              </span>
              <input
                type="text"
                class="form-control rounded-0 fw-bold fontSW28"
                placeholder="Optional"
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={(e) => handleChanges(e, Setbonus, "bonus")}
                onBlur={() => steediting(true)}
                onFocus={() => steediting(false)}
                value={`${bonus === undefined ? 0 : bonus}${
                  editing === true ? ".00" : ""
                }`}
              />
              {bonus?.length < 4 ? null : (
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    position: "absolute",
                    color: "green",
                    right: "36%",
                    zIndex: "123",
                    top: "10px",
                  }}
                >
                  ✔
                </p>
              )}
              {bund?.bonus
                ? bund?.bonus.map((e) => (
                    <p className="text-danger col-lg-12">{e}</p>
                  ))
                : null}

              <select
                class="form-select rounded-0 fw-bold fontSW28"
                id="inputGroupSelect02"
                onChange={(e) => handleSelectChangeForBonus(e)}
              >
                <option selected disabled>
                  select
                </option>
                <option value="ANNUALLY">ANNUALLY</option>
                <option value="MONTHLY">MONTHLY</option>
              </select>
            </div>
            {bund?.bonus_type
              ? bund?.bonus_type.map((e) => (
                  <p className="text-danger col-lg-12 text-end">{e}</p>
                ))
              : null}

            <div class="input-group mb-3">
              <span class="input-group-text fw-bold fontSW27" id="basic-addon1">
                Commissions
              </span>
              <input
                type="text"
                class="form-control rounded-0 fw-bold fontSW28"
                placeholder="Optional"
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={(e) => handleChanges(e, Setcommission, "commission")}
                onBlur={() => steediting(true)}
                onFocus={() => steediting(false)}
                value={`${commission === undefined ? 0 : commission}${
                  editing === true ? ".00" : ""
                }`}
              />
              {commission?.length < 4 ? null : (
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    position: "absolute",
                    color: "green",
                    right: "36%",
                    zIndex: "123",
                    top: "10px",
                  }}
                >
                  ✔
                </p>
              )}
              {bund?.commission
                ? bund?.commission.map((e) => (
                    <p className="text-danger col-lg-12">{e}</p>
                  ))
                : null}

              <select
                class="form-select rounded-0 fw-bold fontSW28"
                id="inputGroupSelect02"
                onChange={(e) => handleSelectChangeForCommision(e)}
              >
                <option selected disabled>
                  select
                </option>
                <option value="ANNUALLY">ANNUALLY</option>
                <option value="MONTHLY">MONTHLY</option>
              </select>
            </div>
            {bund?.commission_type
              ? bund?.commission_type.map((e) => (
                  <p className="text-danger col-lg-12 text-end">{e}</p>
                ))
              : null}

            <div class="input-group mb-3">
              <span class="input-group-text fw-bold fontSW27" id="basic-addon1">
                Overtime
              </span>
              <input
                type="text"
                class="form-control rounded-0 fw-bold fontSW28"
                placeholder="Optional"
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={(e) => handleChanges(e, Set_overtime, "overtime")}
                onBlur={() => steediting(true)}
                onFocus={() => steediting(false)}
                value={`${overtime === undefined ? 0 : overtime}${
                  editing === true ? ".00" : ""
                }`}
              />
              {overtime?.length < 4 ? null : (
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    position: "absolute",
                    color: "green",
                    right: "36%",
                    zIndex: "123",
                    top: "10px",
                  }}
                >
                  ✔
                </p>
              )}
              {bund?.overtime
                ? bund?.overtime.map((e) => (
                    <p className="text-danger col-lg-12">{e}</p>
                  ))
                : null}

              <select
                class="form-select rounded-0 fw-bold fontSW28"
                id="inputGroupSelect02"
                onChange={(e) => handleSelectChangeForOverTime(e)}
              >
                <option selected disabled>
                  select
                </option>
                <option value="ANNUALLY">ANNUALLY</option>
                <option value="MONTHLY">MONTHLY</option>
              </select>
            </div>
            {bund?.overtime_type
              ? bund?.overtime_type.map((e) => (
                  <p className="text-danger col-lg-12 text-end">{e}</p>
                ))
              : null}

            <div class="input-group mb-3">
              <span class="input-group-text fw-bold fontSW27" id="basic-addon1">
                Other
              </span>
              <input
                type="text"
                class="form-control rounded-0 fw-bold fontSW28"
                placeholder="Optional"
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={(e) => handleChanges(e, Set_other, "other")}
                onBlur={() => steediting(true)}
                onFocus={() => steediting(false)}
                value={`${other === undefined ? 0 : other}${
                  editing === true ? ".00" : ""
                }`}
              />
              {other?.length < 4 ? null : (
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    position: "absolute",
                    color: "green",
                    right: "36%",
                    zIndex: "123",
                    top: "10px",
                  }}
                >
                  ✔
                </p>
              )}
              {bund?.other
                ? bund?.other.map((e) => (
                    <p className="text-danger col-lg-12 text-end">{e}</p>
                  ))
                : null}

              <select
                class="form-select rounded-0 fw-bold fontSW28"
                id="inputGroupSelect02"
                onChange={(e) => handleSelectChangeForOther(e)}
              >
                <option selected disabled>
                  select
                </option>
                <option value="ANNUALLY">ANNUALLY</option>
                <option value="MONTHLY">MONTHLY</option>
              </select>
            </div>
            {bund?.other_type
              ? bund?.other_type.map((e) => (
                  <p className="text-danger col-lg-12 text-end">{e}</p>
                ))
              : null}

            {current == 1 ? (
              <div class="d-flex mt-4">
                <div className="d-flex form-check">
                  <input
                    type="checkbox"
                    class="form-check-label me-1"
                    onChange={handleSelectChangeForSeasonal_income}
                    checked
                  />
                  Seasonal Income
                </div>
                <div className="d-flex form-check me-1">
                  <input
                    type="checkbox"
                    class="form-check-label"
                    onChange={handleSelectChangeForForiegnIncome}
                    checked
                  />
                  Foreign Income
                </div>
              </div>
            ) : null}
            <div class="form-check d-flex mt-4">
              <input
                type="checkbox"
                class="form-check-label me-1"
                onChange={handleChangeforEmployFamily}
              />
              &nbsp; Employed by a family member, property seller, real estate
              agent, etc.
            </div>

            <div className="d-flex my-4">
              <button
                className="btn btn-link bg-primary text-light px-3 py-2 btnx404 rounded-0 me-2"
                onClick={() => AddBaseIncome()}
              >
                SAVE
              </button>
              <button
                className="btn btn-link px-3 py-2 btnx404 rounded-0"
                onClick={onCancel}
              >
                CLOSE
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Base;
