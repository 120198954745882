/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import Header from "../../../../Layout/Header";
import ProfileInfo from "../../Profile/ProfileInfo";
import Swal from "sweetalert2";
import axios from "axios";
import Baseurl from "../../../../../Baseurl";
import { Link, useHistory, useLocation } from "react-router-dom";
import SidebarDashNew from "./SidebarDashNew";

const TanAdditionalInfo = () => {
  const [loader, setLoader] = useState(false);
  const [allPostData, setAllPostData] = useState();
  const [bund, setBund] = useState("");

  const application_id = localStorage.getItem("assignId");
  const reviewData = new FormData();
  reviewData.append("application_id", application_id);

  const location = useLocation();
  const history = useHistory();

  const postData = () => {
    const token = localStorage.getItem("usertoken");

    var config = {
      method: "post",
      url: `${Baseurl.baseurl}real-estate/rent/tenant/progress`,
      data: reviewData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        setAllPostData(response?.data?.data);
        console.log(response?.data?.data, "sidebar response");
        if (response?.data?.status === true) {
          console.log(response?.data?.message, "response?.data?.message");
          // history.push('/new_mortage')
          setLoader(false);

          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
        // console.log(allGet, "all data");
        console.log(response, "my response");
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setBund(error?.response?.data?.errors);
        Swal.fire({
          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };
  useEffect(() => {
    postData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [isOpen, setIsOpen] = useState(true);

  const Assign_id = localStorage.getItem("assignId");

  const [id, Setid] = useState();
  const [vehicle, setVehicle] = useState(false);
  const [pet_living, setpet_living] = useState(false);

  const [smoke, setsmoke] = useState(false);
  const [smokingArea, setSmokingArea] = useState("");
  const [Vapes, setVapes] = useState("");

  const [fireArmsTF, setFireArmsTF] = useState(false);
  const [fireArms, setFireArms] = useState("");
  const [TypeWhat, setTypeWhat] = useState("");
  const [Cigrates, setCigrates] = useState("");
  const [Other, setOther] = useState("");

  const [formData, setFormData] = useState([]);
  const [vehicleNumber, setVehicleNumber] = useState(0);
  const [formFields, setFormFields] = useState([]);

  const [formDataPet, setFormDataPet] = useState([]);
  const [vehicleNumberPet, setVehicleNumberPet] = useState(0);
  const [formFieldsPet, setFormFieldsPet] = useState([]);

  const [letterOnly, setLetterOnly] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [dollarBank, setDollarBank] = useState("");

  const handleInputChange = (e) => {
    const { value } = e.target;
    const lettersOnly = value.replace(/[^a-zA-Z ]/g, "");
    setLetterOnly(lettersOnly);
  };

  const Get_Borrower = () => {
    setLoader(true);
    const Data = new FormData();
    Data.append("application_id", Assign_id);
    let token = localStorage.getItem("usertoken");
    console.log(token, "token");
    var config = {
      method: "post",
      url: `${Baseurl.baseurl}real-estate/rent/tenant/get/additional/info`,
      data: Data,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        if (response?.data?.status === true) {
          const res = response?.data?.data;
          setpet_living(res?.pet_living == 1 ? true : false);
          setVehicleNumberPet(res?.no_of_pets);
          setVehicle(res?.vehicle == 1 ? true : false);
          setVehicleNumber(res?.total_vehicles);
          setsmoke(res?.smoke == 1 ? true : false);
          setVapes(res?.smoking_type);
          setSmokingArea(res?.smoking_area);
          setFireArmsTF(res?.firearms == 1 ? true : false);
          setFireArms(res?.firearms_type);
          setTypeWhat(res?.type_in_firearms_type);
          setLetterOnly(res?.bank_name);
          setAccountNumber(res?.bank_account);
          setDollarBank(res?.bank_balance);
          Setid(res?.id);
          setFormFieldsPet(res?.pet_details?.pets);
          setFormFields(res?.vehicle_details?.vehicles);
          setLoader(false);

          Swal.fire({
            toast: true,
            icon: "success",
            title: response?.data?.message,
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setBund(error?.response?.data?.errors);
        Swal.fire({
          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };
  useEffect(() => {
    Get_Borrower();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const AddAdditionalInfo = (e) => {
    e.preventDefault();
    setLoader(true);
    const Data = new FormData();
    Data.append("application_id", Assign_id);
    Data.append("pet_living", pet_living ? 1 : 0);
    Data.append("no_of_pets", vehicleNumberPet);
    formFieldsPet?.map((pets, index) => {
      Data.append(`pets[${index}][type]`, pets?.type);
      Data.append(`pets[${index}][breed]`, pets?.breed);
      Data.append(`pets[${index}][weight]`, pets?.weight);
      Data.append(`pets[${index}][age]`, pets?.age);
      Data.append(`pets[${index}][image]`, pets?.petImage);
    });
    Data.append("vehicle", vehicle ? 1 : 0);
    Data.append("total_vehicles", vehicleNumber);
    formFields?.map((vehicles, index) => {
      Data.append(`vehicles[${index}][make]`, vehicles?.make);
      Data.append(`vehicles[${index}][model]`, vehicles?.model);
      Data.append(`vehicles[${index}][year]`, vehicles?.year);
      Data.append(`vehicles[${index}][color]`, vehicles?.color);
      Data.append(`vehicles[${index}][license]`, vehicles?.license);
      Data.append(`vehicles[${index}][image]`, vehicles?.vehicleImage);
    });
    Data.append("smoke", smoke ? 1 : 0);
    Data.append("smoking_area", smokingArea);
    Data.append("smoking_type", Vapes);

    Data.append("firearms", fireArmsTF ? 1 : 0);
    Data.append("firearms_type", fireArms);
    Data.append("type_in_firearms_type", TypeWhat);

    Data.append("bank_name", letterOnly);
    Data.append("bank_account", accountNumber);
    Data.append("bank_balance", dollarBank);
    id && Data.append("id", id);
    let token = localStorage.getItem("usertoken");
    var config = {
      method: "post",
      url: `${Baseurl.baseurl}real-estate/rent/tenant/store/additional/info`,
      data: Data,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response);
        if (response?.data?.status === true) {
          setLoader(false);
          Get_Borrower();
          Swal.fire({
            toast: true,
            icon: "success",
            title: response?.data?.message,
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          history.push("/rent/tanent/document");
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setBund(error?.response?.data?.errors);
        Swal.fire({
          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };

  // Function to update form data
  const updateFormData = (index, field, value) => {
    const newFormData = [...formData];
    newFormData[index] = { ...newFormData[index], [field]: value };
    setFormData(newFormData);
  };

  function handleChanges(event, state, type) {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9]/g, "");
    const formattedValue = Number(numericValue).toLocaleString();
    state(formattedValue);
  }

  const vehicleNumberPetInput = vehicleNumberPet > 0;
  const vehicleNumberVehicleInput = vehicleNumber > 0;
  console.log(vehicleNumberVehicleInput , 'vehicleNumberVehicleInput')

  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <Header />
      {!loader ? (
        <form  onSubmit={AddAdditionalInfo}>
        <div className="container-fluid">
          <div className="row">
          <>
            <SidebarDashNew />
          </>
            <div
              className={
                isOpen === true
                  ? "col-md-8 open he my-5 ps-lg-5"
                  : isOpen === false
                  ? "col-md-10 open nhi he my-5 ps-lg-5"
                  : ""
              }
            >
              <div className="row">
                <h3 className="text-dark3">
                  We have a few more questions for you. Almost done!
                </h3>
                <h5 className="text-dark">General Information</h5>

                <div className="row mt-2">
                  <h5 className="form-label ">
                    1) Will you have a pet living with you at this property?
                  </h5>
                  <div className="row mb-2">
                    <div className="col-md-3">
                      <div className="select-container my-2">
                        <select
                          name="estimated_credit_score"
                          formcontrolname="creditRange"
                          className={`form-control-select w-100 ${
                            pet_living ? "text-success" : "text-dark"
                          }`}
                          value={pet_living ? "Yes" : "No"}
                          onChange={(e) =>
                            setpet_living(e.target.value === "Yes")
                          }
                          onFocus={() =>
                            document
                              .querySelector(".label-new")
                              ?.classList.add("label-top")
                          }
                          onBlur={() =>
                            !pet_living &&
                            document
                              .querySelector(".label-new")
                              ?.classList.remove("label-top")
                          }
                        >
                          <option value="" disabled></option>
                          <option value="Yes" className="ng-star-inserted">
                            Yes{" "}
                            {pet_living ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value="No" className="ng-star-inserted">
                            No{" "}
                            {!pet_living ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                        </select>
                        <label
                          className={`label-new ${
                            pet_living ? "label-top" : "label-top"
                          }`}
                          htmlFor="estimated_credit_score"
                        >
                          Pet
                        </label>
                      </div>
                    </div>
                    {pet_living ? (
                      <div className="col-md-3 mt-2">
                        <div className="select-container">
                          <select
                            name="estimated_credit_score"
                            formcontrolname="creditRange"
                            className={`form-control-select w-100 ${
                              vehicleNumberPet ? "text-success" : "text-dark"
                            }`}
                            value={vehicleNumberPet}
                            onChange={(e) => {
                              const num = e.target.value;
                              setVehicleNumberPet(num);
                              setFormFieldsPet(
                                Array.from({ length: Number(num) }, () => ({
                                  type: "",
                                  breed: "",
                                  weight: "",
                                  age: "",
                                  petImage: null,
                                }))
                              );
                              setFormDataPet([]);
                            }}
                          >
                            <option value="" disabled>
                              {" "}
                            </option>
                            {[1, 2, 3, 4, 5].map((num) => (
                              <option key={num} value={num}>
                                {num}
                              </option>
                            ))}
                          </select>
                          <label
                            className={`label-new ${
                              vehicleNumberPet ? "label-top" : ""
                            }`}
                            htmlFor="estimated_credit_score"
                          >
                            No Of Pet
                          </label>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {pet_living ? (
                    <>
                      {formFieldsPet?.map((field, index) => (
                        <>
                          {/* Make Input */}
                          <div className="col-md-3 position-relative">
                            <div className="form-group-new">
                              <input
                                className="form-control-new rounded-0 text-capitalize w-100"
                                type="text"
                                placeholder=" "
                                value={field.type}
                                onChange={(e) => {
                                  const lettersOnly = e.target.value.replace(
                                    /[^a-zA-Z ]/g,
                                    ""
                                  );
                                  setFormFieldsPet((prevFields) => {
                                    const updatedFields = [...prevFields];
                                    updatedFields[index] = {
                                      ...updatedFields[index],
                                      type: lettersOnly,
                                    };
                                    return updatedFields;
                                  });
                                  updateFormData(index, "type", lettersOnly);
                                }}
                                required={vehicleNumberPetInput ? true : false}
                              />
                              <label
                                htmlFor={`type${index}`}
                                className="label-new"
                                style={{ fontSize: "14px" }}
                              >
                                Type (DOG, CAT, ETC.)
                              </label>
                              {/* Validation Check */}
                              {field.type?.length > 4 && (
                                <p
                                  style={{
                                    position: "absolute",
                                    right: "20px",
                                    top: "14px",
                                  }}
                                  className="validation-icon"
                                >
                                  ✔
                                </p>
                              )}
                            </div>
                          </div>

                          {/* Model Input */}
                          <div className="col-md-2 position-relative">
                            <div className="form-group-new">
                              <input
                                className="form-control-new rounded-0 text-capitalize w-100"
                                type="text"
                                placeholder=" "
                                value={field.breed}
                                onChange={(e) => {
                                  const lettersOnly = e.target.value.replace(
                                    /[^a-zA-Z ]/g,
                                    ""
                                  );
                                  setFormFieldsPet((prevFields) => {
                                    const updatedFields = [...prevFields];
                                    updatedFields[index] = {
                                      ...updatedFields[index],
                                      breed: lettersOnly,
                                    };
                                    return updatedFields;
                                  });
                                  updateFormData(index, "breed", lettersOnly);
                                }}
                                required={vehicleNumberPetInput ? true : false}
                              />
                              <label
                                htmlFor={`breed${index}`}
                                className="label-new"
                                style={{ fontSize: "14px" }}
                              >
                                Breed
                              </label>
                              {/* Validation Check */}
                              {field.breed?.length >= 2 && (
                                <p
                                  style={{
                                    position: "absolute",
                                    right: "20px",
                                    top: "14px",
                                  }}
                                  className="validation-icon"
                                >
                                  ✔
                                </p>
                              )}
                            </div>
                          </div>

                          {/* Color Input */}
                          <div className="col-md-2 position-relative">
                            <div className="form-group-new">
                              <input
                                className="form-control-new rounded-0 text-capitalize w-100"
                                type="number"
                                placeholder=" "
                                value={field.weight}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setFormFieldsPet((prevFields) => {
                                    const updatedFields = [...prevFields];
                                    updatedFields[index] = {
                                      ...updatedFields[index],
                                      weight: value,
                                    };
                                    return updatedFields;
                                  });
                                  updateFormData(index, "weight", value);
                                }}
                                required={vehicleNumberPetInput ? true : false}
                              />
                              <label
                                htmlFor={`weight${index}`}
                                className="label-new"
                                style={{ fontSize: "14px" }}
                              >
                                Weigth (LBS)
                              </label>
                              {/* Validation Check */}
                              {field.weight?.length >= 2 && (
                                <p
                                  style={{
                                    position: "absolute",
                                    right: "20px",
                                    top: "14px",
                                  }}
                                  className="validation-icon"
                                >
                                  ✔
                                </p>
                              )}
                            </div>
                          </div>

                          {/* Year Input */}
                          <div className="col-md-2 position-relative">
                            <div className="form-group-new">
                              <input
                                className="form-control-new rounded-0 text-capitalize w-100"
                                type="number"
                                placeholder=" "
                                value={field.age}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setFormFieldsPet((prevFields) => {
                                    const updatedFields = [...prevFields];
                                    updatedFields[index] = {
                                      ...updatedFields[index],
                                      age: value,
                                    };
                                    return updatedFields;
                                  });
                                  updateFormData(index, "age", value);
                                }}
                                required={vehicleNumberPetInput ? true : false}
                              />
                              <label
                                htmlFor={`age${index}`}
                                className="label-new"
                                style={{ fontSize: "14px" }}
                              >
                                Age
                              </label>
                              {/* Validation Check */}
                              {field.age?.length >= 2 && (
                                <p
                                  style={{
                                    position: "absolute",
                                    right: "20px",
                                    top: "14px",
                                  }}
                                  className="validation-icon"
                                >
                                  ✔
                                </p>
                              )}
                            </div>
                          </div>

                          {/* Vehicle Image Input */}
                          <div className="col-md-3 position-relative">
                            <div
                              className="form-group-new"
                              style={{
                                marginTop: "-14px",
                              }}
                            >
                              <label className="d-block">Pet Image</label>
                              <input
                                className="w-100 add_input px-2 form-control"
                                type="file"
                                onChange={(e) => {
                                  const file = e.target.files[0];
                                  setFormFieldsPet((prevFields) => {
                                    const updatedFields = [...prevFields];
                                    updatedFields[index] = {
                                      ...updatedFields[index],
                                      petImage: file,
                                    };
                                    return updatedFields;
                                  });
                                }}
                                required={vehicleNumberPetInput ? true : false}
                              />
                            </div>
                          </div>
                        </>
                      ))}
                    </>
                  ) : null}
                </div>

                <div className="row mt-3">
                  <p className="h5">
                    2) Will you have a vehicle at this property?
                  </p>

                  <div className="row mb-2">
                    <div className="col-md-3">
                      <div className="select-container my-2">
                        <select
                          name="estimated_credit_score"
                          formcontrolname="creditRange"
                          className={`form-control-select w-100 ${
                            vehicle ? "text-success" : "text-dark"
                          }`}
                          value={vehicle ? "Yes" : "No"}
                          onChange={(e) => setVehicle(e.target.value === "Yes")}
                          onFocus={() =>
                            document
                              .querySelector(".label-new")
                              ?.classList.add("label-top")
                          }
                          onBlur={() =>
                            !vehicle &&
                            document
                              .querySelector(".label-new")
                              ?.classList.remove("label-top")
                          }
                        >
                          <option value="" disabled></option>
                          <option value="Yes" className="ng-star-inserted">
                            Yes{" "}
                            {vehicle ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                          <option value="No" className="ng-star-inserted">
                            No{" "}
                            {!vehicle ? (
                              <span className="tick-mark2">✔</span>
                            ) : null}
                          </option>
                        </select>
                        <label
                          className={`label-new ${
                            vehicle ? "label-top" : "label-top"
                          }`}
                          htmlFor="estimated_credit_score"
                        >
                          Vehicle
                        </label>
                      </div>
                    </div>
                    {vehicle ? (
                      <div className="col-md-3 mt-2 position-relative">
                        <div className="select-container">
                          <select
                            name="estimated_credit_score"
                            formcontrolname="creditRange"
                            className={`form-control-select w-100 ${
                              vehicleNumber ? "text-success" : "text-dark"
                            }`}
                            value={vehicleNumber}
                            onChange={(e) => {
                              const num = e.target.value;
                              setVehicleNumber(num);
                              setFormFields(
                                Array.from({ length: Number(num) }, () => ({
                                  make: "",
                                  model: "",
                                  color: "",
                                  year: "",
                                  vehicleImage: null,
                                  license: "",
                                }))
                              );
                              setFormData([]);
                            }}
                          >
                            <option value="" disabled>
                              {" "}
                            </option>
                            {[1, 2, 3, 4, 5].map((num) => (
                              <option key={num} value={num}>
                                {num}
                              </option>
                            ))}
                          </select>
                          <label
                            className={`label-new ${
                              vehicleNumber ? "label-top" : ""
                            }`}
                            htmlFor="estimated_credit_score"
                          >
                            No Of Vehicle
                          </label>
                        </div>
                      </div>
                    ) : null}
                  </div>

                  {vehicle ? (
                    <>
                      {formFields?.map((field, index) => (
                        <>
                          {/* Year Input */}
                          <div className="col-md-2 position-relative">
                            <div className="form-group-new">
                              <input
                                className="form-control-new rounded-0 text-capitalize w-100"
                                type="number"
                                placeholder=" "
                                value={field.year}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setFormFields((prevFields) => {
                                    const updatedFields = [...prevFields];
                                    updatedFields[index] = {
                                      ...updatedFields[index],
                                      year: value,
                                    };
                                    return updatedFields;
                                  });
                                  updateFormData(index, "year", value);
                                }}
                                required={vehicleNumberVehicleInput ? true : false}
                              />
                              <label
                                htmlFor={`year${index}`}
                                className="label-new"
                              >
                                Year
                              </label>
                              {/* Validation Check */}
                              {field.year?.length >= 4 && (
                                <p
                                  style={{
                                    position: "absolute",
                                    right: "20px",
                                    top: "14px",
                                  }}
                                  className="validation-icon"
                                >
                                  ✔
                                </p>
                              )}
                            </div>
                          </div>

                          {/* Make Input */}
                          <div className="col-md-2 position-relative">
                            <div className="form-group-new">
                              <input
                                className="form-control-new rounded-0 text-capitalize w-100"
                                type="text"
                                placeholder=" "
                                value={field.make}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setFormFields((prevFields) => {
                                    const updatedFields = [...prevFields];
                                    updatedFields[index] = {
                                      ...updatedFields[index],
                                      make: value,
                                    };
                                    return updatedFields;
                                  });
                                  updateFormData(index, "make", value);
                                }}
                                required={vehicleNumberVehicleInput ? true : false}
                              />
                              <label
                                htmlFor={`make${index}`}
                                className="label-new"
                              >
                                Make
                              </label>
                              {/* Validation Check */}
                              {field.make?.length > 4 && (
                                <p
                                  style={{
                                    position: "absolute",
                                    right: "20px",
                                    top: "14px",
                                  }}
                                  className="validation-icon"
                                >
                                  ✔
                                </p>
                              )}
                            </div>
                          </div>

                          {/* Model Input */}
                          <div className="col-md-2 position-relative">
                            <div className="form-group-new">
                              <input
                                className="form-control-new rounded-0 text-capitalize w-100"
                                type="text"
                                placeholder=" "
                                value={field.model}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setFormFields((prevFields) => {
                                    const updatedFields = [...prevFields];
                                    updatedFields[index] = {
                                      ...updatedFields[index],
                                      model: value,
                                    };
                                    return updatedFields;
                                  });
                                  updateFormData(index, "model", value);
                                }}
                                required={vehicleNumberVehicleInput ? true : false}
                              />
                              <label
                                htmlFor={`model${index}`}
                                className="label-new"
                              >
                                Model
                              </label>
                              {/* Validation Check */}
                              {field.model?.length >= 2 && (
                                <p
                                  style={{
                                    position: "absolute",
                                    right: "20px",
                                    top: "14px",
                                  }}
                                  className="validation-icon"
                                >
                                  ✔
                                </p>
                              )}
                            </div>
                          </div>

                          {/* Color Input */}
                          <div className="col-md-2 position-relative">
                            <div className="form-group-new">
                              <input
                                className="form-control-new rounded-0 text-capitalize w-100"
                                type="text"
                                placeholder=" "
                                value={field.color}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setFormFields((prevFields) => {
                                    const updatedFields = [...prevFields];
                                    updatedFields[index] = {
                                      ...updatedFields[index],
                                      color: value,
                                    };
                                    return updatedFields;
                                  });
                                  updateFormData(index, "color", value);
                                }}
                                required={vehicleNumberVehicleInput ? true : false}
                              />
                              <label
                                htmlFor={`color${index}`}
                                className="label-new"
                              >
                                Color
                              </label>
                              {/* Validation Check */}
                              {field.color?.length > 4 && (
                                <p
                                  style={{
                                    position: "absolute",
                                    right: "20px",
                                    top: "14px",
                                  }}
                                  className="validation-icon"
                                >
                                  ✔
                                </p>
                              )}
                            </div>
                          </div>
                          {/* Vehicle Number Input */}
                          <div className="col-md-2 position-relative">
                            <div className="form-group-new">
                              <input
                                className="form-control-new rounded-0 text-capitalize w-100"
                                id={`license${index}`}
                                placeholder=" "
                                type="text"
                                value={field.license}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setFormFields((prevFields) => {
                                    const updatedFields = [...prevFields];
                                    updatedFields[index] = {
                                      ...updatedFields[index],
                                      license: value,
                                    };
                                    return updatedFields;
                                  });
                                  updateFormData(index, "license", value);
                                }}
                                required={vehicleNumberVehicleInput ? true : false}
                              />
                              <label
                                htmlFor={`license${index}`}
                                className="label-new"
                              >
                                License #
                              </label>
                              {/* Validation Check */}
                              {field.license?.length >= 4 && (
                                <p
                                  style={{
                                    position: "absolute",
                                    right: "20px",
                                    top: "14px",
                                  }}
                                  className="validation-icon"
                                >
                                  ✔
                                </p>
                              )}
                            </div>
                          </div>

                          {/* Vehicle Image Input */}
                          <div className="col-md-2 position-relative">
                            <div
                              className="form-group-new"
                              style={{
                                marginTop: "-14px",
                              }}
                            >
                              <label className="d-block">Vehicle Image</label>
                              <input
                                className="w-100 add_input px-2 form-control"
                                type="file"
                                onChange={(e) => {
                                  const file = e.target.files[0];
                                  setFormFields((prevFields) => {
                                    const updatedFields = [...prevFields];
                                    updatedFields[index] = {
                                      ...updatedFields[index],
                                      vehicleImage: file,
                                    };
                                    return updatedFields;
                                  });
                                }}
                                required={vehicleNumberVehicleInput ? true : false}
                              />
                            </div>
                          </div>
                        </>
                      ))}
                    </>
                  ) : null}
                </div>

                <div className="row align-items-center mt-3">
                  <p className="h5">3) Do you smoke?</p>
                  <div className="col-md-2">
                    <div className="select-container my-2">
                      <select
                        name="estimated_credit_score"
                        formcontrolname="creditRange"
                        className={`form-control-select w-100 ${
                          smoke ? "text-success" : "text-dark"
                        }`}
                        value={smoke ? "Yes" : "No"}
                        onChange={(e) => setsmoke(e.target.value === "Yes")}
                        onFocus={() =>
                          document
                            .querySelector(".label-new")
                            ?.classList.add("label-top")
                        }
                        onBlur={() =>
                          !smoke &&
                          document
                            .querySelector(".label-new")
                            ?.classList.remove("label-top")
                        }
                      >
                        <option value="" disabled></option>
                        <option value="Yes" className="ng-star-inserted">
                          Yes{" "}
                          {smoke ? <span className="tick-mark2">✔</span> : null}
                        </option>
                        <option value="No" className="ng-star-inserted">
                          No{" "}
                          {!smoke ? (
                            <span className="tick-mark2">✔</span>
                          ) : null}
                        </option>
                      </select>
                      <label
                        className={`label-new ${
                          smoke ? "label-top" : "label-top"
                        }`}
                        htmlFor="estimated_credit_score"
                      >
                        Smoke
                      </label>
                    </div>
                  </div>
                  {smoke ? (
                    <>
                      <div className="col-md-2">
                        <label style={{ fontSize: "12px" }}>
                          Type Of Smoking:
                        </label>
                      </div>
                      <div className="col-md-2">
                        <label className="w-100">
                          <input
                            type="radio"
                            className="btn-check"
                            name="Cigarettes"
                            autocomplete="off"
                            value="Cigarettes Cigars"
                            checked={Cigrates == "Cigarettes Cigars"}
                            onChange={(e) => setCigrates(e.target.value)}
                          />
                          <span
                            className="btn btn btn-link yes2 px-1 py-2 w-100 mx-0"
                            style={{ fontSize: "12px" }}
                            for="Cigarettes"
                          >
                            Cigarettes Cigars{" "}
                            {Cigrates == "Cigarettes Cigars" ? "✔" : null}
                          </span>
                        </label>
                      </div>
                      <div className="col-md-2">
                        <label className="w-100">
                          <input
                            type="radio"
                            className="btn-check"
                            name="Vapes"
                            autocomplete="off"
                            value="Vapes"
                            checked={Vapes == "Vapes"}
                            onChange={(e) => setVapes(e.target.value)}
                          />
                          <span
                            className="btn btn btn-link yes2 px-1 py-2 w-100 mx-0"
                            style={{ fontSize: "12px" }}
                            for="Vapes"
                          >
                            Vapes {Vapes == "Vapes" ? "✔" : null}
                          </span>
                        </label>
                      </div>
                      <div className="col-md-2">
                        <label className="w-100">
                          <input
                            type="radio"
                            className="btn-check"
                            name="others"
                            autocomplete="off"
                            value="Marijuana & others"
                            checked={Other == "Marijuana & others"}
                            onChange={(e) => setOther(e.target.value)}
                          />
                          <span
                            className="btn btn btn-link yes2 px-1 py-2 w-100 mx-0 otxhers"
                            style={{ fontSize: "12px" }}
                          >
                            Marijuana others{" "}
                            {Other == "Marijuana & others" ? "✔" : null}
                          </span>
                        </label>
                      </div>
                      <div className="col-md-2">
                        <div className="select-container my-2">
                          <select
                          required={smoke ? true : false}
                            name="estimated_credit_score"
                            formcontrolname="creditRange"
                            className={`form-control-select w-100 ${
                              smokingArea ? "text-success" : "text-dark"
                            }`}
                            value={smokingArea}
                            onChange={(e) => setSmokingArea(e.target.value)}
                            onFocus={() =>
                              document
                                .querySelector(".label-new")
                                ?.classList.add("label-top")
                            }
                            onBlur={() =>
                              !smokingArea &&
                              document
                                .querySelector(".label-new")
                                ?.classList.remove("label-top")
                            }
                          >
                            <option value="" disabled></option>
                            <option
                              value="Indoors"
                              className="ng-star-inserted"
                            >
                              Outdoor Only{" "}
                              {smokingArea ? (
                                <span className="tick-mark2">✔</span>
                              ) : null}
                            </option>
                            <option
                              value="Indoors"
                              className="ng-star-inserted"
                            >
                              Indoors{" "}
                              {!smokingArea ? (
                                <span className="tick-mark2">✔</span>
                              ) : null}
                            </option>
                          </select>
                          <label
                            className={`label-new ${
                              smokingArea ? "label-top" : "label-top"
                            }`}
                            htmlFor="estimated_credit_score"
                          >
                            Area
                          </label>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>

                <div className="row mt-3">
                  <p className="h5">
                    4) Do you store/handle/own any type of firearms /
                    Explosives?
                  </p>

                  <div className="col-md-2">
                    <div className="select-container my-2">
                      <select
                        name="estimated_credit_score"
                        formcontrolname="creditRange"
                        className={`form-control-select w-100 ${
                          fireArmsTF ? "text-success" : "text-dark"
                        }`}
                        value={fireArmsTF ? "Yes" : "No"}
                        onChange={(e) =>
                          setFireArmsTF(e.target.value === "Yes")
                        }
                        onFocus={() =>
                          document
                            .querySelector(".label-new")
                            ?.classList.add("label-top")
                        }
                        onBlur={() =>
                          !fireArmsTF &&
                          document
                            .querySelector(".label-new")
                            ?.classList.remove("label-top")
                        }
                      >
                        <option value="" disabled></option>
                        <option value="Yes" className="ng-star-inserted">
                          Yes{" "}
                          {fireArmsTF ? (
                            <span className="tick-mark2">✔</span>
                          ) : null}
                        </option>
                        <option value="No" className="ng-star-inserted">
                          No{" "}
                          {!fireArmsTF ? (
                            <span className="tick-mark2">✔</span>
                          ) : null}
                        </option>
                      </select>
                      <label
                        className={`label-new ${
                          fireArmsTF ? "label-top" : "label-top"
                        }`}
                        htmlFor="estimated_credit_score"
                      >
                        Fire Arms
                      </label>
                    </div>
                  </div>
                  {fireArmsTF ? (
                    <>
                      <div className="col-md-4">
                        <div className="position-relative w-100">
                          <div className="form-group-new">
                            <input
                              className="w-100 form-control-new rounded-0 text-capitalize"
                              id="inputheight1"
                              placeholder=" "
                              type="text"
                              defaultValue={TypeWhat}
                              onChange={(e) => setTypeWhat(e.target.value)}
                            />
                            <label
                              htmlFor="inputheight1"
                              className="label-new"
                              style={{ fontSize: "12px" }}
                            >
                              Type in what type of fire arms
                            </label>
                          </div>
                          {TypeWhat?.length >= 4 ? (
                            <p
                              style={{
                                fontSize: "18px",
                                fontWeight: "bold",
                                position: "absolute",
                                color: "green",
                                right: "10px",
                                bottom: "-2px",
                                zIndex: "123",
                              }}
                            >
                              ✔
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-2">
                        <label className="w-100">
                          <input
                            type="radio"
                            className="btn-check"
                            name="fireArmsTF"
                            autocomplete="off"
                            value="Pleasure"
                            checked={fireArms == "Pleasure"}
                            onChange={(e) => setFireArms(e.target.value)}
                          />
                          <span
                            className="btn btn btn-link yes2 px-3 py-2 w-100 ms-0"
                            for="fireArmsTF"
                          >
                            Pleasure {fireArms == "Pleasure" ? "✔" : null}
                          </span>
                        </label>
                      </div>
                      <div className="col-md-2">
                        <label className="w-100">
                          <input
                            type="radio"
                            className="btn-check"
                            name="fireArmsTF"
                            autocomplete="off"
                            value="Hunting"
                            checked={fireArms == "Hunting"}
                            onChange={(e) => setFireArms(e.target.value)}
                          />
                          <span
                            className="btn btn btn-link yes2 px-3 py-2 w-100 ms-0"
                            for="fireArmsTF"
                          >
                            Hunting {fireArms == "Hunting" ? "✔" : null}
                          </span>
                        </label>
                      </div>
                      <div className="col-md-2">
                        <label className="w-100">
                          <input
                            type="radio"
                            className="btn-check"
                            name="fireArmsTF"
                            autocomplete="off"
                            value="for work"
                            checked={fireArms == "for work"}
                            onChange={(e) => setFireArms(e.target.value)}
                          />
                          <span
                            className="btn btn btn-link yes2 px-3 py-2 w-100 ms-0"
                            for="fireArmsTF"
                          >
                            for work {fireArms == "for work" ? "✔" : null}
                          </span>
                        </label>
                      </div>
                    </>
                  ) : null}
                  {bund?.firearms
                    ? bund?.firearms?.map((e) => (
                        <p className="text-danger">{e}</p>
                      ))
                    : null}
                </div>

                <div className="row mt-3">
                  <p className="h5">
                    5) Provide the name of your bank, our account number and the
                    current balance?
                  </p>
                  <div className="row mt-2">
                    <div className="col-md-4">
                      <div className="position-relative mt-2 me-4">
                        <div className="form-group-new">
                          <input
                            className="w-100 form-control-new rounded-0 text-capitalize"
                            id="inputheight1"
                            placeholder=" "
                            type="text"
                            value={letterOnly}
                            onChange={handleInputChange}
                            required
                          />
                          <label
                            htmlFor="inputheight1"
                            className="label-new"
                            style={{ fontSize: "12px" }}
                          >
                            Institution Bank Name
                          </label>
                        </div>
                        {letterOnly?.length >= 4 ? (
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: "bold",
                              position: "absolute",
                              color: "green",
                              right: "10px",
                              bottom: "-2px",
                              zIndex: "123",
                            }}
                          >
                            ✔
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="position-relative mt-2 me-4">
                        <div className="form-group-new">
                          <input
                            className="w-100 form-control-new rounded-0 text-capitalize"
                            id="inputheight1"
                            placeholder=" "
                            type="number"
                            defaultValue={accountNumber}
                            onChange={(e) => setAccountNumber(e.target.value)}
                            required
                          />
                          <label
                            htmlFor="inputheight1"
                            className="label-new"
                            style={{ fontSize: "12px" }}
                          >
                            Account Number
                          </label>
                        </div>
                        {accountNumber?.length >= 4 ? (
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: "bold",
                              position: "absolute",
                              color: "green",
                              right: "10px",
                              bottom: "-2px",
                              zIndex: "123",
                            }}
                          >
                            ✔
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="position-relative mt-2 me-4">
                        <div className="form-group-new">
                          <input
                            className="w-100 form-control-new rounded-0 text-capitalize"
                            id="inputheight1"
                            placeholder=" "
                            type="text"
                            onChange={(e) => {
                              handleChanges(e, setDollarBank, "dollarBank");
                            }}
                            required
                            value={`${
                              dollarBank === undefined
                                ? 0
                                : dollarBank
                                ? "$" + dollarBank
                                : ""
                            }${dollarBank === true ? ".00" : ""}`}
                          />
                          <label
                            htmlFor="inputheight1"
                            className="label-new"
                            style={{ fontSize: "12px" }}
                          >
                            Balance
                          </label>
                        </div>
                        {dollarBank?.length >= 4 ? (
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: "bold",
                              position: "absolute",
                              color: "green",
                              right: "10px",
                              bottom: "-2px",
                              zIndex: "123",
                            }}
                          >
                            ✔
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-5">
                  <label className="text-scolor fontsbtn text-center">
                    Next is <span className="fontsbtncobor">Co-Signers</span>
                  </label>
                  <br />
                  <button
                    id="btn-save"
                    className="btn btn-primary rounded mt-2 btnn-us1 font-bold"
                    type="submit"
                  >
                    Save & Continue &nbsp;
                    <AiOutlineArrowRight />
                  </button>
                </div>
              </div>
            </div>
            <ProfileInfo />
            <div className="footerimage3">
              <img
                src="https://bankerbroker.developer-oa.xyz/assets/img/footercity.svg"
                width="100%"
                alt=""
              />
            </div>
          </div>
        </div>
        </form>
      ) : null}
    </>
  );
};

export default TanAdditionalInfo;
